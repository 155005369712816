import * as types from './constants';
import endpoints from '../../constants/endpoints';
import { getHttpInstance } from '../../services/defaultHttpService';
import { getStoreInfo } from '../../services/storeInfoService';
import { normalizeUrl, replaceTokensInString, setCookie } from '../../utils';
import { clearError, setError } from '../exception/actions';
import { ERROR_DOMAIN, ERROR_PATH } from '../../constants/errorConstants';
import { CSR_TEXTS } from '@app/components/pages/csr-landing/texts';
import { CSR_ACTIONS } from '@app/components/pages/csr-landing/constants';
import { LOGIN_FROM_HOME_PAGE } from '@app/constants/commonConstants';
import { SHOPPING_CART_ROUTE } from '@app/constants/routes';

const openBoBModal = (
  {
    adpSubmitPromise,
    bobRedirect,
    description,
    title,
    showAssociatedDealer,
    showDealerLocator,
    bobUserId,
    reorderFunc
  },
  showWelcomeModal
) => {
  if (showWelcomeModal) {
    showDealerLocator({
      adpSubmitPromise,
      bobRedirect: CSR_ACTIONS.TRANSACT_AS_GUEST,
      description,
      showShopAsGuestButton: true,
      title
    });
  } else {
    showAssociatedDealer({
      adpSubmitPromise,
      bobRedirect,
      bobUserId,
      reorderFunc
    });
  }
};

export const getDealerAssociations =
  (
    { userId = '', csrOrderDetail = false } = {},
    isCSR = false,
    bobModalArgs = false
  ) =>
  dispatch => {
    dispatch({ type: types.DEALER_ASSOC_GET_BEGIN });
    const { storeId, langId } = getStoreInfo();
    const http = getHttpInstance();
    const url = replaceTokensInString(
      userId
        ? endpoints.DEALER_ASSOCIATIONS_CSR
        : endpoints.DEALER_ASSOCIATIONS,
      storeId,
      userId ? userId : langId
    );
    return http
      .get(url)
      .then(({ data } = {}) => {
        dispatch({
          type: types.DEALER_ASSOC_GET_SUCCESS,
          payload: data
        });
        !!csrOrderDetail &&
          dispatch({
            type: types.CSR_ORDER_DETAIL_DEALER_ASSOC_GET_SUCCESS
          });
        if (!!bobModalArgs) {
          openBoBModal(bobModalArgs, !!data.welcomeModal);
        }
        dispatch(
          clearError(ERROR_DOMAIN.CART, ERROR_PATH.EDIT_ORDER_INFORMATION)
        );
        return data;
      })
      .catch(error => {
        dispatch({
          type: types.DEALER_ASSOC_GET_FAIL,
          payload: error
        });
        const unknownError = {
          title: 'UNKNOWN_ERROR_TITLE',
          message: isCSR
            ? CSR_TEXTS.ADP_MODAL_ERROR_MESSAGE
            : 'UNKNOWN_ERROR_MESSAGE',
          severity: 'error'
        };
        dispatch(setError(ERROR_DOMAIN.ADP, ERROR_PATH.STORES, unknownError));
      });
  };

const handleSelectDealer = (invokeSelectDealer, dispatch, dealerStoreValue) => {
  const {
    storeId,
    instantAccessCustomerNumber,
    defaultStoreLocationCode,
    isfromDealerUserNoAssociationFlow
  } = dealerStoreValue;

  const dealerStorePayload = {
    dcnNumberName: instantAccessCustomerNumber,
    orderType: '',
    endUseCode: '',
    fromPage: '',
    bobRedirect: '',
    isLoginAction: true,
    saveToPreferencesFlag: false,
    storeLocationId: defaultStoreLocationCode,
    loginFromPage: LOGIN_FROM_HOME_PAGE,
    equipmentMakeModel: null,
    equipmentSerialNumber: null,
    fromDealerUserNoAssociationFlow: isfromDealerUserNoAssociationFlow
  };
  invokeSelectDealer(
    instantAccessCustomerNumber,
    storeId,
    dealerStorePayload
  ).then(data => {
    if (data.redirectURL) {
      dispatch({
        type: types.SELECTED_ADP_URL_GET_SUCCESS
      });
      setCookie('noucid', '1');
      window.location = normalizeUrl(data.redirectURL);
    }
  });
};

export const getAssociationsdealerStore =
  (invokeSelectStore, invokeSelectDealer) => dispatch => {
    dispatch(getDealerAssociations()).then(data => {
      const {
        defaultStoreLocationIdentifier,
        defaultStoreLocationCode,
        instantAccessCustomerNumber,
        storeId,
        welcomeModal,
        customerHasAccessToDealer
      } = data;
      const onShoppingCartPage =
        window.location.pathname.includes(SHOPPING_CART_ROUTE);
      const dealerStoreValue = {
        storeId,
        instantAccessCustomerNumber,
        defaultStoreLocationCode,
        isfromDealerUserNoAssociationFlow: customerHasAccessToDealer ? 'Y' : 'N'
      };

      if (
        !welcomeModal &&
        defaultStoreLocationIdentifier &&
        defaultStoreLocationCode &&
        instantAccessCustomerNumber
      ) {
        dispatch({
          type: types.SELECTED_STORE_GET_BEGIN
        });
        const body = {
          isRegistration: false,
          fromEspot: false,
          locationId: defaultStoreLocationIdentifier,
          storeId: storeId,
          pageURL: '',
          consent: true,
          bobRedirect: null
        };
        invokeSelectStore(body, storeId).then(data => {
          dispatch({
            type: types.SELECTED_STORE_GET_SUCCESS
          });

          if (data) {
            dispatch({
              type: types.SELECTED_ADP_URL_GET_BEGIN
            });
            handleSelectDealer(invokeSelectDealer, dispatch, dealerStoreValue);
          }
        });
      }
      if (
        !onShoppingCartPage &&
        !welcomeModal &&
        defaultStoreLocationCode &&
        instantAccessCustomerNumber &&
        !customerHasAccessToDealer
      ) {
        dispatch({
          type: types.SELECTED_ADP_URL_GET_BEGIN
        });
        handleSelectDealer(invokeSelectDealer, dispatch, dealerStoreValue);
      }
    });
  };
