import { useEffect, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Modal } from '@cat-ecom/pcc-components';
import { CatButton } from 'blocks-react/bedrock/components/Button';
import { CatDropdown } from 'blocks-react/bedrock/components/Dropdown';
import { CatHeading } from 'blocks-react/bedrock/components/Heading';
import { CatList } from 'blocks-react/bedrock/components/List';
import { CatListItem } from 'blocks-react/bedrock/components/ListItem';
import TaxInfoModalForm from './TaxInfoModalForm';
import useLogout from '@app/components/login/hooks/useLogout';
import { saveUserInformation } from '@app/store/common/actions';
import { STATUS } from '@app/constants/commonConstants';
import {
  TAX_PROFILE_UPDATE,
  TAX_REGISTER_TYPE,
  TAX_UPDATE_REGISTRATION
} from '../constants';

const TaxInfoEntryModal = ({ modal }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const modalRef = useRef();

  const isSaveUserLoading = useSelector(
    s => s?.personalInfoUI?.status === STATUS.PENDING
  );

  const initialValues = {
    taxIdOption: '',
    Individual: '',
    Company: '',
    stateTaxId: '',
    neighborhood: '',
    dealerReceiveEmail: false
  };

  const methods = useForm({
    defaultValues: initialValues,
    mode: 'onBlur',
    reValidateMode: 'onBlur'
  });

  const {
    handleSubmit,
    register,
    setValue,
    control,
    watch,
    resetField,
    getValues,
    trigger,
    formState: { isValid }
  } = methods;

  const isValidForm = useMemo(() => {
    return isSaveUserLoading || !isValid;
  }, [isSaveUserLoading, isValid]);

  useEffect(() => {
    const shadowModal = modalRef.current.shadowRoot.querySelector(
      '.cat-c-modal__window'
    );
    const shadowModalBody =
      modalRef.current.shadowRoot.querySelector('.cat-c-modal__body');
    if (shadowModal) {
      shadowModal.style.overflow = 'visible';
      shadowModalBody.style.paddingTop = '0px';
    }
  }, [modalRef]);

  const { logout } = useLogout();

  const onSubmit = () => {
    trigger();
    const formValues = getValues();

    const params = {
      ...formValues,
      registerType: TAX_REGISTER_TYPE,
      editRegistration: TAX_UPDATE_REGISTRATION,
      profileUpdate: TAX_PROFILE_UPDATE
    };

    const onSuccess = () => {
      modal.modalControl.close();
    };

    const onFail = () => {};

    dispatch(
      saveUserInformation(params, onSuccess, onFail, t('UR_CAT_COMPANY'))
    );
  };

  const onSelectHandler = e => {
    if (watch('taxIdOption') !== e.detail.value) {
      resetField('Company');
      resetField('Individual');
      resetField('stateTaxId');
      resetField('neighborhood');
    }
    setValue('taxIdOption', e.detail.value);
  };

  const taxId = [
    {
      label: t('UR_CAT_COMPANY'),
      value: t('UR_CAT_COMPANY')
    },
    {
      label: t('UR_CAT_INDIVIDUAL'),
      value: t('UR_CAT_INDIVIDUAL')
    }
  ];

  return (
    <Modal
      {...modal.modalProps}
      ref={modalRef}
      headingVariant="title-sm"
      data-testid="taxInfoEntryModal"
    >
      <CatHeading variant="body">{t('TAX_COLLECTION_SUBTITLE')}</CatHeading>
      <div>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="my-3">
              <Controller
                name="taxIdOption"
                control={control}
                rules={{
                  required: true
                }}
                render={({ field }) => (
                  <CatDropdown
                    {...field}
                    dataSource={taxId}
                    label={t('TAX_COLLECTION_DROPDOWN')}
                    name="taxIdOption"
                    required
                    placeholder={t('TAX_COLLECTION_DROPDOWN_PLACEHOLDER')}
                    fieldId="taxIdOption"
                    onBlSelect={e => onSelectHandler(e)}
                    align="bottom-start"
                    data-testid="taxIdOption"
                  >
                    <CatList>
                      {taxId.map(({ label, value }) => {
                        return (
                          <CatListItem value={value} key={value}>
                            {label}
                          </CatListItem>
                        );
                      })}
                    </CatList>
                  </CatDropdown>
                )}
              ></Controller>
            </div>
            <TaxInfoModalForm />
            <div className={'d-flex mt-3'}>
              <CatButton
                fullWidth={false}
                form="form"
                type="submit"
                variant="primary"
                disabled={isValidForm}
              >
                {t('SUBMIT')}
              </CatButton>
              <CatButton
                fullWidth={false}
                form="form"
                type="button"
                variant="ghost"
                onClick={logout}
              >
                <span className="text-capitalize">
                  {t('ADS_CONTINUE_AS_GUEST_BUTTON')}
                </span>
              </CatButton>
            </div>
          </form>
        </FormProvider>
      </div>
    </Modal>
  );
};
TaxInfoEntryModal.propTypes = {
  modal: PropTypes.shape({
    initiatorProps: PropTypes.shape({
      onClick: PropTypes.func
    }),
    modalProps: PropTypes.shape({
      isActive: PropTypes.bool,
      onBlModalClose: PropTypes.func,
      dismissible: PropTypes.bool,
      disableBackdropClick: PropTypes.bool,
      disableEscClick: PropTypes.bool,
      heading: PropTypes.string,
      size: PropTypes.string,
      backButton: PropTypes.bool
    }),
    modalControl: PropTypes.shape({
      isActive: PropTypes.bool,
      open: PropTypes.func,
      close: PropTypes.func
    })
  })
};

export default TaxInfoEntryModal;
