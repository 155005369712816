import * as types from './constants';
import endpoints from '../../constants/endpoints';
import { getHttpInstance } from '../../services/defaultHttpService';
import { getStoreInfo } from '../../services/storeInfoService';
import {
  replaceTokensInString,
  replaceTokensInUrl,
  normalizeUrl
} from '@app/utils';
import { normalizeError } from '../exception/utils';
import { setError } from '../exception/actions';
import { GENERIC_USER_ID } from '@app/constants/commonConstants';
import {
  ERROR_DOMAIN,
  ERROR_PATH,
  INFO_SHOPPING_CART_CRITICAL_ERROR
} from '@app/constants/errorConstants';
import { SHOPPING_CART_ROUTE } from '@app/constants/routes';
import { TAX_PROFILE_UPDATE } from '@app/components/DealerLocatorEffects/constants';

export const getCommonVars =
  ({ errorInfo = {} } = {}) =>
  async dispatch => {
    dispatch({ type: types.COMMON_GET_VARS_BEGIN });
    const isShoppingCartPage =
      window.location.pathname.includes(SHOPPING_CART_ROUTE);
    const { storeId, catalogId, langId } = getStoreInfo();
    const http = getHttpInstance();
    return http
      .get(replaceTokensInString(endpoints.USER_INFO_URL, storeId, langId))
      .then(response => {
        const { esiteURL } = response.data;
        const selectedStore =
          response.data.selectedStore || window.catReact.selectedStore;
        const url = '/Logoff?URL=';
        const normalizedURLParam = encodeURIComponent(normalizeUrl(esiteURL));
        const params = `${normalizedURLParam}&physicalStoreId=${selectedStore}&storeId=${storeId}`;
        const logoffURL = normalizeUrl(`${esiteURL}${url}${params}`);

        return dispatch({
          type: types.COMMON_GET_VARS_SUCCESS,
          payload: {
            storeId,
            catalogId,
            langId,
            logoffURL,
            ...response.data,
            selectedStore
          }
        });
      })
      .catch(error => {
        const { domain, path } = errorInfo;
        if (isShoppingCartPage) {
          dispatch(
            setError(
              ERROR_DOMAIN.CART,
              ERROR_PATH.SHOPPING_CART_CRITICAL_ERROR,
              INFO_SHOPPING_CART_CRITICAL_ERROR
            )
          );
        }
        if (domain && path) {
          dispatch(setError(domain, path, normalizeError(error)));
        } else {
          dispatch(setError('app', 'common', normalizeError(error)));
          dispatch({ type: 'COMMON_GET_VARS_FAIL' });
        }
      });
  };

export const setTranslationsLoaded = () => dispatch =>
  dispatch({
    type: types.COMMON_SET_TRANSLATIONS_LOADED
  });

export const cancelCartImport = () => {
  const http = getHttpInstance();
  return (dispatch, getState) => {
    const {
      common: { storeId }
    } = getState();
    const url = replaceTokensInUrl(endpoints.CART_IMPORT, storeId);
    return http.delete(url).then(() =>
      dispatch({
        type: types.COMMON_CANCEL_CART_IMPORT
      })
    );
  };
};

export const createGuestIdentity =
  (isSetCookie, isSetUserId) => async (dispatch, getState) => {
    const http = getHttpInstance();
    const { storeId } = getState().common;
    const url = replaceTokensInString(endpoints.SET_GUEST_IDENTITY, storeId);
    dispatch({ type: types.COMMON_CREATE_GUEST_IDENTITY_BEGIN });

    const cookieOptions = isSetCookie
      ? {
          responseFormat: 'json',
          updateCookies: 'true'
        }
      : null;

    try {
      const { data } = await http.post(url, cookieOptions).then(resp => {
        window.catReact.authToken = resp?.data?.WCTrustedToken;
        if (isSetUserId) {
          window.catReact.userId = resp.data.userId;
          dispatch({
            type: types.COMMON_SET_USER_ID,
            payload: { userId: resp.data.userId }
          });
        }
      });
      dispatch({
        type: types.COMMON_CREATE_GUEST_IDENTITY_SUCCESS,
        payload: data
      });
      if (!cookieOptions) {
        await dispatch(getCommonVars());
      }
    } catch (error) {
      dispatch({
        type: types.COMMON_CREATE_GUEST_IDENTITY_FAIL,
        payload: error
      });
    }
  };

export const saveUserInformation =
  (userInfo, onSuccess, onFail, COMPANY_TRANSLATION_VALUE) =>
  async (dispatch, getState) => {
    const http = getHttpInstance();
    const {
      storeId,
      catalogId,
      logonId,
      userId,
      isBuyOnBehalf = false
    } = getState().common;
    let url = replaceTokensInUrl(endpoints.PERSONAL_INFORMATION_SAVE, storeId);
    url += isBuyOnBehalf && userId ? `&forUserId=${userId}` : '';
    const {
      firstName,
      lastName,
      country,
      zipCode,
      address1,
      address2,
      city,
      state,
      phone1,
      companyName,
      industry,
      taxIdOption,
      Company,
      Individual,
      stateTaxId,
      neighborhood,
      registerType,
      editRegistration,
      profileUpdate,
      dealerReceiveEmail
    } = userInfo ?? {};
    let filteredPhone;
    if (phone1) {
      //backend requirement to send the phone without any non-numeric character other than the country code
      let countryCode = phone1.match(/\+[0-9]+/, '');
      countryCode = countryCode.length > 0 ? countryCode[0] : ''; //in case for any reason we cant find the country code
      const phoneWithoutCountryCode = phone1.replace(/\+[0-9]+ +/g, '');
      const phoneWithoutCharacters = phoneWithoutCountryCode.replace(
        /[^0-9]/g,
        ''
      );
      filteredPhone = `${countryCode} ${phoneWithoutCharacters}`;
    } else {
      filteredPhone = '';
    }
    dispatch({ type: types.SAVE_USER_INFO_BEGIN });
    try {
      //instead of spreading because there are some filds in the form which we do not want to send
      const filteredUserInfo = {
        ...types.SAVE_USER_INFO_CONSTANTS,
        storeId,
        catalogId,
        logonId,
        firstName,
        lastName,
        country,
        zipCode,
        address1,
        address2,
        city,
        state,
        phone1: filteredPhone,
        companyName,
        industry,
        taxIdOption,
        ...(taxIdOption === COMPANY_TRANSLATION_VALUE
          ? { Individual: '', Company }
          : { Company: '', Individual }),
        stateTaxId,
        neighborhood,
        ...(registerType && { registerType }),
        ...(editRegistration && { editRegistration }),
        ...(profileUpdate && { profileUpdate }),
        ...(dealerReceiveEmail && { dealerReceiveEmail })
      };
      await http.put(url, filteredUserInfo);
      if (profileUpdate === TAX_PROFILE_UPDATE) {
        dispatch({
          type: types.SAVE_TAX_INFO_SUCCESS,
          payload: filteredUserInfo
        });
      } else {
        dispatch({
          type: types.SAVE_USER_INFO_SUCCESS,
          payload: filteredUserInfo
        });
      }
      onSuccess && onSuccess();
    } catch (error) {
      onFail && onFail();
      dispatch({ type: types.SAVE_USER_INFO_FAIL, payload: error });
    }
  };

export const getFeatureInformation =
  featureNames => async (dispatch, getState) => {
    const { storeId } = getState().common;
    const http = getHttpInstance();

    const url = replaceTokensInUrl(
      endpoints.FEATURE_INFORMATION,
      storeId,
      featureNames
    );
    dispatch({ type: types.GET_FEATURE_INFORMATION_BEGIN });

    return http
      .get(decodeURIComponent(url))
      .then(response => {
        if (response.data) {
          const { data } = response;
          dispatch({
            type: types.GET_FEATURE_INFORMATION_SUCCESS,
            payload: data
          });
        }
      })
      .catch(() => {
        dispatch({ type: types.GET_FEATURE_INFORMATION_FAIL });
      });
  };

export const optInEmailMarketing =
  guestUserData => async (dispatch, getState) => {
    const {
      common: { storeId, catalogId, userType, userId }
    } = getState();
    dispatch({ type: types.COMMON_OPT_IN_EMAIL_MARKETING_BEGIN });
    try {
      const isLoggedIn = userType !== null && userType !== 'G';
      const userInfo_URL = replaceTokensInUrl(
        endpoints.UPDATE_USER_INFO,
        storeId
      );
      const http = getHttpInstance();
      if (userId === GENERIC_USER_ID) {
        await dispatch(createGuestIdentity(true));
      }
      await http.put(userInfo_URL, {
        storeId,
        catalogId,
        receiveEmail: 'true',
        ...(!isLoggedIn && {
          firstName: guestUserData?.firstName,
          lastName: guestUserData?.lastName,
          email: guestUserData?.email,
          country: guestUserData?.country?.code
        })
      });
      dispatch({ type: types.COMMON_OPT_IN_EMAIL_MARKETING_SUCCESS });
    } catch (error) {
      dispatch({ type: types.COMMON_OPT_IN_EMAIL_MARKETING_FAIL });
    }
  };

export const toggleADPmodalOpenStatus = data => dispatch => {
  dispatch({ type: types.ADP_MODAL_STATS_TOGGLE, payload: data });
};
