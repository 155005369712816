export const PDP_FBT_FLAG = 'PCC_PDPFBTFeature';
export const LUCID_INTEGRATION_FLAG = 'PCCLucidIntegration';
export const DEALER_USER_SHARED_LIST_FLAG =
  'PCC_DealerUserSharedSavedListsFlag';
export const PCC_PDP_LUCID_FLAG = 'PCCPDPLucidFlag';
export const PCC_NAVIGATION_HEADER_SUBHEADER_MENU_FLAG =
  'PCCNavigationHeaderSubheaderMenuFlag';
export const PCC_PDP_LUCID_TAX_DISCLAIMER = 'PCC_TaxInformationMessagesFlag';

export const PCC_PC_LUCID_FLAG = 'PCCLucidProductCategoryIntegrationFlag';
export const SIS_TO_CART_FALLBACK_FEATURE_FLAG =
  'PCC_SISToCartFallbackFeatureFlag';
export const PCC_Request_Service_VL_Flag = 'PCCRequestServiceVLFlag';
export const PRIVACY_POLICY_FLAG = 'PCC_ECTPrivacyPolicy';
export const TERMS_CONDITIONS_FEATURE_FLAG = 'PCC_ECTTermsAndConditions';
export const PCC_CAL_FOR_QUOTE_FEATURE_FLAG = 'PCC_CalForQuoteFeatureFlag';
export const PCC_CREATE_QUOTE_BUTTON_POPOVER_TOOLTIP_FLAG =
  'PCC_CreateQuoteButtonPopoverTooltipFlag';
export const HEADLESS_SHOPPING_CART_FLAG = 'PCC_HeadlessBlocksShoppingCart';
export const EMERGENGY_MESSAGE_FLAG = 'PCC_ECTEmergencyMessageFlag';
