/**
 * Maps a list of objects given an property and returns the values found.
 * @param {Array<any>} arr List of elements to map
 * @param {string} p Property to filter an map
 * @returns {Array<any>} List of existing values
 */
export const getArrayProp = (arr, p) =>
  (arr || []).filter(a => a[p] !== undefined).map(b => b[p]);

export const sortAlphabeticByAttribute = (list, attr) => {
  const clonedList = Array.from(list || []);
  return clonedList.sort((prevItem, nextItem) =>
    prevItem[attr].localeCompare(nextItem[attr])
  );
};

/*sorting function for any array, which takes into account the locale and additional parameters 
if the array has objects inside, you can sort base on the keys on a first order nesting level*/
export const sortArrayAlphabeticallyWithLocale = (
  startingArray,
  locale = 'en_US',
  key = null
) => {
  let sortedArray = [];
  if (key) {
    sortedArray = startingArray.sort((item1, item2) => {
      return item1[key].localeCompare(item2[key], locale.slice(0, 2));
    });
  } else {
    sortedArray = startingArray.sort((item1, item2) => {
      return item1.localeCompare(item2, locale.slice(0, 2));
    });
  }
  return sortedArray;
};

/**
 * Sorts list by date specified in a specific attribute of the list
 * @param {Array} list
 * @param {string} attr
 * @returns {Array} Sorted list
 */
export const sortByDateAndAttribute = (list, attr) => {
  return [...list].sort((item1, item2) => {
    const parsed1 = new Date(item1[attr]);
    const parsed2 = new Date(item2[attr]);
    return parsed2 - parsed1;
  });
};
