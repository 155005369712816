import { appendUrl } from '@app/components/common/SisLink/utils';
import useLoginRegistration from '@app/components/login/hooks/useLoginRegistration';
import {
  CAT_SIS_REDIRECT,
  HEADER_GLOBAL_LOGIN,
  INDUSTRY_CODE,
  LOCATION_HASH_KEY,
  FOCUS_SERVICE_INSIGHT,
  USER_TYPE_GUEST
} from '@app/constants/commonConstants';
import { navigateToUrl } from '@app/services/windowService';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import queryString from 'query-string';
import {
  normalizeUrl,
  replaceTokensInString,
  setCookie,
  setSessionStorage
} from '@app/utils';
import endpoints from '@app/constants/endpoints';
import { headlessLinks } from '@app/constants/links';
import {
  ALP_REGISTER_FOR_WARRANTY,
  CAT_PARTS_DIAGRAM,
  CHANGE_EQUIPMENT,
  CLEAR_SELECTION,
  CLEAR_SELECTION_DESELECT,
  COOKIE_EQUIPMENT_SERIAL_NUMBER,
  CUST_EDIT,
  EDIT,
  ENGINE_WARRANTY,
  FULL_SIS_LABEL,
  QUICK_CLEAR,
  SELECT,
  SELECT_EQUIPMENT,
  SHOP_PARTS,
  ALP_MAINTENANCE_REPAIRS,
  SINGLE_SHIPMENT_PAY_CHANGE,
  SIS,
  SIS_PART_LOOKUP,
  MLP_MEQ_KABOB1,
  VIEW_SERVICE_INSIGHTS,
  VIEW_DASHBOARD
} from './constants';
import { useTranslation } from 'react-i18next';
import {
  selectEquipment,
  deselectEquipment,
  setEquipmentToast,
  equipmentSelectDeselect
} from '@app/store/myequipment/actions';
import { capitalizeText } from '@app/utils/stringUtils';
import {
  GENERIC_WARRANTY,
  REGISTER_FOR_ENGINE_WARRANTY
} from '../../components/pages/alp/constants';
import { CatIconTrendingUp } from '@blocks-react/components/Icons/TrendingUp';

const useEquipmentActions = (equipmentDetails, drawers) => {
  const [t] = useTranslation();
  const {
    seoUrl = '',
    serialNumber = '',
    model = '',
    equipmentFamily = '',
    code = ''
  } = equipmentDetails || {};
  const userType = useSelector(s => s.common.userType);
  const locale = useSelector(s => s.common.locale);
  const isLoggedIn = userType !== USER_TYPE_GUEST;
  const href = useSelector(state => state.header.globalNavigation?.sisURL);
  const isHeadlessPartsDiagramEnabled = useSelector(
    state => state.featureFlag.PCCPartsDiagramUrlFlag
  );

  const { pathname, search: searchParams } = useLocation();
  const isMyequipmentPage = window.location.href?.includes('MyEquipment');
  const { showLoginRegistrationModal } = useLoginRegistration();
  const esiteURL = useSelector(s => s.common.esiteURL);
  const dispatch = useDispatch();
  const selectedEquipmentUniqueIndex = useSelector(
    s => s.myEquipment.equipments.selectedEquipment.uniqueIndex
  );
  const isValidatedAsset = equipmentDetails.validatedAsset;

  const setSelectedEquipment = useCallback(
    (redirectToUrl = null, onSuccessCallback = null) => {
      if (selectedEquipmentUniqueIndex === equipmentDetails?.uniqueIndex) {
        return onSuccessCallback
          ? onSuccessCallback()
          : navigateToUrl(redirectToUrl);
      } else if (redirectToUrl) {
        navigateToUrl(redirectToUrl);
      }
      dispatch(
        selectEquipment(
          equipmentDetails,
          null,
          onSuccessCallback,
          null,
          redirectToUrl
        )
      );
    },
    [dispatch, equipmentDetails, selectedEquipmentUniqueIndex]
  );

  const viewDashBoard = useCallback(() => {
    setSelectedEquipment(`${seoUrl}`);
  }, [seoUrl, setSelectedEquipment]);

  const serviceRecommendation = useCallback(() => {
    setSessionStorage(FOCUS_SERVICE_INSIGHT, true);
    setSelectedEquipment(`${seoUrl}${LOCATION_HASH_KEY.SERVICE_INSIGHT}`);
  }, [seoUrl, setSelectedEquipment]);

  const shopParts = useCallback(() => {
    setSelectedEquipment(`${seoUrl}${LOCATION_HASH_KEY.SHOP_PARTS}`);
  }, [seoUrl, setSelectedEquipment]);

  const maintenanceRepairs = useCallback(() => {
    setSelectedEquipment(`${seoUrl}${LOCATION_HASH_KEY.MAINTENANCE_REPAIRS}`);
  }, [seoUrl, setSelectedEquipment]);

  const registerForEngineWarranty = () => {
    const url =
      code === INDUSTRY_CODE.ET && serialNumber
        ? replaceTokensInString(REGISTER_FOR_ENGINE_WARRANTY, locale)
        : replaceTokensInString(GENERIC_WARRANTY, locale);
    window.open(url, '_blank');
  };
  const partsManual = useCallback(() => {
    setCookie(COOKIE_EQUIPMENT_SERIAL_NUMBER, serialNumber);
    setSelectedEquipment(
      normalizeUrl(
        replaceTokensInString(
          isHeadlessPartsDiagramEnabled
            ? headlessLinks.HEADLESS_PARTS_DIAGRAM_LINK
            : endpoints.FOOTER_LINK_PART_MANUAL,
          esiteURL
        )
      )
    );
  }, [
    serialNumber,
    setSelectedEquipment,
    isHeadlessPartsDiagramEnabled,
    esiteURL
  ]);

  const sis = useCallback(() => {
    if (!isLoggedIn) {
      if (isMyequipmentPage) {
        drawers?.opensisDrawer();
      } else {
        const guestSisSuccessCallBack = () => {
          showLoginRegistrationModal({
            widgetId: HEADER_GLOBAL_LOGIN,
            murl: CAT_SIS_REDIRECT
          });
        };
        setSelectedEquipment(null, guestSisSuccessCallBack);
      }

      return;
    }
    const { searchTerm } = queryString.parse(searchParams);
    const sisLinkParam = appendUrl(href, pathname, searchTerm);
    setSelectedEquipment(normalizeUrl(sisLinkParam));
  }, [
    isLoggedIn,
    searchParams,
    href,
    isMyequipmentPage,
    pathname,
    drawers,
    setSelectedEquipment,
    showLoginRegistrationModal
  ]);

  const edit = useCallback(() => {
    drawers?.openEditDrawer();
  }, [drawers]);

  const deselect = useCallback(() => {
    dispatch(deselectEquipment(equipmentDetails));
  }, [dispatch, equipmentDetails]);

  const clearSelection = useCallback(() => {
    if (isMyequipmentPage) {
      dispatch(deselectEquipment(equipmentDetails));
    } else {
      const toastText = replaceTokensInString(
        t('MEQ_DESELECT_MESSAGE'),
        model,

        capitalizeText(equipmentFamily)
      );
      dispatch(
        deselectEquipment(
          dispatch(
            setEquipmentToast({
              message: toastText
            })
          )
        )
      );
    }
  }, [
    dispatch,
    equipmentFamily,
    model,
    t,
    isMyequipmentPage,
    equipmentDetails
  ]);

  const changeEquipment = useCallback(() => {
    drawers?.openSelectDrawer();
  }, [drawers]);

  const selectCb = () => {
    dispatch(
      equipmentSelectDeselect({
        type: 'select-success',
        model: equipmentDetails?.model,
        productFamily: equipmentDetails?.equipmentFamily
      })
    );
  };

  const actions = useMemo(() => {
    const clearAction = {
      text: CLEAR_SELECTION,
      onclickHandler: () => clearSelection(),
      key: CLEAR_SELECTION
    };
    const editAction = {
      text: EDIT,
      onclickHandler: () => !isValidatedAsset && edit(),
      key: EDIT
    };
    const changeAction = {
      text: CHANGE_EQUIPMENT,
      onclickHandler: () => changeEquipment(),
      key: CHANGE_EQUIPMENT
    };
    return {
      VIEW_DASHBOARD: {
        text: VIEW_DASHBOARD,
        onclickHandler: () => viewDashBoard(),
        key: VIEW_DASHBOARD
      },
      VIEW_SERVICE_INSIGHTS: {
        text: VIEW_SERVICE_INSIGHTS,
        onclickHandler: () => serviceRecommendation(),
        key: VIEW_SERVICE_INSIGHTS,
        icon: <CatIconTrendingUp />
      },
      SHOP_PARTS: {
        text: MLP_MEQ_KABOB1,
        onclickHandler: () => shopParts(),
        key: SHOP_PARTS
      },
      ALP_MAINTENANCE_REPAIRS: {
        text: ALP_MAINTENANCE_REPAIRS,
        onclickHandler: () => maintenanceRepairs(),
        key: ALP_MAINTENANCE_REPAIRS
      },
      SIS_PART_LOOKUP: {
        text: SIS_PART_LOOKUP,
        onclickHandler: () => partsManual(),
        key: SIS_PART_LOOKUP
      },
      CAT_PARTS_DIAGRAM: {
        text: CAT_PARTS_DIAGRAM,
        onclickHandler: () => partsManual(),
        key: CAT_PARTS_DIAGRAM
      },
      ENGINE_WARRANTY: {
        text: ALP_REGISTER_FOR_WARRANTY,
        onclickHandler: () => registerForEngineWarranty(),
        key: ENGINE_WARRANTY
      },
      CHANGE_EQUIPMENT: changeAction,
      CHANGE_EQUIPMENT_SELECTED: {
        ...changeAction,
        text: SINGLE_SHIPMENT_PAY_CHANGE
      },
      CLEAR_SELECTION: clearAction,
      CLEAR_SELECTION_SELECTED: { ...clearAction, text: QUICK_CLEAR },
      SIS: {
        text: FULL_SIS_LABEL,
        onclickHandler: () => sis(),
        key: SIS
      },
      EDIT: editAction,
      EDIT_SELECTED: {
        ...editAction,
        text: CUST_EDIT
      },
      CLEAR_SELECTION_DESELECT: {
        text: CLEAR_SELECTION,
        onclickHandler: () => deselect(),
        key: CLEAR_SELECTION_DESELECT
      },
      SELECT: {
        text: 'PLEASE_SELECT',
        onclickHandler: () => setSelectedEquipment(null, selectCb),
        key: SELECT
      },
      SELECT_EQUIPMENT: {
        text: SELECT_EQUIPMENT,
        onclickHandler: () => setSelectedEquipment(null, selectCb),
        key: SELECT_EQUIPMENT
      }
    };
  }, [
    clearSelection,
    isValidatedAsset,
    edit,
    changeEquipment,
    serviceRecommendation,
    shopParts,
    maintenanceRepairs,
    partsManual,
    registerForEngineWarranty,
    sis,
    deselect,
    setSelectedEquipment,
    selectCb
  ]);

  return { equipmentActions: actions };
};

export default useEquipmentActions;
