/*
 * Because of limitations with Commerce's wishlist,
 * there is only one entry per model per wishlist registry.
 * We facilitate adding multiple equipment records with different assetIds
 * or serial numbers by keeping a list of serials and assetIds
 * in extra fields on that model's wishlist entry, and including an action string
 * representing update/delete.
 * A "multiple" boolean is included in each equipment record so that we know
 * if a piece of equipment has multiple models.
 *
 * The serials and assets will be sent as arrays, where element 0 is always
 * the equipment to be updated, and the rest of the array are the duplicate models
 * that are not being updated. The action parameter will indicate what is being done
 * to element 0 ("update" or "delete").
 *
 * For single pieces of equipment where "multiple": false,
 * {
 *   ...
 *   xitem_field3: ["12345"],
 *   xitem_field4: ["nickname 1"]
 * }
 *
 * For multiple pieces of equipment where a nickname will be changed:
 * {
 *    xitem_field3: ["12345", "23456"],
 *    xitem_field4: ["Changed Nickname", "Unchanged Nickname"]
 * }
 */
import {
  STATUS_FAIL,
  STATUS_SUCCESS,
  FORM_AD_EQ_UPDATE_EQUIPMENT
} from '@app/constants/analyticsConstants';
import { navigateToUrl } from '@app/services/windowService';
import { setSessionStorage } from '@app/utils';
import { ERROR_DOMAIN, ERROR_PATH } from '../../constants/errorConstants';
import { EQUIPMENT_SAVE_STATUS_SESSION } from '@app/components/pages/alp/constants';
import { EN_LOCALE, SEARCH_FILTER } from '@app/constants/commonConstants';
import { ommBookMeta } from './constants';

export const getMultipleEquipParams = (current, list) => {
  const serials = [current.serialNumber];
  const assetIds = [current.assetId];

  if (current.multiple) {
    list.forEach(eq => {
      if (
        eq.unValidatedItemListId === current.unValidatedItemListId &&
        eq.uniqueIndex !== current.uniqueIndex
      ) {
        const setAssetId =
          eq.isQRCodeTempEquipment && eq.assetId === ''
            ? 'temporaryEquipment'
            : eq.assetId;
        serials.push(eq.serialNumber);
        assetIds.push(setAssetId);
      }
    });
  }

  return {
    serials,
    assetIds
  };
};

export const setPendingToastAndReload = (
  message,
  method = 'createSuccessToast',
  guestAddEquipment = false,
  currentEquipment = {}
) => {
  sessionStorage.setItem(
    'pendingToast',
    JSON.stringify({
      method,
      message,
      guestAddEquipment,
      currentEquipment
    })
  );
  window.location.reload();
};

export const setPendingToastAndRedirect = (
  message,
  prevSerialNumber,
  serialNumber,
  preventMccRedirect,
  method = 'createSuccessToast',
  guestAddEquipment = false
) => {
  sessionStorage.setItem(
    'pendingToast',
    JSON.stringify({
      method,
      message,
      guestAddEquipment
    })
  );
  const newredirectUrl = window.location.href.replace(
    prevSerialNumber,
    serialNumber
  );
  if (!preventMccRedirect) {
    window.location.href = newredirectUrl;
  }
};

export const emailNotificationAndDispatchSuccess = ({
  storeId,
  langId,
  customerNumber,
  emailBody,
  salesModel,
  serialNumber,
  nickName,
  dispatch,
  setEquipmentSaveSuccess,
  endLoading,
  setExceptionError,
  emailNotification,
  action = 'update',
  fireFormSubmittedEvent,
  formSubmittedEventPayload,
  onEmailNotificationSuccessFailure = null,
  shouldRedirect = false,
  setToasts = null
}) => {
  const notificationToast = (success = true) => {
    if (setToasts) {
      setToasts(success);
    }
  };
  return emailNotification(
    storeId,
    langId,
    customerNumber,
    emailBody,
    salesModel,
    serialNumber,
    nickName,
    action
  )
    .then(() => {
      if (fireFormSubmittedEvent) {
        fireFormSubmittedEvent({
          ...formSubmittedEventPayload,
          formStatus: STATUS_SUCCESS
        });
      }

      notificationToast();
    })
    .catch(error => {
      if (fireFormSubmittedEvent) {
        fireFormSubmittedEvent({
          ...formSubmittedEventPayload,
          formFieldCausingError: error?.message,
          formStatus: STATUS_FAIL
        });
      }

      if (shouldRedirect) {
        notificationToast(false);
      } else {
        notificationToast();
        dispatch(
          setExceptionError(
            ERROR_DOMAIN.MY_EQUIPMENT,
            ERROR_PATH.DEALER_NOTIFICATION,
            {
              message: 'MEQ_ADD_NOTIFICATION8'
            }
          )
        );
      }
    })
    .finally(() => {
      if (onEmailNotificationSuccessFailure) {
        onEmailNotificationSuccessFailure();
      } else {
        dispatch(endLoading());
      }
    });
};
export const setPendingToastforMLPRedirect = (
  message,
  method = 'createSuccessToast',
  guestAddEquipment = false,
  currentEquipment = {}
) => {
  sessionStorage.setItem(
    'pendingToast',
    JSON.stringify({
      method,
      message,
      guestAddEquipment,
      currentEquipment
    })
  );
};

export const onSuccessFailureOfAutoSave = (
  status = null,
  redirectUrl = null,
  preventRedirectToALP = false
) => {
  if (status) {
    setSessionStorage(EQUIPMENT_SAVE_STATUS_SESSION, status);
  }
  if (redirectUrl && !preventRedirectToALP) {
    navigateToUrl(redirectUrl);
  }
  if (preventRedirectToALP) {
    window.location.reload();
  }
};

export const getGAEventPayload = (filterType, equipment) => {
  return {
    event: 'formSubmitted',
    pagePath: document.location.pathname,
    formName: FORM_AD_EQ_UPDATE_EQUIPMENT,
    formContent: equipment.serialNumber,
    formContent2: `${equipment.model} ${equipment.equipmentFamily}`,
    formFieldCausingError: '',
    formLocation:
      filterType === SEARCH_FILTER.SERIAL
        ? SEARCH_FILTER.SERIAL
        : SEARCH_FILTER.MODEL,
    formStatus: STATUS_SUCCESS
  };
};

export const getEquipmentFromList = (equipmentList, equipmentToSearch) => {
  return equipmentList.find(equipment =>
    !!equipmentToSearch.serialNumber
      ? equipmentToSearch.serialNumber === equipment.serialNumber
      : equipmentToSearch.model === equipment.model &&
        equipment.serialNumber === ''
  );
};

export const tranformFitmentObjToSerialObj = fitmentObject => {
  const serialMatchPartNumbers = [];
  for (const [key, value] of Object.entries(fitmentObject)) {
    if (value.isSerialCompatible) {
      serialMatchPartNumbers.push(key);
    }
  }

  return {
    serialMatchPartNumbers
  };
};

export const loadFilteredData = (equipments, selectedCustomerNumber) => {
  const nonValidatedAssets = [],
    selectedDcnEquipment = [],
    nonSelectedDcnEquipment = [],
    nonDcnEquipment = [];
  equipments.myEquipmentList.forEach(equipment => {
    if (equipment.validatedAsset === false) {
      nonValidatedAssets.push(equipment);
    } else if (equipment.dcn === selectedCustomerNumber) {
      selectedDcnEquipment.push(equipment);
    } else if (equipment.dcn && equipment.dcn !== selectedCustomerNumber) {
      nonSelectedDcnEquipment.push(equipment);
    } else {
      nonDcnEquipment.push(equipment);
    }
  });
  return {
    ...equipments,
    myEquipmentList: [
      ...nonValidatedAssets,
      ...selectedDcnEquipment,
      ...nonSelectedDcnEquipment,
      ...nonDcnEquipment
    ]
  };
};

export const getOmmLocale = (locale, languages, ommAllowedLanguages = '') => {
  if (locale === EN_LOCALE || !ommAllowedLanguages) {
    return '';
  }
  const { seoToken: lang } = languages.find(
    ({ localeName }) => localeName === locale
  );
  if (ommAllowedLanguages.split('|').includes(lang.toUpperCase())) {
    return lang;
  }
  return '';
};

export const getOmmImageUrl = metaData => {
  const imageMeta = metaData.find(
    meta => meta.name === ommBookMeta.SampleImageUrl
  );
  if (imageMeta) {
    const pattern = /(https:\/\/.*\/)/gm;
    const matches = pattern.exec(imageMeta.value);
    return matches ? matches[0] : null;
  }
  return null;
};

export const getYoutubeIframeSrc = youtubeIframe => {
  const htmlDocument = new DOMParser().parseFromString(
    youtubeIframe,
    'text/html'
  );
  const childNodes = htmlDocument.querySelectorAll('iframe');
  if (childNodes.length === 0) {
    return '';
  }
  const iframeAttributes = childNodes[0];
  return iframeAttributes.getAttribute('src');
};

export const getPartNumber = () => {
  const urlElements = window.location.pathname.split('/');
  return urlElements[urlElements.length - 1];
};

export const getIsSameModel = url => {
  const urlElements = url && url.split('/');
  const modelFromUrl = urlElements && urlElements[urlElements.length - 1];
  return getPartNumber() === modelFromUrl;
};
export default {
  getMultipleEquipParams,
  setPendingToastAndReload
};
