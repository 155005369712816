import { useMemo } from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { AlloyButton, ChildModal } from 'cat-ecommerce-alloy';
import PropTypes from 'prop-types';
import ContactDealerPopup from '../../ContactDealerPopup';
import { availability as availabilityPropTypes } from '../Availability.proptypes';
import { useBreakpoint } from '@app/hooks';
import { PAGE_BREAKPOINTS } from '@app/constants/commonConstants';
import ContactDealerDisplay from '../Common/ContactDealerDisplay';
import availabilityStyles from '../Availability.module.scss';
import styles from '../Common/ModernAvailability.module.scss';

const ModernAvailabilityContent = props => {
  const {
    availabilityInformation,
    catEntryId,
    open,
    parentIsOpen = false,
    isSecondaryComp
  } = props;
  const showContactDealerOnly = (availabilityInformation || []).some(
    ({ quantityAvailable, showContactDealerPopUp }) =>
      !quantityAvailable && showContactDealerPopUp ? true : false
  );
  const cellStyles = showContactDealerOnly
    ? 'text-start m-0'
    : 'text-start m-0 ps-2 ps-lg-1';
  const [t] = useTranslation();
  const isSM = !useBreakpoint(PAGE_BREAKPOINTS.MD);
  const normalizeString = str => (str ?? '')?.toString().trim();
  const isNotVoidOrZero = value => !!value && value !== '0';

  const isQuantityShow = useMemo(() => {
    if (availabilityInformation) {
      return availabilityInformation.length > 1;
    } else if (!showContactDealerOnly) {
      return true;
    }

    return false;
  }, [availabilityInformation, showContactDealerOnly]);

  const getContactDealerButton = msg => {
    return (
      <>
        {isSM && parentIsOpen ? (
          <>
            <AlloyButton
              data-testid="availability-contact-dealer"
              buttonType="link"
              className={cx(
                availabilityStyles['print-black-text'],
                'p-0 text-start'
              )}
              onClick={() => open()}
            >
              {msg || t('CONTACT_YOUR_DEALER')}
            </AlloyButton>
          </>
        ) : (
          <ContactDealerDisplay
            msg={msg}
            catEntryId={catEntryId}
            isSecondaryComp={isSecondaryComp}
          />
        )}
      </>
    );
  };

  const getAvailabilityMessage = msg => {
    return (
      <>
        <div className={styles['print__availability-text']}>{msg}</div>
      </>
    );
  };
  const showContactDealerBtnOrMsg = (msg, showContactDealerPopUp) => {
    if (showContactDealerPopUp || !msg) {
      return getContactDealerButton(msg);
    }
    return getAvailabilityMessage(msg);
  };
  return (
    <>
      <table
        className={styles['modern-availability-table']}
        data-testid="moderenAvailabilityTable"
      >
        <tbody>
          {(availabilityInformation || []).map(
            ({
              quantityAvailable,
              availabilityMessage,
              showContactDealerPopUp
            }) => {
              const msg = normalizeString(availabilityMessage);
              const qty = normalizeString(quantityAvailable);
              return isNotVoidOrZero(qty) || showContactDealerOnly ? (
                <tr key={`${qty}-${msg || 'contact-dealer'}`}>
                  <td className="d-flex h-100">
                    {isQuantityShow && (
                      <div
                        className={cx(
                          cellStyles,
                          'fw-bold',
                          styles['availability-by-source-text'],
                          styles['print__availability-text']
                        )}
                      >
                        {`${qty}`}
                      </div>
                    )}
                    <div
                      className={cx(
                        cellStyles,
                        styles['contact-button'],
                        styles['print__availability-text']
                      )}
                    >
                      {showContactDealerBtnOrMsg(msg, showContactDealerPopUp)}
                    </div>
                  </td>
                </tr>
              ) : null;
            }
          )}
        </tbody>
      </table>
      <ChildrenModalContent {...props} catEntryId={catEntryId} />
    </>
  );
};

ModernAvailabilityContent.propTypes = {
  catEntryId: PropTypes.string,
  availabilityInformation: availabilityPropTypes,
  quantity: PropTypes.number,
  open: PropTypes.func,
  parentIsOpen: PropTypes.bool,
  isSecondaryComp: PropTypes.bool
};
export default ModernAvailabilityContent;

export const ChildrenModalContent = props => {
  const [t] = useTranslation();
  return (
    <ChildModal
      backButtonMessage={'Back to Availabilbity'}
      title={t('CONTACT_YOUR_DEALER')}
      {...props}
      className={'d-flex flex-column'}
    >
      <ContactDealerPopup {...props} isShowContactUs={false} />
    </ChildModal>
  );
};
