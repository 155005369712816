import PropTypes from 'prop-types';
import _ from 'lodash';
import useLogin from '@app/components/login/hooks/useLogin';
import { LoginButton } from '@cat-ecom/pcc-components';
import { useTranslation } from 'react-i18next';

const SignInButton = ({ className, signinCallback = null }) => {
  const { t } = useTranslation();
  const { login } = useLogin();

  const signInHandler = () => {
    if (signinCallback) {
      return login(signinCallback);
    } else {
      return login();
    }
  };

  return (
    <LoginButton className={className} onClick={signInHandler}>
      {t('SIGN_IN')}
    </LoginButton>
  );
};

SignInButton.propTypes = {
  className: PropTypes.string,
  signinCallback: PropTypes.objectOf(
    PropTypes.shape({
      detail: { redirectUrl: PropTypes.string }
    })
  )
};

export default SignInButton;
