import {
  FITMENT_FALSE,
  FITMENT_TRUE,
  TRACKING_ID
} from '../../../constants/analyticsConstants';
import { getPathname } from '../../../services/windowService';
import {
  getCookie,
  replaceTokensInString,
  isProd,
  setSessionStorage
} from '../../../utils';
import {
  EVENT_ADD_TO_CART,
  EVENT_IMPRESSION_READY,
  EVENT_FORM_SUBMITTED,
  EVENT_PRODUCT_CLICK,
  EVENT_PRODUCT_DETAIL_READY,
  EVENT_PROMO_CLICK,
  EVENT_PROMO_VIEW,
  LIST_BUNDLES_PARTS_TABLE,
  LIST_BUNDLES_HEADER,
  SSO_KIT_ID,
  STATUS_SUCCESS,
  PM_KIT_LIST_BUNDLES_PARTS_TABLE
} from './analyticsConstants';

export const getStoreId = () => {
  const { storeId } = window.catReact;
  return storeId;
};

const getInputValueById = (elementId, defaultValue = '') => {
  let val = defaultValue;
  const element = document.getElementById(elementId);
  if (element && element.value) {
    val = element.value;
  }
  return val;
};

const getHeaderDataObject = () => {
  let obj;
  try {
    const headerData = getCookie('headerData');
    obj = JSON.parse(headerData);
  } catch (error) {
    obj = {};
  }
  return obj || {};
};

const getInventoryLocation = () => {
  const { storeName } = getHeaderDataObject();
  const inventoryLocation = storeName || '';
  return inventoryLocation;
};

export const getInventoryStatus = (availability = '') => {
  const inventoryStatus = Array.isArray(availability)
    ? availability
        .map(node => `${node.quantityAvailable}: ${node.availabilityMessage}`)
        .join(', ')
    : availability;
  return inventoryStatus;
};

export const getParentCategory = () => {
  return getInputValueById('parentCategoriesForGA');
};

const getDealerId = () => {
  const { dc } = getHeaderDataObject();
  const dealerId = dc || '';
  return dealerId;
};

const getCartId = () => {
  const storeId = getStoreId();
  const cartOrderId = getCookie(`WC_CartOrderId_${storeId}`);
  return cartOrderId || '';
};

const getPaymentMethod = () => {
  return getInputValueById('ga_CurrentPaymentMethodName');
};

const getBrand = parentCatEntryId => (parentCatEntryId ? 'Cat' : 'Non Cat');

export const getCartDetails = () => {
  return {
    dealerId: getDealerId(),
    cartId: getCartId(),
    paymentMethod: getPaymentMethod(),
    businessUnit: 'PCC'
  };
};

export const getCurrencyCode = () => {
  return getInputValueById('currentOrderCurrency');
};

export const getBundlePartsTableListValue = (
  multiplePartHeader,
  groupNumber,
  isPMKit
) => {
  let analyticsValue = isPMKit
    ? PM_KIT_LIST_BUNDLES_PARTS_TABLE
    : LIST_BUNDLES_PARTS_TABLE;
  if (multiplePartHeader && groupNumber) {
    analyticsValue += `-Component Configuration (${groupNumber})`;
  }
  return analyticsValue;
};

export const formatSsoKitId = kitId => {
  return replaceTokensInString(SSO_KIT_ID, kitId);
};

export const formatPrice = (price = '') => {
  return price ? price.replace(/\$|,/g, '') : '';
};

export const getProductInfo = ({
  name = '',
  brand = '',
  category = '',
  id = '',
  kitId = '',
  position = '',
  price = '',
  list = '',
  productWeight = '',
  businessUnit = '',
  classification = '',
  packageFlag = '',
  checkFitment = '',
  relatedModel = '',
  Loader = '',
  availability
}) => {
  return {
    name,
    id,
    price,
    brand,
    category,
    list,
    position,
    inventoryStatus: getInventoryStatus(availability),
    inventoryLocation: getInventoryLocation(),
    kitId,
    businessUnit,
    classification,
    productWeight,
    packageFlag,
    checkFitment,
    relatedModel,
    Loader
  };
};

const getBundleProductData = (products, list, kitId, relatedModel) => {
  const brand = getInputValueById(
    `ga_manufacturerName_${kitId}` || getBrand(kitId)
  );

  const productArray = Array.isArray(products) ? products : [products];
  return productArray.map(
    ({
      name,
      partNumber,
      selectedQty = 1,
      discountedPrice,
      weight,
      position
    }) => {
      const productInfo = getProductInfo({
        name,
        id: partNumber,
        brand,
        price: formatPrice(discountedPrice),
        productWeight: weight,
        list,
        position,
        inventoryStatus: '',
        inventoryLocation: '',
        kitId: formatSsoKitId(kitId),
        checkFitment: 'fity',
        relatedModel
      });
      return { ...productInfo, quantity: selectedQty };
    }
  );
};

const getBundleHeaderData = (bundleHeaders, position, relatedModel, ssId) => {
  const brand = getInputValueById(
    `ga_manufacturerName_${ssId}` || getBrand(ssId)
  );
  const list = LIST_BUNDLES_HEADER;
  const bundleHeadersArray = Array.isArray(bundleHeaders)
    ? bundleHeaders
    : [bundleHeaders];
  const positionFound = position !== undefined;
  return bundleHeadersArray.map(({ groupNumber, attributeName }, index) => {
    return {
      name: attributeName,
      id: groupNumber,
      position: positionFound ? position : index + 1,
      price: '',
      list,
      category: '',
      inventoryStatus: '',
      inventoryLocation: '',
      kitId: formatSsoKitId(ssId),
      productWeight: '',
      checkFitment: 'fity',
      relatedModel,
      classification: '',
      brand
    };
  });
};

const getBundleCarouselProductData = (products, list, relatedModel, ssId) => {
  const brand = getInputValueById(
    `ga_manufacturerName_${ssId}` || getBrand(ssId)
  );
  const productArray = Array.isArray(products) ? products : [products];
  return productArray.map(
    (
      {
        name,
        partNumber,
        position,
        selectedQty = 0,
        discountedPrice = '',
        weight = '',
        availability
      },
      index
    ) => {
      return {
        name: name,
        id: partNumber,
        position: position ? position : index + 1,
        price: formatPrice(discountedPrice),
        quantity: selectedQty,
        list,
        category: '',
        inventoryStatus: getInventoryStatus(availability),
        inventoryLocation: getInventoryLocation(),
        kitId: ssId ? formatSsoKitId(ssId) : '',
        productWeight: weight,
        checkFitment: 'fity',
        relatedModel,
        classification: '',
        brand
      };
    }
  );
};

/**
 * Returns a formatted event object for Google Analytics formSubmit events. *Note: Do not ever push PII into the data layer!!*
 *
 * @param {Object} values - values to use for the event
 * @param {string} values.formContent - information about a form e.g. searched term, Product of interest, non PII field inputs
 * @param {string} values.formContent2 - information about a form e.g. searched term, Product of interest, non PII field inputs
 * @param {string} values.formFieldCausingError -  If the form submit was prevented by a field / fields that were not properly filled out, pass the names of those fields here. On successful form submits, pass empty string. e.g. email, phone number
 * @param {string} values.formLocation - Form location on the page (where on the page not which page, only in cases there are multiple instances of the form on a page). e.g. CATRetail_HPEspotTop
 * @param {string} values.formName - Name of the submitted form. e.g. Contact Us
 * @param {string} values.pagePath -  Page on which the formSubmit data layer push occurred. e.g. /en/search/search-results.html
 * @param {string} values.formStatus - Was the form submit a success or fail including details about the fail, if applicable. e.g. success, fail
 * @returns {Object} - formatted formSubmit event object with defaults added
 */
export const getFormSubmitEvent = ({
  formContent = '',
  formContent2 = '',
  formFieldCausingError = '',
  formLocation = '',
  formName = '',
  pagePath = getPathname(),
  formStatus = STATUS_SUCCESS
}) => ({
  event: EVENT_FORM_SUBMITTED,
  formFieldCausingError,
  formName,
  formContent,
  formContent2,
  formStatus,
  formLocation,
  pagePath
});

export const getProductImpressionEvent = (
  displayedProducts,
  list,
  filterCallback
) => {
  const defaultFilter = () => true;
  const filter = filterCallback || defaultFilter;
  const currencyCode = getCurrencyCode();
  const cartDetails = getCartDetails();
  const productArray = Array.isArray(displayedProducts)
    ? displayedProducts
    : [displayedProducts];
  const impressions = productArray
    .map(({ partNumber, partName, parentCatEntryId, category = '' }, index) =>
      getProductInfo({
        name: partName,
        id: partNumber.replace('-PRODUCT', ''),
        category,
        brand: getBrand(parentCatEntryId),
        kitId: parentCatEntryId,
        position: index + 1,
        price: 'Check Price',
        list
      })
    )
    .filter(filter);

  return {
    event: EVENT_IMPRESSION_READY,
    cartDetails,
    ecommerce: {
      currencyCode,
      impressions
    }
  };
};

export const getMLPProductImpressionEvent = (
  displayedProducts,
  list,
  model
) => {
  const currencyCode = getCurrencyCode();
  const cartDetails = getCartDetails();
  const productArray = Array.isArray(displayedProducts)
    ? displayedProducts
    : [displayedProducts];
  const impressions = productArray.map(
    ({ partNumber, name, categoryPath = '', manufacturer }, index) =>
      getProductInfo({
        name: name,
        id: partNumber.replace('-PRODUCT', ''),
        category: categoryPath,
        brand: manufacturer,
        position: index + 1,
        packageFlag: 'No',
        businessUnit: 'PCC',
        checkFitment: 'fity',
        relatedModel: model,
        list,
        Loader: categoryPath
      })
  );

  return {
    event: EVENT_IMPRESSION_READY,
    cartDetails,
    ecommerce: {
      currencyCode,
      impressions
    }
  };
};

export const getMLPBundleProductImpressionEvent = (
  bundleOptions,
  list,
  model
) => {
  const currencyCode = getCurrencyCode();
  const cartDetails = getCartDetails();
  const productArray = Array.isArray(bundleOptions)
    ? bundleOptions
    : [bundleOptions];
  const impressions = productArray.map(
    ({ partNumber, partName, position, category = '' }) =>
      getProductInfo({
        name: partName,
        id: formatSsoKitId(partNumber),
        kitId: formatSsoKitId(partNumber),
        category,
        position,
        packageFlag: 'No',
        checkFitment: 'fity',
        relatedModel: model,
        list,
        Loader: category
      })
  );

  return {
    event: EVENT_IMPRESSION_READY,
    cartDetails,
    ecommerce: {
      currencyCode,
      impressions
    }
  };
};

export const getBundlePartsTableProductImpressionEvent = (
  bundleOptions,
  multiplePartHeader,
  groupNumber,
  model,
  ssId,
  isPMKit
) => {
  const currencyCode = getCurrencyCode();
  const cartDetails = getCartDetails();
  const productArray = Array.isArray(bundleOptions)
    ? bundleOptions
    : [bundleOptions];
  const impressions = productArray
    .filter(({ isPartsListEmpty }) => !isPartsListEmpty)
    .map(
      (
        {
          partNumber,
          name,
          discountedPrice,
          weight,
          category = '',
          availability
        },
        index
      ) =>
        getProductInfo({
          name: name,
          id: partNumber,
          kitId: formatSsoKitId(ssId),
          category,
          position: index + 1,
          packageFlag: 'No',
          checkFitment: 'fity',
          relatedModel: model,
          price: formatPrice(discountedPrice),
          productWeight: weight,
          list: getBundlePartsTableListValue(
            multiplePartHeader,
            groupNumber,
            isPMKit
          ),
          Loader: category,
          availability
        })
    );

  return {
    event: EVENT_IMPRESSION_READY,
    cartDetails,
    ecommerce: {
      currencyCode,
      impressions
    }
  };
};

export const getBundleHeaderProductImpressionEvent = (
  bundleHeaders,
  model,
  ssId
) => {
  const currencyCode = getCurrencyCode();
  const cartDetails = getCartDetails();
  const impressions = getBundleHeaderData(
    bundleHeaders,
    undefined,
    model,
    ssId
  );

  return {
    event: EVENT_IMPRESSION_READY,
    cartDetails,
    ecommerce: {
      currencyCode,
      impressions
    }
  };
};

export const getBundleCarouselProductImpressionEvent = (
  products,
  list,
  salesModel,
  ssId
) => {
  const currencyCode = getCurrencyCode();
  const cartDetails = getCartDetails();
  const impressions = getBundleCarouselProductData(
    products,
    list,
    salesModel,
    ssId
  );

  return {
    event: EVENT_IMPRESSION_READY,
    cartDetails,
    ecommerce: {
      currencyCode,
      impressions
    }
  };
};

export const getMLPProductClickEvent = (
  name,
  id,
  list,
  position,
  category,
  relatedModel,
  brand
) => {
  const cartDetails = getCartDetails();
  const currencyCode = getCurrencyCode();
  const actionField = { list };
  const products = getProductInfo({
    name,
    id: id.replace('-PRODUCT', ''),
    position,
    list,
    businessUnit: 'PCC',
    category,
    packageFlag: 'No',
    Loader: category,
    checkFitment: 'fity',
    relatedModel,
    brand
  });

  return {
    event: EVENT_PRODUCT_CLICK,
    cartDetails,
    ecommerce: {
      currencyCode,
      click: {
        actionField,
        products: [products]
      }
    }
  };
};

export const getMLPBundleProductClickEvent = (
  partName,
  partNumber,
  list,
  position,
  relatedModel,
  category = ''
) => {
  const cartDetails = getCartDetails();
  const currencyCode = getCurrencyCode();
  const actionField = { list };
  const products = getProductInfo({
    name: partName,
    id: formatSsoKitId(partNumber),
    kitId: formatSsoKitId(partNumber),
    position,
    list,
    category,
    packageFlag: 'No',
    Loader: category,
    checkFitment: 'fity',
    relatedModel
  });

  return {
    event: EVENT_PRODUCT_CLICK,
    cartDetails,
    ecommerce: {
      currencyCode,
      click: {
        actionField,
        products: [products]
      }
    }
  };
};

export const getProductClickEvent = ({ name, id, kitId, list, position }) => {
  const cartDetails = getCartDetails();
  const currencyCode = getCurrencyCode();
  const actionField = { list };
  const inventoryStatus = getInputValueById(`ga_inventoryStatus_${kitId}`);
  const price = getInputValueById(`ga_itemPrice_${kitId}`);
  const productWeight = getInputValueById(`ga_weight_${kitId}`);
  const brand = getInputValueById(
    `ga_manufacturerName_${kitId}` || getBrand(kitId)
  );
  const product = getProductInfo({
    name,
    id,
    brand,
    price,
    position,
    inventoryStatus,
    kitId,
    productWeight
  });

  return {
    event: EVENT_PRODUCT_CLICK,
    cartDetails,
    ecommerce: {
      currencyCode,
      click: {
        actionField,
        products: [product]
      }
    }
  };
};

export const getBundleProductClickEvent = ({
  products,
  kitId,
  multiplePartHeader,
  groupNumber,
  relatedModel,
  isPMKit
}) => {
  const cartDetails = getCartDetails();
  const currencyCode = getCurrencyCode();
  const list = getBundlePartsTableListValue(
    multiplePartHeader,
    groupNumber,
    isPMKit
  );
  const actionField = { list };
  const eventProducts = getBundleProductData(
    products,
    list,
    kitId,
    relatedModel
  );

  return {
    event: EVENT_PRODUCT_CLICK,
    cartDetails,
    ecommerce: {
      currencyCode,
      click: {
        actionField,
        products: eventProducts
      }
    }
  };
};

export const getBundleHeaderProductClickEvent = (
  bundleHeader,
  position,
  model,
  ssId
) => {
  const currencyCode = getCurrencyCode();
  const cartDetails = getCartDetails();
  const list = LIST_BUNDLES_HEADER;
  const actionField = { list };
  const products = getBundleHeaderData(bundleHeader, position, model, ssId);

  return {
    event: EVENT_PRODUCT_CLICK,
    cartDetails,
    ecommerce: {
      currencyCode,
      click: {
        actionField,
        products
      }
    }
  };
};

export const getBundleCarouselProductClickEvent = (
  { products, list, kitId, relatedModel },
  suppressSSID
) => {
  const cartDetails = getCartDetails();
  const currencyCode = getCurrencyCode();
  const actionField = { list };
  const eventProducts = getBundleCarouselProductData(
    products,
    list,
    relatedModel,
    suppressSSID ? '' : kitId
  );

  return {
    event: EVENT_PRODUCT_CLICK,
    cartDetails,
    ecommerce: {
      currencyCode,
      click: {
        actionField,
        products: eventProducts
      }
    }
  };
};

export const getAddToCartEvent = addedProducts => {
  const cartDetails = getCartDetails();
  const currencyCode = getCurrencyCode();
  const productArray = Array.isArray(addedProducts)
    ? addedProducts
    : [addedProducts];
  const products = productArray.map(
    ({ name, id, kitId, list, position, quantity = 1 }) => {
      const inventoryStatus = getInputValueById(`ga_inventoryStatus_${kitId}`);
      const price = getInputValueById(`ga_itemPrice_${kitId}`);
      const productWeight = getInputValueById(`ga_weight_${kitId}`);
      const brand = getInputValueById(
        `ga_manufacturerName_${kitId}` || getBrand(kitId)
      );
      const productInfo = getProductInfo({
        name,
        id,
        brand,
        price,
        list,
        position,
        inventoryStatus,
        kitId,
        productWeight
      });

      return { ...productInfo, quantity };
    }
  );

  return {
    event: EVENT_ADD_TO_CART,
    cartDetails,
    ecommerce: {
      currencyCode,
      add: {
        products
      }
    }
  };
};

export const getBundleAddToCartEvent = ({
  products,
  kitId,
  multiplePartHeader,
  groupNumber,
  relatedModel,
  isPMKit
}) => {
  const cartDetails = getCartDetails();
  const currencyCode = getCurrencyCode();
  const list = getBundlePartsTableListValue(
    multiplePartHeader,
    groupNumber,
    isPMKit
  );
  const eventProducts = getBundleProductData(
    products,
    list,
    kitId,
    relatedModel
  );

  return {
    event: EVENT_ADD_TO_CART,
    cartDetails,
    ecommerce: {
      currencyCode,
      add: {
        products: eventProducts
      }
    }
  };
};

export const getBundleCarouselAddToCartEvent = (
  { products, kitId, list, relatedModel },
  suppressSSID
) => {
  const cartDetails = getCartDetails();
  const currencyCode = getCurrencyCode();
  const eventProducts = getBundleCarouselProductData(
    products,
    list,
    relatedModel,
    suppressSSID ? '' : kitId
  );

  return {
    event: EVENT_ADD_TO_CART,
    cartDetails,
    ecommerce: {
      currencyCode,
      add: {
        products: eventProducts
      }
    }
  };
};

export const getBundleProductDetailReadyEvent = (
  { name },
  bundleTotal,
  relatedModel,
  kitId,
  list
) => {
  const actionField = { list };
  const cartDetails = getCartDetails();
  const currencyCode = getCurrencyCode();
  const brand = getInputValueById(
    `ga_manufacturerName_${kitId}` || getBrand(kitId)
  );

  const bundleData = {
    name,
    id: formatSsoKitId(kitId),
    kitId: formatSsoKitId(kitId),
    currencyCode,
    list,
    price: formatPrice(bundleTotal),
    brand,
    inventoryStatus: '',
    inventoryLocation: '',
    businessUnit: '',
    productWeight: '',
    category: '',
    packageFlag: '',
    checkFitment: 'fity',
    relatedModel
  };

  return {
    event: EVENT_PRODUCT_DETAIL_READY,
    cartDetails,
    ecommerce: {
      currencyCode,
      detail: {
        actionField,
        products: [bundleData]
      }
    }
  };
};
export const getEspotImpressionOrClickEvent = (
  htmlContent,
  locale,
  espotType,
  position,
  promoClick
) => {
  const { name, range, creative, source } = htmlContent;
  const sanitizeName = name?.trim();
  const sanitizeRange = range?.trim();
  const sanitizeCreative = creative?.trim();
  const sanitizeSource = source?.trim();

  const promotions = {
    name: sanitizeName,
    position: position,
    id: `${espotType} | ${sanitizeRange}`,
    creative: `${sanitizeCreative} - ${locale} - ${sanitizeSource}`
  };
  const promoEvent = promoClick || 'promoView';
  return {
    event: promoClick ? EVENT_PROMO_CLICK : EVENT_PROMO_VIEW,
    ecommerce: {
      [promoEvent]: {
        promotions: [promotions]
      }
    }
  };
};

/**
 * Util function to return wether the part fits with the equipment
 * @param {string} partNumber
 * @param {Set} serialMatchPartNumbers
 * @param {Set} rangeMatchPartNumbers
 * @returns {string} 'fity' if the part fits and 'fitn' if the part doesn't fit
 * @example getFitmentCheck('110-6331', new Set())
 */
export const getFitmentCheck = (partNumber, serialMatchPartNumbers) =>
  serialMatchPartNumbers?.has(partNumber) ||
  serialMatchPartNumbers?.has(partNumber?.replace(/-/g, ''))
    ? FITMENT_TRUE
    : FITMENT_FALSE;

export const getProductListOnClickEvent = (
  partName,
  partNumber,
  list,
  position,
  relatedModel,
  category = ''
) => {
  const cartDetails = getCartDetails();
  const currencyCode = getCurrencyCode();
  const actionField = { list };

  const products = getProductInfo({
    name: partName,
    id: formatSsoKitId(partNumber),
    kitId: formatSsoKitId(partNumber),
    position,
    list,
    category,
    packageFlag: 'No',
    Loader: category,
    checkFitment: 'fity',
    relatedModel
  });
  return {
    event: EVENT_PRODUCT_CLICK,
    cartDetails,
    ecommerce: {
      currencyCode,
      click: {
        actionField,
        products: [products]
      }
    }
  };
};

export const getServiceResponseTime = config => {
  const startTime = config['axios-retry'].lastRequestTime;
  const endTime = new Date();
  return Math.floor(endTime - startTime);
};

export const gaTimeTracker = (serviceResponseTime, gaName) => {
  if (window.ga) {
    const { ga } = window;
    if (isProd()) {
      ga('create', TRACKING_ID.PROD, 'auto');
    } else {
      ga('create', TRACKING_ID.DEV, 'auto');
    }
    ga('send', 'timing', gaName, 'load', serviceResponseTime);
  }
};

export const setTimeSessionStorage = key => {
  const time = new Date();
  setSessionStorage(key, time);
};
export const getCoreTrackingTableItem = ({ index, partName, partNumber }) => ({
  position: index + 1,
  name: partName,
  partNumber,
  inventoryLocation: '',
  inventoryStatus: '',
  price: ''
});

export default {
  getAddToCartEvent,
  getBundleAddToCartEvent,
  getBundleCarouselAddToCartEvent,
  getBundleCarouselProductClickEvent,
  getBundleCarouselProductImpressionEvent,
  getBundleHeaderProductClickEvent,
  getBundleHeaderProductImpressionEvent,
  getBundlePartsTableProductImpressionEvent,
  getBundleProductClickEvent,
  getBundleProductDetailReadyEvent,
  getCartDetails,
  getCurrencyCode,
  getEspotImpressionOrClickEvent,
  getFitmentCheck,
  getFormSubmitEvent,
  getMLPBundleProductClickEvent,
  getMLPBundleProductImpressionEvent,
  getMLPProductClickEvent,
  getMLPProductImpressionEvent,
  getProductClickEvent,
  getProductImpressionEvent,
  getProductInfo,
  getProductListOnClickEvent,
  getCoreTrackingTableItem,
  getServiceResponseTime,
  gaTimeTracker,
  setTimeSessionStorage
};
