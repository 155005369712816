import PropTypes from 'prop-types';
import {
  MyEquipmentLinkContent,
  MyEquipmentDrawerLinkImage
} from '@cat-ecom/pcc-components';
import styles from '../EditEquipmentDrawer/EditEquipmentDrawer.module.scss';
import {
  EQUIPMNET_IMAGE_PARAMS,
  SERIAL_MATCH_IMAGE_PARAMS,
  WIDGET_IDENTIFIER
} from '../constant';
import cx from 'classnames';
import { CatHeading } from 'blocks-react/bedrock/components/Heading';
import { useBreakpoint } from '@app/hooks';
import { PAGE_BREAKPOINTS } from '@app/constants/commonConstants';
import { useTranslation } from 'react-i18next';
import CapitalizeText from '@app/components/common/Text/CapitalizeText';

const SelectedEquipmentInfo = ({ currentEquipment = {}, identifier }) => {
  const [t] = useTranslation();
  const nickName = currentEquipment.assetId ?? '';
  const serialNumber = currentEquipment.serialNumber ?? '';
  const isSM = useBreakpoint(PAGE_BREAKPOINTS.SM);
  const isAdd = identifier === WIDGET_IDENTIFIER.ADD;
  const isEdit = identifier === WIDGET_IDENTIFIER.EDIT;

  const imageParams = isAdd
    ? SERIAL_MATCH_IMAGE_PARAMS
    : EQUIPMNET_IMAGE_PARAMS;

  return (
    <MyEquipmentLinkContent
      classname={cx('d-flex mt-2', {
        'align-items-start': !isAdd,
        'align-items-center': isAdd
      })}
    >
      <MyEquipmentDrawerLinkImage
        classname={styles['img--border']}
        alt={`${currentEquipment.model} ${currentEquipment.equipmentFamily}`}
        src={
          currentEquipment.imageURL &&
          `${currentEquipment.imageURL}${imageParams}`
        }
      />

      <MyEquipmentLinkContent classname="ps-3">
        {isAdd ? (
          <>
            <CatHeading variant="footnote" ariaLive="polite">
              {t('MEQ_SERIAL_MODEL_MESSAGE')}
            </CatHeading>
            <CatHeading variant={'title-sm'}>
              {`${currentEquipment.model} `} -
              <CapitalizeText text={currentEquipment.equipmentFamily} />
            </CatHeading>
          </>
        ) : (
          <>
            <CatHeading variant={isSM && isEdit ? 'title-sm' : 'title'}>
              {nickName}
            </CatHeading>
            <CatHeading variant={isSM && isEdit ? 'title-sm' : 'title'}>
              {`${currentEquipment.model}`} -
              <CapitalizeText text={currentEquipment.equipmentFamily} />
            </CatHeading>
            <CatHeading variant="label-lg">{serialNumber}</CatHeading>
          </>
        )}
      </MyEquipmentLinkContent>
    </MyEquipmentLinkContent>
  );
};

SelectedEquipmentInfo.propTypes = {
  currentEquipment: PropTypes.object,
  identifier: PropTypes.string
};

export default SelectedEquipmentInfo;
