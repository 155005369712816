import { replaceTokensInString } from '@app/utils';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { CatInlineNotification } from 'blocks-react/bedrock/components/InlineNotification';
import { CatIconWarningTriangle } from 'blocks-react/bedrock/components/Icons/WarningTriangle';
import Conditional from '@app/components/common/Conditional';

const HeaderManualApprovalMessage = () => {
  const { t } = useTranslation();
  const dealerName = useSelector(state => state?.dealer?.dealerName);
  const manualApprovalTitle = t('2175_MANUAL_APPROVAL_REG_ESPOT');
  const manualApprovalMessage = replaceTokensInString(
    t('2175_MNUAL_APPROVA_REG_ESPOT2'),
    dealerName
  );

  return (
    <Conditional test={!!dealerName}>
      <CatInlineNotification
        heading={manualApprovalTitle}
        variant={'warning'}
        data-testid="manual-approval-message"
      >
        <CatIconWarningTriangle slot="before" data-testid="warning-icon" />
        {manualApprovalMessage}
      </CatInlineNotification>
    </Conditional>
  );
};

export default HeaderManualApprovalMessage;
