import { MyEquipmentLink, MyEquipmentWarning } from '@cat-ecom/pcc-components';
import { CatButton } from 'blocks-react/bedrock/components/Button';
import { CatHeading } from 'blocks-react/bedrock/components/Heading';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const EquipmentDuplicateBanner = ({ onAccept, onCancel }) => {
  const [t] = useTranslation();
  return (
    <div className="mt-2">
      <MyEquipmentWarning errorNote={t('MEQ_DUPLICATE_EQUIPMENT_MESSAGE')}>
        <>
          <CatHeading variant="body-sm">
            {t('MEQ_DUPLICATE_EQUIPMENT_SELECT')}
          </CatHeading>
          <div className="mt-2">
            <CatButton onClick={onAccept}>{t('YES')}</CatButton>
            <MyEquipmentLink onClick={onCancel}>{t('NO')}</MyEquipmentLink>
          </div>
        </>
      </MyEquipmentWarning>
    </div>
  );
};

EquipmentDuplicateBanner.propTypes = {
  onAccept: PropTypes.func,
  onCancel: PropTypes.func
};

export default EquipmentDuplicateBanner;
