import { Fragment } from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useBreakpoint } from '@app/hooks';
import { AlloyButton, ChildModal } from 'cat-ecommerce-alloy';
import PropTypes from 'prop-types';
import { PAGE_BREAKPOINTS } from '@app/constants/commonConstants';
import ContactDealerPopup from '../../ContactDealerPopup';
import { sourcingDetailsPropTypes } from './AvailabilityBySourceContent.proptypes';
import ContactDealerDisplay from '../Common/ContactDealerDisplay';
import Conditional from '@app/components/common/Conditional';
import { isEmpty } from '@app/utils';
import availabilityStyle from '../Availability.module.scss';
import styles from './AvailabilityBySourceContent.module.scss';

const SourcingDetailsContent = props => {
  const {
    sourcingDetailsInformation,
    open,
    parentIsOpen = false,
    catEntryId,
    isSecondaryComp
  } = props;

  const [t] = useTranslation();
  const isSM = !useBreakpoint(PAGE_BREAKPOINTS.MD);

  const getContactDealerButton = msg => {
    return (
      <>
        {isSM && parentIsOpen ? (
          <>
            <AlloyButton
              data-testid="availability-contact-dealer"
              buttonType="link"
              className={cx(
                'p-0 text-start',
                availabilityStyle['print-black-text']
              )}
              onClick={() => open()}
            >
              {msg || t('CONTACT_YOUR_DEALER')}
            </AlloyButton>
          </>
        ) : (
          <ContactDealerDisplay
            msg={msg}
            catEntryId={catEntryId}
            isSecondaryComp={isSecondaryComp}
          />
        )}
      </>
    );
  };
  return (
    <>
      <table
        className={styles['sourcing-details-content-table']}
        data-testid="sourcingDetails"
      >
        <tbody>
          {sourcingDetailsInformation?.map(
            ({ availabilityMessage, availStoreList, sequence }) => {
              const hasAvailabilityMessage =
                availabilityMessage?.length > 0 && !isEmpty(sequence);
              return (
                <Fragment key={availabilityMessage}>
                  <Conditional test={hasAvailabilityMessage && sequence !== 4}>
                    <tr>
                      <td
                        className={`${styles['availability-by-source-text']} ${styles['print__availability-text']}`}
                        colSpan="2"
                      >
                        {availabilityMessage}
                      </td>
                    </tr>
                  </Conditional>

                  {availStoreList?.map(
                    ({ storeName, quantityAvailable, leadTimeDateRange }) => {
                      return (
                        <tr key={storeName}>
                          <td className="d-flex h-100">
                            <div
                              className={cx(
                                'fw-bold',
                                styles['quantity-available-text'],
                                styles['print__availability-text']
                              )}
                            >
                              {`${quantityAvailable}`}
                            </div>
                            <div
                              className={cx(
                                styles['contact-button'],
                                styles['print__availability-text']
                              )}
                            >
                              {sequence === 3 &&
                                getContactDealerButton(storeName)}
                              {sequence !== 3 && sequence !== 4 && (
                                <>
                                  {storeName}{' '}
                                  {leadTimeDateRange &&
                                    `(${leadTimeDateRange})`}
                                </>
                              )}
                              {sequence === 4 &&
                                getContactDealerButton(availabilityMessage)}
                            </div>
                          </td>
                        </tr>
                      );
                    }
                  )}
                </Fragment>
              );
            }
          )}
        </tbody>
      </table>
      <ChildrenModalContent {...props} catEntryId={catEntryId} />
    </>
  );
};
SourcingDetailsContent.propTypes = {
  sourcingDetailsInformation: sourcingDetailsPropTypes,
  open: PropTypes.func,
  parentIsOpen: PropTypes.bool,
  catEntryId: PropTypes.string,
  isSecondaryComp: PropTypes.bool
};
export default SourcingDetailsContent;

export const ChildrenModalContent = props => {
  const [t] = useTranslation();
  return (
    <ChildModal
      backButtonMessage={'Back to Availabilbity'}
      title={t('CONTACT_YOUR_DEALER')}
      {...props}
      className={'d-flex flex-column'}
    >
      <ContactDealerPopup {...props} isShowContactUs={false} />
    </ChildModal>
  );
};
