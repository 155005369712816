import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AssociatedDealerSearchCustomerModal } from '../AssociatedDealerSearchCustomer/AssociatedDealerSearchCustomerModal';
import { AssociatedDealerSelectCustomerModal } from '../AssociatedDealerSelectCustomer/AssociatedDealerSelectCustomerModal';
import useAssociatedDealer from '../hooks/useAssociatedDealer';
import { ModalType } from '../types';
import { AssociatedDealerSearchResultsModal } from '../AssociatedDealerSearchResults/AssociatedDealerSearchResultsModal';
import { AFFILIATION_DEALER } from '@app/constants/commonConstants';
import { getDealerCustomerDcn } from '@app/store/checkout/actions';

export const AssociatedDealerModal = ({ modal }) => {
  const dispatch = useDispatch();
  const userAffiliation = useSelector(s => s.common?.userAffiliation);
  const isDealerUser = userAffiliation === AFFILIATION_DEALER;
  const {
    searchCustomerProps,
    searchResultsProps,
    selectCustomerProps,
    searchError
  } = useAssociatedDealer({ isLoginAction: true });
  const storeId = useSelector(s => s.common?.dealerUserStoreId);
  const { customerNumber } = selectCustomerProps || '';
  const { value } = customerNumber || '';
  useEffect(() => {
    isDealerUser &&
      dispatch(getDealerCustomerDcn({ store: storeId, customerNumber: value }));
  }, [dispatch, value, storeId, isDealerUser]);

  return (
    <>
      {isDealerUser ? (
        <>
          <AssociatedDealerSearchCustomerModal
            modal={modal}
            searchError={searchError}
            {...searchCustomerProps}
          />
          <AssociatedDealerSearchResultsModal {...searchResultsProps} />
          <AssociatedDealerSelectCustomerModal {...selectCustomerProps} />
        </>
      ) : (
        <AssociatedDealerSelectCustomerModal
          modal={modal}
          {...selectCustomerProps}
        />
      )}
    </>
  );
};

AssociatedDealerModal.propTypes = {
  modal: ModalType
};
