import { lazy, Suspense, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import {
  ToastManager,
  ModalManager,
  PageLoader,
  PopupManager
} from 'cat-ecommerce-alloy';
import { track } from 'react-tracking';
import useAppStartup from './hooks/useAppStartup';

import {
  CSR_LANDING_PAGE_ROUTE,
  HOMEPAGE_ROUTE,
  MY_ACCOUNT_ROUTES,
  PRODUCT_ROUTE,
  REPAIR_OPTIONS_ROUTE,
  SUMMARY_AND_PAYMENT_ROUTE_LEGACY,
  HELP_CENTER_ROUTE,
  SHOPPING_CART_ROUTES,
  CATEGORY_ROUTE,
  SHOP_ALL_CATEGORIES_ROUTE,
  ABOUT_CAT_PARTS_ROUTE,
  WARRRANTY_RETURN_POLICY_ROUTE,
  USER_REGISTRATION_FORM_ROUTE,
  SEARCHPAGE_ROUTE,
  PARTS_MANUAL_ROUTE,
  SHOPPING_CART_ROUTE,
  ROUTE_SITE_MAP,
  ORDER_STATUS_INQUIRY_ROUTE,
  ORDER_CONFIRMATION_ROUTE,
  PICKUP_AND_DELIVERY_PAGE,
  GUEST_ORDER_STATUS_ROUTE,
  OMM_CONTENT_ROUTE,
  CONTACT_US_PAGE_ROUTE,
  GUEST_EQUIPMENT_VIEW,
  ALP_MAINTENANCE_VIDEOS_VIEW_ROUTE,
  TAX_DETAILS_PAGE_ROUTE
} from './constants/routes';
import {
  NOTIFICATION_ROOT,
  ORDER_DETAILS_WARRANTY_RETURN_POLICY_VIEW,
  CMC_MARKETING_TARGET,
  REACT_ROOT_MAIN,
  APP_BANNERS,
  HEADER
} from './constants/targets';
import {
  LOCALHOST_CONTEXT_PREFIX,
  LOCALHOST_CONTEXT_PREVIEW_PREFIX,
  STATUS,
  IS_STICKY_SCROLL_TOP,
  HEADER_STICKY_ON_SCROLL,
  STICKY_BANNER_ON_SCROLL
} from './constants/commonConstants';
import { CATEGORIES_SIZE } from './components/pages/shop-all-categories/constants';
import {
  isDev,
  isCategoryPage,
  isMLP,
  isPDP,
  metaValueExists,
  getPageGroupContent
} from './utils';

import { PageLoad } from './components/common/PageLoad';
import ExceptionFeedbackBySelector from './components/common/Exception/ExceptionFeedbackBySelector';
import ExceptionBoundary from './components/common/Exception/ExceptionBoundary';
import DOMRoute from './components/common/DOMRoute/DOMRoute';
import Conditional from './components/common/Conditional';
import CategorySkeletonLoader from './components/pages/category/CategorySkeletonLoader';
import ProductSkeletonLoader from './components/pages/product/ProductSkeletonLoader';
import IframeRedirect from './components/common/IframeRedirect';
import Notification from './components/system-notification';

import Header from './components/header';
import FooterWrapper from './components/footer/FooterWrapper';
import GlobalBanners from './components/global-banners';

import AppBanner from './components/appBanner';
import CSRBanner from './components/common/CSRUtils/CSRBanner';
import MyEquipmentFitmentWrapper from './components/common/myEquipmentFitment/MyEquipmentFitmentWrapper';
import MyEquipmentHeaderWrapper from './components/common/myEquipmentHeader/MyEquipmentHeaderWrapper';
import PCCHelpCenterModals from './components/PCCHelpCenterModals';
import HelpcenterChatWidget from './components/pages/HelpcenterChatWidget';
import './cmc-blocks-imports';

import './app.scss';
import CsrActionSummaryNotification from './components/common/CSRUtils/CsrActionSummaryNotification';
import CsrActionSummaryNotificationBlocks from './components/common/CSRUtils/CsrActionSummaryNotificationBlocks';
import DealerLocatorEffects from './components/DealerLocatorEffects';
import EmailMarketingOptInEffect from './components/EmailMarketingOptInEffect';
import HeaderLoading from './components/header/HeaderLoading';
import EquipmentToast from './components/pages/my-equipment/EquipmentToast';
import ManualApprovalToast from './components/common/ManualApprovalToast';
import { ConsentAcceptanceModalContext } from './components/common/ConsentAcceptanceModalContext';
import ShoppingCartCriticalErrorMessage from './components/pages/checkout/shopping-cart/ShoppingCartCriticalErrorMessage';

const WarrantyReturnPolicyContainer = lazy(
  () =>
    import(
      /* webpackChunkName: 'warrantyReturnPolicyContainer' */ './components/common/WarrantyReturnPolicy/WarrantyReturnPolicyContainer'
    )
);
const MarketingPage = lazy(
  () =>
    import(/* webpackChunkName: 'marketing' */ './components/pages/marketing')
);
const WarrantyReturnPolicy = lazy(
  () =>
    import(
      /* webpackChunkName: 'warrantyReturnPolicy' */ './components/footer/FooterSupportLinks/WarrantyReturnPolicy'
    )
);
const HelpCenterPage = lazy(
  () =>
    import(
      /* webpackChunkName: 'helpCenterPage' */ './components/pages/help-center'
    )
);
const AboutCatParts = lazy(
  () =>
    import(
      /* webpackChunkName: 'aboutCatParts' */ './components/footer/FooterFindPartsLinks/AboutCatParts'
    )
);
const Sitemap = lazy(
  () => import(/* webpackChunkName: 'sitemap' */ './components/pages/siteMap')
);
const AccountPages = lazy(
  () =>
    import(/* webpackChunkName: 'accountPages' */ './components/pages/account')
);
const RepairOptions = lazy(
  () =>
    import(
      /* webpackChunkName: 'repairOptions' */ './components/pages/RepairOptions'
    )
);
const SearchPage = lazy(
  () =>
    import(
      /* webpackChunkName: 'searchPage' */ './components/pages/search-page'
    )
);
const CSRLandingPage = lazy(
  () =>
    import(
      /* webpackChunkName: 'csrLandingPage' */ './components/pages/csr-landing'
    )
);
const WelcomeModal = lazy(
  () =>
    import(
      /* webpackChunkName: 'welcomeModal' */ './components/pages/control-center/WelcomeModal'
    )
);
const Checkout = lazy(
  () => import(/* webpackChunkName: 'checkout' */ './components/pages/checkout')
);
const RegistrationPage = lazy(
  () =>
    import(
      /* webpackChunkName: 'registration' */ './components/pages/registration'
    )
);
const ProductPage = lazy(
  () => import(/* webpackChunkName: 'product' */ './components/pages/product')
);
const CategoryPage = lazy(
  () => import(/* webpackChunkName: 'category' */ './components/pages/category')
);
const ShopAllCategories = lazy(
  () =>
    import(
      /* webpackChunkName: 'shopAllCategories' */ './components/pages/shop-all-categories'
    )
);
const OrderConfirmation = lazy(
  () =>
    import(
      /* webpackChunkName: 'orderConfirmation' */ './components/pages/checkout/order-confirmation'
    )
);
const HomePage = lazy(
  () => import(/* webpackChunkName: 'homepage' */ './components/pages/homepage')
);
const PickUpAndDelivery = lazy(
  () =>
    import(
      /* webpackChunkName: 'pickUpAndDelivery' */ '@app/components/pages/checkout/PickUpAndDelivery'
    )
);
const ErrorPage = lazy(
  () => import(/* webpackChunkName: 'errorPage' */ './components/pages/Error')
);

const OrderStatusInquiryPage = lazy(
  () =>
    import(
      /* webpackChunkName: 'orderstatusinquiry' */ './components/pages/order-status-inquiry'
    )
);
const GuestOrderStatusPage = lazy(
  () =>
    import(
      /* webpackChunkName: 'orderstatusinquiry' */ './components/pages/guest-order-status-page'
    )
);
const OMMSection = lazy(
  () => import(/* webpackChunkName: 'OMMSection' */ './components/pages/omm')
);

const ContactUsPage = lazy(
  () =>
    import(
      /* webpackChunkName: 'contactUsPage' */ './components/pages/contact-us'
    )
);

const GuestUserMyEquipmentView = lazy(
  () =>
    import(
      /* webpackChunkName: 'GuestUserMyEquipmentView' */ './components/pages/guest-equipment'
    )
);
const AlpMaintenanceVideosView = lazy(
  () => import(/* */ './components/pages/alpVideo')
);
const TaxDetailsPage = lazy(
  () =>
    import(
      /* webpackChunkName: 'TaxDetailsPage' */ './components/pages/Tax-Details-Page'
    )
);
export const App = () => {
  const appError = useSelector(state => state.errors.app);
  const commonStatus = useSelector(state => state.common.status);
  const isShoppingCartPage = getPageGroupContent() === 'ShoppingCart';

  const featureFlagStatus = useSelector(
    state => state.featureFlag.featureFlagStatus
  );

  const areTranslationsLoaded = useSelector(
    state => state.common.areTranslationsLoaded
  );

  const isPCCStickyNavMenuIntegrationFlag = useSelector(
    s => s.featureFlag?.PCCStickyNavMenuIntegrationFlag
  );

  const PCC_CustomerSupportActionsOnYourBehalfModal = useSelector(
    state => state.featureFlag.PCC_CustomerSupportActionsOnYourBehalfModal
  );

  const featureFlagResolved = featureFlagStatus === STATUS.RESOLVED;

  const isCommonLoading =
    commonStatus === STATUS.IDLE || commonStatus === STATUS.PENDING;
  let ROUTE_PREFIX = '';
  if (
    'catReact' in window &&
    'envInPreview' in window.catReact &&
    window.catReact.envInPreview === 'true'
  ) {
    ROUTE_PREFIX = LOCALHOST_CONTEXT_PREVIEW_PREFIX;
  } else if (isDev()) {
    ROUTE_PREFIX = LOCALHOST_CONTEXT_PREFIX;
  }

  const { isCommonFirstCall } = useAppStartup();

  const getErrorOrLoader = () => {
    if (appError?.common) {
      if (isShoppingCartPage) {
        return <ShoppingCartCriticalErrorMessage />;
      }

      return (
        <ExceptionFeedbackBySelector
          selector={state => state?.errors?.app?.common}
        />
      );
    }

    if (isCommonLoading && isCommonFirstCall) {
      return <PageLoader />;
    }

    return null;
  };

  const showCategoryPage = isCategoryPage();
  const showMachineLandingPage = isMLP();
  const showProductDisplayPage = isPDP();
  const showBundle = metaValueExists('pageGroup', 'Bundle');
  const showProductKit = metaValueExists('pageGroup', 'Package');
  const showProductPage =
    showProductDisplayPage ||
    showMachineLandingPage ||
    showProductKit ||
    showBundle;

  const orderHistoryWarrantyReturnPolicyTarget = document.getElementById(
    ORDER_DETAILS_WARRANTY_RETURN_POLICY_VIEW
  );

  const isRenderedInIframe = window.self !== window.top;

  useEffect(() => {
    const body = document.getElementById(REACT_ROOT_MAIN);
    body?.classList?.add(HEADER_STICKY_ON_SCROLL);

    const bannersRootElement = document.getElementById(APP_BANNERS);
    if (isPCCStickyNavMenuIntegrationFlag && bannersRootElement) {
      const headerRoot = document.getElementById(HEADER);
      bannersRootElement?.classList?.add(STICKY_BANNER_ON_SCROLL);

      // sets dynamic top position for sticky nav header based on banner height
      function bannerHeightHandler() {
        let stickyBanner = bannersRootElement?.getBoundingClientRect()?.height;
        if (headerRoot) {
          headerRoot.style['top'] = stickyBanner ? `${stickyBanner}px` : 0;
        }
      }
      bannerHeightHandler();
      const observer = new ResizeObserver(bannerHeightHandler);
      observer.observe(bannersRootElement);

      return () => observer.disconnect();
    }
    return () => body?.classList?.remove(HEADER_STICKY_ON_SCROLL);
  }, [isPCCStickyNavMenuIntegrationFlag]);

  return (
    <ExceptionBoundary>
      <Conditional
        test={!isRenderedInIframe}
        fallback={<IframeRedirect basename={ROUTE_PREFIX} />}
      >
        {getErrorOrLoader() || (
          <Router basename={ROUTE_PREFIX}>
            <ToastManager>
              <ModalManager>
                <PopupManager>
                  <ConsentAcceptanceModalContext>
                    <Route component={AppBanner} />
                    <Route component={CSRBanner} />
                    <Route component={GlobalBanners} />
                    <Route component={HelpcenterChatWidget} />
                    <Route component={EquipmentToast} />
                    <Route component={DealerLocatorEffects} />
                    <Route component={EmailMarketingOptInEffect} />
                    <Conditional
                      test={featureFlagResolved && areTranslationsLoaded}
                      fallback={<Route component={HeaderLoading} />}
                    >
                      <Route component={Header} />
                    </Conditional>
                    <Route
                      component={
                        PCC_CustomerSupportActionsOnYourBehalfModal
                          ? CsrActionSummaryNotificationBlocks
                          : CsrActionSummaryNotification
                      }
                    />
                    <Route component={MyEquipmentHeaderWrapper} />
                    <Route component={MyEquipmentFitmentWrapper} />
                    <Route component={PCCHelpCenterModals} />
                    <DOMRoute
                      target={NOTIFICATION_ROOT}
                      component={Notification}
                    />
                    <DOMRoute
                      target={ORDER_DETAILS_WARRANTY_RETURN_POLICY_VIEW}
                    >
                      <Suspense fallback={<PageLoader />}>
                        <WarrantyReturnPolicyContainer
                          domNode={orderHistoryWarrantyReturnPolicyTarget}
                        />
                      </Suspense>
                    </DOMRoute>
                    <DOMRoute target={CMC_MARKETING_TARGET}>
                      <Suspense fallback={<PageLoader />}>
                        <MarketingPage />
                      </Suspense>
                    </DOMRoute>
                    <ExceptionBoundary>
                      <Switch>
                        <Route path={WARRRANTY_RETURN_POLICY_ROUTE}>
                          <Suspense fallback={<PageLoader />}>
                            <WarrantyReturnPolicy />
                          </Suspense>
                        </Route>
                        <Route path={HELP_CENTER_ROUTE}>
                          <Suspense fallback={<PageLoader />}>
                            <HelpCenterPage />
                          </Suspense>
                        </Route>
                        <Route path={ABOUT_CAT_PARTS_ROUTE}>
                          <Suspense fallback={<PageLoader />}>
                            <AboutCatParts />
                          </Suspense>
                        </Route>
                        <Route path={ROUTE_SITE_MAP}>
                          <Suspense fallback={<PageLoader />}>
                            <Sitemap />
                          </Suspense>
                        </Route>
                        <Route
                          // path={CHECKOUT_ROUTE} TODO use for new checkout routing when ready
                          path={[
                            SUMMARY_AND_PAYMENT_ROUTE_LEGACY,
                            ...SHOPPING_CART_ROUTES
                          ]}
                        >
                          <Suspense fallback={<PageLoader />}>
                            <Checkout />
                          </Suspense>
                        </Route>
                        <Route path={MY_ACCOUNT_ROUTES}>
                          <Suspense fallback={<PageLoader />}>
                            <AccountPages />
                          </Suspense>
                        </Route>
                        <Route path={USER_REGISTRATION_FORM_ROUTE}>
                          <Suspense fallback={<PageLoader />}>
                            <RegistrationPage />
                          </Suspense>
                        </Route>
                        <Route path={ORDER_STATUS_INQUIRY_ROUTE}>
                          <Suspense fallback={<PageLoader />}>
                            <OrderStatusInquiryPage />
                          </Suspense>
                        </Route>
                        <Route path={GUEST_ORDER_STATUS_ROUTE}>
                          <Suspense fallback={<PageLoader />}>
                            <GuestOrderStatusPage />
                          </Suspense>
                        </Route>
                        <Route path={REPAIR_OPTIONS_ROUTE}>
                          <Suspense fallback={<PageLoader />}>
                            <RepairOptions />
                          </Suspense>
                        </Route>
                        <Route path={OMM_CONTENT_ROUTE}>
                          <Suspense fallback={<PageLoader />}>
                            <OMMSection />
                          </Suspense>
                        </Route>
                        <Route path={GUEST_EQUIPMENT_VIEW}>
                          <Suspense fallback={<PageLoader />}>
                            <GuestUserMyEquipmentView />
                          </Suspense>
                        </Route>

                        <Route path={ALP_MAINTENANCE_VIDEOS_VIEW_ROUTE}>
                          <Suspense fallback={<PageLoader />}>
                            <AlpMaintenanceVideosView />
                          </Suspense>
                        </Route>
                        <Route path={SHOP_ALL_CATEGORIES_ROUTE}>
                          <Suspense
                            fallback={
                              <CategorySkeletonLoader
                                showNavAsMenu
                                isShopAllCategoryPage
                                skeletonSize={CATEGORIES_SIZE}
                              />
                            }
                          >
                            <ShopAllCategories />
                          </Suspense>
                        </Route>
                        <Route path={SEARCHPAGE_ROUTE}>
                          <Suspense fallback={<PageLoader />}>
                            <SearchPage />
                          </Suspense>
                        </Route>
                        {showCategoryPage ? (
                          <Route path={CATEGORY_ROUTE}>
                            <Suspense fallback={<CategorySkeletonLoader />}>
                              <CategoryPage />
                            </Suspense>
                          </Route>
                        ) : null}
                        {showProductPage ? (
                          <Route path={PRODUCT_ROUTE}>
                            <Suspense fallback={<ProductSkeletonLoader />}>
                              <ProductPage />
                            </Suspense>
                          </Route>
                        ) : null}
                        <Route path={CSR_LANDING_PAGE_ROUTE}>
                          <Suspense fallback={<PageLoader />}>
                            <CSRLandingPage />;
                          </Suspense>
                        </Route>
                        <Route path={CONTACT_US_PAGE_ROUTE}>
                          <Suspense fallback={<PageLoader />}>
                            <ContactUsPage />
                          </Suspense>
                        </Route>
                        <Route path={`/${PICKUP_AND_DELIVERY_PAGE}`}>
                          <Suspense fallback={<PageLoader />}>
                            <PickUpAndDelivery />
                          </Suspense>
                        </Route>
                        <Route path={TAX_DETAILS_PAGE_ROUTE}>
                          <Suspense fallback={<PageLoader />}>
                            <TaxDetailsPage />
                          </Suspense>
                        </Route>
                        <Route path={ORDER_CONFIRMATION_ROUTE}>
                          <Suspense fallback={<PageLoader />}>
                            <OrderConfirmation />
                          </Suspense>
                        </Route>
                        <Route path={HOMEPAGE_ROUTE}>
                          <HomePage />
                        </Route>
                      </Switch>
                    </ExceptionBoundary>
                    <ErrorPage />
                    <Route path={[PARTS_MANUAL_ROUTE, SHOPPING_CART_ROUTE]}>
                      <Suspense fallback={<PageLoader />}>
                        <WelcomeModal />
                      </Suspense>
                    </Route>
                    <Route component={FooterWrapper} />
                    <PageLoad />
                  </ConsentAcceptanceModalContext>
                </PopupManager>
              </ModalManager>
            </ToastManager>
          </Router>
        )}
      </Conditional>
    </ExceptionBoundary>
  );
};

export default track()(App);
