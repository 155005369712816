import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import queryString from 'query-string';
import useDealerLocator from '@app/components/dealer-locator';
import {
  useMyAccountLinks,
  useControlCenterChecker
} from '@app/components/pages/account/hooks';
import useControlCenterUrl from '@app/hooks/useControlCenterUrl';
import { MY_ACCOUNT_SEARCH } from '@app/constants/routes';
import {
  CUSTOMER_CARE_HELPER_TEXT,
  TRANSACT_GUEST_HELPER_TEXT,
  TRANSACT_GUEST_REDIRECT,
  TRANSACT_GUEST_LABEL
} from '@app/components/header/constants';
import { MAIN_NAV_TRACK } from '@app/constants/analyticsConstants';
import { getStaticData } from '@app/components/pages/account/cat-vantage-rewards/utils/getStaticData.js';
import {
  isCSPWithCAL,
  isDealerUser,
  isCALRole,
  showPendingQuoteFeature
} from '@app/utils/userUtils';
import { getLink } from './utils';

const useHeaderAccountFeatures = (isLoggedIn, closeDrawer) => {
  const { t } = useTranslation();
  const { staticLinks } = useMyAccountLinks();
  const { isControlCenterEnabled } = useControlCenterChecker();
  const { CVRGlobalNavLabel, catVantageRewardCopyright } = getStaticData(t);

  const isCatCSR = useSelector(s => !!s.common?.isCatCSR);
  const isCSP = useSelector(s => !!s.common.isCSPCustomer);
  const quoteEnabled = useSelector(s => !!s.common.quoteEnabled);
  const isCatVantageRewardsEnabled = useSelector(
    state => state.featureFlag.PCC_CatVantageRewardsFlag
  );
  const customerAuthorityManager = useSelector(
    s => !!s.common.orderApprovalEnabled
  );
  const shipmentTrackingEnabled = useSelector(
    s => !!s.common?.shipmentTrackingEnabled
  );
  const userRoles = useSelector(s => s?.common?.userRoles);
  const userAffiliation = useSelector(s => s.common.userAffiliation);

  const enableNotification =
    shipmentTrackingEnabled ||
    showPendingQuoteFeature(isCSP, userAffiliation, quoteEnabled) ||
    isCSPWithCAL(isCSP, customerAuthorityManager);
  const showNotificationFeature = !isCatCSR && enableNotification;

  const showNotificationFeatureForRole = useCallback(() => {
    return showNotificationFeature || isCALRole(userRoles);
  }, [showNotificationFeature, userRoles]);

  const notificationPreferncePermission = useMemo(() => {
    if (userRoles) {
      showNotificationFeatureForRole();
    }
    return showNotificationFeature;
  }, [showNotificationFeature, showNotificationFeatureForRole, userRoles]);

  const orderHistoryPages = [
    MY_ACCOUNT_SEARCH.ORDERS,
    MY_ACCOUNT_SEARCH.ORDER_DETAILS,
    MY_ACCOUNT_SEARCH.ORDER_STATUS,
    MY_ACCOUNT_SEARCH.RETURN_FORM
  ];
  const ordersToApprovePages = [
    MY_ACCOUNT_SEARCH.ORDERS_TO_APPROVE,
    MY_ACCOUNT_SEARCH.PENDING_ORDER_DETAILS
  ];
  const savedListPages = [
    MY_ACCOUNT_SEARCH.LISTS,
    MY_ACCOUNT_SEARCH.LIST_DETAILS
  ];
  const pendingQuotePages = [
    MY_ACCOUNT_SEARCH.PENDINGQUOTES,
    MY_ACCOUNT_SEARCH.PENDING_QUOTE_DETAILS
  ];

  const { search } = useLocation();
  const { route } = queryString.parse(search);

  const { showDealerLocator } = useDealerLocator();
  const handleTransactionAsGuestClick = () => {
    closeDrawer();
    showDealerLocator({
      bobRedirect: TRANSACT_GUEST_REDIRECT
    });
  };

  const {
    CONTROL_CENTER,
    PERSONAL_INFO,
    MY_EQUIPMENT,
    ORDER_HISTORY,
    ORDERS_TO_APPROVE,
    SAVED_LIST,
    NOTIFICATION_PREFERENCES,
    SHOPPING_PREFERENCES,
    ADDRESS_BOOK,
    PENDING_QUOTES,
    CUSTOMER_CARE,
    CAT_VANTAGE_AWARDS
  } = staticLinks;

  const getStaicLink = !isLoggedIn;
  const controlCenterUrl = useControlCenterUrl();

  const controlCenterFeature = !!isControlCenterEnabled && {
    label: CONTROL_CENTER.label,
    helperText: t('DESC_CONTROL_CENTER'),
    link: controlCenterUrl,
    isActive: MY_ACCOUNT_SEARCH.CONTROL_CENTER.includes(route),
    dataTrackEvent: MAIN_NAV_TRACK.EVENT,
    dataTrackText: MAIN_NAV_TRACK.CONTROL_CENTER_TEXT,
    dataTrackContext: MAIN_NAV_TRACK.TOP_MENU_CONTEXT
  };
  const personalInfoFeature = {
    label: PERSONAL_INFO.label,
    helperText: t('DESC_PERSONAL_INFO'),
    link: getLink(isLoggedIn, PERSONAL_INFO.url),
    isStatic: getStaicLink,
    isActive: MY_ACCOUNT_SEARCH.PERSONAL_INFORMATION.includes(route),
    dataTrackEvent: MAIN_NAV_TRACK.EVENT,
    dataTrackText: MAIN_NAV_TRACK.PERSONAL_INFORMATION_TEXT,
    dataTrackContext: MAIN_NAV_TRACK.TOP_MENU_CONTEXT
  };
  const myEquipmentFeature = {
    label: MY_EQUIPMENT.label,
    helperText: t('DESC_MY_EQUIP'),
    link: getLink(isLoggedIn, MY_EQUIPMENT.url),
    isStatic: getStaicLink,
    isActive: MY_ACCOUNT_SEARCH.EQUIPMENT.includes(route),
    dataTrackEvent: MAIN_NAV_TRACK.EVENT,
    dataTrackText: MAIN_NAV_TRACK.MY_EQUIPMENT_TEXT,
    dataTrackContext: MAIN_NAV_TRACK.TOP_MENU_CONTEXT
  };
  const orderHistoryFeature = {
    label: ORDER_HISTORY.label,
    helperText: t('DESC_ORDER_INFO'),
    link: getLink(isLoggedIn, ORDER_HISTORY.url),
    isStatic: getStaicLink,
    isActive: orderHistoryPages.includes(route),
    dataTrackEvent: MAIN_NAV_TRACK.EVENT,
    dataTrackText: MAIN_NAV_TRACK.ORDER_HISTORY_TEXT,
    dataTrackContext: MAIN_NAV_TRACK.TOP_MENU_CONTEXT
  };
  const ordersToApproveFeature = isCSPWithCAL(
    isCSP,
    customerAuthorityManager
  ) && {
    label: t('MYACCOUNT_ORDER_APPROVAL'),
    helperText: t('DESC_ORDERS_TO_APPROVE'),
    link: ORDERS_TO_APPROVE.url,
    isActive: ordersToApprovePages.includes(route),
    dataTrackEvent: MAIN_NAV_TRACK.EVENT,
    dataTrackText: MAIN_NAV_TRACK.ORDER_TO_APPROVE_TEXT,
    dataTrackContext: MAIN_NAV_TRACK.TOP_MENU_CONTEXT
  };
  const savedListFeature = {
    label: SAVED_LIST.label,
    helperText: t('DESC_SAVED_LISTS'),
    link: getLink(isLoggedIn, SAVED_LIST.url),
    isStatic: getStaicLink,
    isActive: savedListPages.includes(route),
    dataTrackEvent: MAIN_NAV_TRACK.EVENT,
    dataTrackText: MAIN_NAV_TRACK.SAVED_LISTS_TEXT,
    dataTrackContext: MAIN_NAV_TRACK.TOP_MENU_CONTEXT
  };
  const notificationPreferencesFeature = notificationPreferncePermission && {
    label: NOTIFICATION_PREFERENCES.label,
    helperText: t('DESC_NOTIF_PREFERENCES'),
    link: NOTIFICATION_PREFERENCES.url,
    isActive: MY_ACCOUNT_SEARCH.NOTIFICATION_PREFERENCES.includes(route),
    dataTrackEvent: MAIN_NAV_TRACK.EVENT,
    dataTrackText: MAIN_NAV_TRACK.NOTIFICATION_PREFERENCES_TEXT,
    dataTrackContext: MAIN_NAV_TRACK.TOP_MENU_CONTEXT
  };
  const shoppingPreferencesFeature = {
    label: SHOPPING_PREFERENCES.label,
    helperText: t('DESC_SHOPPING_PREFERENCES'),
    link: getLink(isLoggedIn, SHOPPING_PREFERENCES.url),
    isStatic: getStaicLink,
    isActive: MY_ACCOUNT_SEARCH.PREFERENCES.includes(route),
    dataTrackEvent: MAIN_NAV_TRACK.EVENT,
    dataTrackText: MAIN_NAV_TRACK.SHOPPING_PREFERENCES_TEXT,
    dataTrackContext: MAIN_NAV_TRACK.TOP_MENU_CONTEXT
  };
  const addressBookFeature = {
    label: ADDRESS_BOOK.label,
    helperText: t('DESC_ADDRESS_BOOK'),
    link: getLink(isLoggedIn, ADDRESS_BOOK.url),
    isStatic: getStaicLink,
    isActive: MY_ACCOUNT_SEARCH.ADDRESS_BOOK.includes(route),
    dataTrackEvent: MAIN_NAV_TRACK.EVENT,
    dataTrackText: MAIN_NAV_TRACK.ADDRESS_BOOK_TEXT,
    dataTrackContext: MAIN_NAV_TRACK.TOP_MENU_CONTEXT
  };
  const catVantageRewardsFeature = !!isCatVantageRewardsEnabled && {
    label: catVantageRewardCopyright,
    helperText: CVRGlobalNavLabel,
    link: getLink(isLoggedIn, CAT_VANTAGE_AWARDS.url)
  };
  const pendingQuoteFeature = showPendingQuoteFeature(
    isCSP,
    userAffiliation,
    quoteEnabled
  ) && {
    label: PENDING_QUOTES.label,
    helperText: t('DESC_PENDING_QUOTES'),
    link: PENDING_QUOTES.url,
    isActive: pendingQuotePages.includes(route),
    dataTrackEvent: MAIN_NAV_TRACK.EVENT,
    dataTrackText: MAIN_NAV_TRACK.PENDING_QUOTES_TEXT,
    dataTrackContext: MAIN_NAV_TRACK.TOP_MENU_CONTEXT
  };

  if (isCatCSR) {
    const customerCareFeature = {
      label: CUSTOMER_CARE.label,
      helperText: CUSTOMER_CARE_HELPER_TEXT,
      link: CUSTOMER_CARE.url,
      dataTrackEvent: MAIN_NAV_TRACK.EVENT,
      dataTrackText: MAIN_NAV_TRACK.CUSTOMER_CARE_HOME_TEXT,
      dataTrackContext: MAIN_NAV_TRACK.TOP_MENU_CONTEXT
    };
    const transactGuestFeature = {
      label: TRANSACT_GUEST_LABEL,
      helperText: TRANSACT_GUEST_HELPER_TEXT,
      dataTrackEvent: MAIN_NAV_TRACK.EVENT,
      dataTrackText: MAIN_NAV_TRACK.TRANSACT_AS_GUEST_TEXT,
      dataTrackContext: MAIN_NAV_TRACK.TOP_MENU_CONTEXT,
      onClick: () => handleTransactionAsGuestClick()
    };
    return [customerCareFeature, transactGuestFeature];
  } else if (isDealerUser(userAffiliation)) {
    return [
      personalInfoFeature,
      myEquipmentFeature,
      orderHistoryFeature,
      savedListFeature,
      pendingQuoteFeature
    ];
  }
  return [
    controlCenterFeature,
    personalInfoFeature,
    myEquipmentFeature,
    orderHistoryFeature,
    ordersToApproveFeature,
    pendingQuoteFeature,
    savedListFeature,
    notificationPreferencesFeature,
    shoppingPreferencesFeature,
    addressBookFeature
    // TODO: Disabled for july interim release, and will be enabled in a later release.
    // catVantageRewardsFeature
  ];
};

export default useHeaderAccountFeatures;
