import Conditional from '@app/components/common/Conditional';
import { isEmpty, isIE } from '@app/utils';
import PropTypes from 'prop-types';
import { CATSAS_IE_ALERT_ESPOT_HOME_PAGE, ESPOT_TYPE } from '../constants';
import { lazy } from 'react';

const Espot = lazy(() =>
  import(/* webpackChunkName: 'espot' */ '@app/components/common/Espot/Espot')
);

const BrowserWarningMessage = ({ getEspotContent }) => {
  // This banner is render when user is using Internet Explorer.
  const browserWarningHtmlEspot = getEspotContent(
    CATSAS_IE_ALERT_ESPOT_HOME_PAGE
  );

  const showBrowserWarningMessage = isIE() && !isEmpty(browserWarningHtmlEspot);
  return (
    <Conditional test={showBrowserWarningMessage}>
      <Espot html={browserWarningHtmlEspot} />
    </Conditional>
  );
};

export default BrowserWarningMessage;
BrowserWarningMessage.propTypes = {
  espotData: ESPOT_TYPE,
  getEspotContent: PropTypes.func
};
