import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ERROR_DOMAIN, ERROR_PATH } from '@app/constants/errorConstants';
import useSystemFeedback from '@app/hooks/useSystemFeedback';
import { replaceTokensInString } from '@app/utils';
import { BellIcon } from 'cat-ecommerce-alloy';

const ManualApprovalMessage = () => {
  const { t } = useTranslation();
  const { setError } = useSystemFeedback();
  const dealerName = useSelector(state => state?.dealer?.dealerName);
  const manualApprovalMessage1 = t('2175_MANUAL_APPROVAL_REG_ESPOT');
  const manualApprovalMessage2 = t('2175_MNUAL_APPROVA_REG_ESPOT2');

  const manualApprovalMessage = replaceTokensInString(
    `${manualApprovalMessage1} ${manualApprovalMessage2}`,
    dealerName
  );

  useEffect(() => {
    const error = {
      icon: BellIcon,
      message: manualApprovalMessage,
      severity: 'success'
    };
    setError(ERROR_DOMAIN.GLOBAL, ERROR_PATH.MAIN, error);
  }, [manualApprovalMessage, setError]);
  return null;
};

export default ManualApprovalMessage;
