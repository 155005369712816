import { createPortal } from 'react-dom';
import { track } from 'react-tracking';
import { SUBMENU_ROOT, HEADER } from '@app/constants/targets';
import { useSelector } from 'react-redux';
import ManualApprovalToast from './ManualApprovalToast';

export const Container = props => {
  const PCCNavigationHeaderSubheaderMenuFlag = useSelector(
    state => state.featureFlag.PCCNavigationHeaderSubheaderMenuFlag
  );

  const subheaderTarget = document.getElementById(SUBMENU_ROOT);
  const headerTarget = document.getElementById(HEADER);

  const targetHeader =
    headerTarget && PCCNavigationHeaderSubheaderMenuFlag ? headerTarget : null;

  const targetElement = subheaderTarget ? subheaderTarget : targetHeader;

  return targetElement
    ? createPortal(<ManualApprovalToast {...props} />, targetElement)
    : null;
};

export default track()(Container);
