import { useEffect, useRef, useState } from 'react';
import { useTracking } from 'react-tracking';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import EspotContentPortal from './EspotContentPortal/EspotContentPortal';
import PurifiedHtml from '@app/components/common/PurifiedHtml';
import { getEspotImpressionOrClickEvent } from '../../analytics/analyticsUtils';

const EspotContent = ({
  htmlContent,
  espotType,
  position,
  setCarouselHeight,
  className
}) => {
  const { html, creative } = htmlContent;
  const locale = useSelector(s => s.common?.locale);
  const ref = useRef();
  const [isRefAvailable, setIsRefAvailable] = useState(false);
  const { trackEvent } = useTracking();
  const [wasImpressionTracked, setWasImpressionTracked] = useState(false);

  useEffect(() => {
    if (wasImpressionTracked) {
      return;
    }
    if (creative) {
      trackEvent(
        getEspotImpressionOrClickEvent(htmlContent, locale, espotType, position)
      );
      setWasImpressionTracked(true);
    }
  }, [htmlContent, trackEvent, wasImpressionTracked]);

  useEffect(() => {
    setCarouselHeight && setCarouselHeight(`${ref?.current?.clientHeight}px`);
  }, [setCarouselHeight]);

  const renderPortal = () => {
    if (ref.current) {
      const registerCTAs = ref.current.querySelectorAll('.cta-button-widget');
      return Array.from(registerCTAs).map(node => {
        return (
          <EspotContentPortal
            key={node.id}
            type={node.dataset.type}
            buttonText={node.dataset.buttonText}
            portalTarget={node}
            component={node.dataset.component}
          />
        );
      });
    }
  };

  useEffect(() => {
    ref.current && setIsRefAvailable(true);
  }, [ref]);

  const onEspotClick = event => {
    creative &&
      event?.target?.attributes?.href?.value &&
      trackEvent(
        getEspotImpressionOrClickEvent(
          htmlContent,
          locale,
          espotType,
          position,
          'promoClick'
        )
      );
  };

  return (
    <>
      <PurifiedHtml
        aria-hidden="true"
        className={className}
        data-testid="espotData"
        forceBody
        htmlString={html}
        onClick={onEspotClick}
        ref={ref}
      />
      {isRefAvailable && renderPortal()}
    </>
  );
};

export default EspotContent;

EspotContent.propTypes = {
  html: PropTypes.arrayOf(
    PropTypes.shape({
      html: PropTypes.string,
      creative: PropTypes.string,
      range: PropTypes.string,
      source: PropTypes.string
    })
  ),
  espotType: PropTypes.string,
  position: PropTypes.number,
  setCarouselHeight: PropTypes.func,
  className: PropTypes.string
};
