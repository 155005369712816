import {
  MyEquipmentHeader,
  MyEquipmentInfoToolTip
} from '@cat-ecom/pcc-components';
import { bool, func } from 'prop-types';
import { useTranslation } from 'react-i18next';
import styles from './AddEquipmentDrawer.module.scss';

const AddEquipmentHeader = ({ isChild, onClose }) => {
  const [t] = useTranslation();
  return (
    <MyEquipmentHeader
      variant={'title-sm'}
      className="d-flex"
      onBackButtonClick={isChild ? onClose : null}
    >
      <span className={`${styles['equipment-header-text']}`}>
        {t('ADD_EQUIPMENT_DRAWER_HEADING')}
      </span>
      <MyEquipmentInfoToolTip
        align="bottom"
        ariaLabel={`${t('PLEASE_NOTE')} ${t('MEQ_SUPPORTED_EQUIP1')}`}
        className={`${styles['equipment-header-tooltip']}`}
        label={t('MEQ_SUPPORTED_EQUIP1')}
      >
        <span className="fw-bold me-1">{t('PLEASE_NOTE')}</span>
      </MyEquipmentInfoToolTip>
    </MyEquipmentHeader>
  );
};

AddEquipmentHeader.propTypes = {
  isChild: bool,
  onClose: func
};

export default AddEquipmentHeader;
