import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useBreakpoint } from '@app/hooks';
import {
  CAT_CARD_MENU_CRITERIA_LANGID,
  CAT_CARD_MENU_FRENCH_TITLE,
  LUCID_CATEGORY_API_PARAMS,
  PAGE_BREAKPOINTS
} from '@app/constants/commonConstants';
import {
  ProductCategories,
  SubheaderShopByCategory
} from '@cat-ecom/pcc-components';
import { isFunctionalCookiesEnable } from '@app/utils/cookieUtils';
import { getLucidProductCategory } from '@app/store/categories/actions';
import { APP_BANNERS, HEADER } from '@app/constants/targets';
import { isCatCardMenu, validateIfActive } from './utils';

const ShopByCategoryLucid = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const currentPage = pathname?.split('/').slice(-1)[0];
  const [isAccepted, setIsAccepted] = useState(false);
  const baseUrl = useSelector(s => s?.dealer?.seoURL);
  const { defaultCountry: country } = useSelector(s => s?.dealer);
  const { isCSPCustomer, isInstantAccess, locale } = useSelector(
    state => state?.common
  );
  const [topOffset, setTopOffset] = useState(0);
  const isIAUser = !isCSPCustomer && isInstantAccess;

  const isCatCard = useMemo(() => {
    return isCatCardMenu(country, locale) && (isCSPCustomer || isIAUser);
  }, [country, isCSPCustomer, isIAUser]);

  const catCardMenuTitle = useMemo(() => {
    return locale === CAT_CARD_MENU_CRITERIA_LANGID.fr_FR
      ? CAT_CARD_MENU_FRENCH_TITLE
      : t('CAT_DEALERPYMTTYPES_CatCard');
  }, [locale]);

  const dispatch = useDispatch();
  const TRANSLATIONS = {
    heading: t('PARTS'),
    subHeading: t('SEE_ALL_CATEGORIES'),
    buyingOptionLabel: t('BUYING_OPTIONS'),
    subTitle: t('SEE_ALL'),
    ssoLabel: t('ALP_SSRO_TITLE'),
    catCardMenuLabel: catCardMenuTitle
  };
  const { data: lucidProductCategories } = useSelector(
    s => s.categories?.lucidProductCategories
  );
  const allCategoriesWithActiveProp = lucidProductCategories?.categories?.map(
    item => {
      const isActive = validateIfActive({
        url: item.seoUrlKeyword,
        currentPage
      });
      return {
        ...item,
        isActive
      };
    }
  );

  const buyingOptionsWithActiveProp =
    lucidProductCategories?.buyingOptions?.map(item => {
      const isActive = validateIfActive({
        url: item.seoUrlKeyword,
        currentPage
      });
      return {
        ...item,
        isActive
      };
    });

  const isUserInPLPPage =
    Array.isArray(allCategoriesWithActiveProp) &&
    Array.isArray(buyingOptionsWithActiveProp) &&
    [...allCategoriesWithActiveProp, ...buyingOptionsWithActiveProp]?.some(
      ({ isActive }) => isActive
    );

  const isMobile = !useBreakpoint(PAGE_BREAKPOINTS.BLOCKS_LG);

  function isCookieAccepted() {
    let cookie = isFunctionalCookiesEnable();
    setIsAccepted(() => cookie);
  }

  useEffect(() => {
    isCookieAccepted();
    window.addEventListener('OneTrustGroupsUpdated', () => {
      isCookieAccepted();
    });
    dispatch(getLucidProductCategory(LUCID_CATEGORY_API_PARAMS));

    const bannersRootElement = document.getElementById(APP_BANNERS);
    const headerRootElement = document?.getElementById(HEADER);
    if (headerRootElement && !bannersRootElement) {
      setTopOffset(headerRootElement?.getBoundingClientRect()?.height);
    }
    if (bannersRootElement && headerRootElement) {
      const observer = new ResizeObserver(() => {
        const calculatedOffset =
          bannersRootElement?.getBoundingClientRect()?.height +
          headerRootElement?.getBoundingClientRect()?.height;
        setTopOffset(calculatedOffset);
      });
      observer.observe(bannersRootElement);
      return () => observer.disconnect();
    }
  }, []);

  return (
    <SubheaderShopByCategory
      label={t('SHOP')}
      isMobile={isMobile}
      isCurrent={isUserInPLPPage}
      isFeatureFlag
    >
      <ProductCategories
        categoriesList={lucidProductCategories?.categories}
        buyingOptions={lucidProductCategories?.buyingOptions}
        translations={TRANSLATIONS}
        isCookieAccepted={isAccepted}
        isCatCard={isCatCard}
        url={baseUrl}
        isMobile={isMobile}
        topOffset={topOffset}
      />
    </SubheaderShopByCategory>
  );
};

export default ShopByCategoryLucid;
