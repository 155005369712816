import { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { CatButton } from 'blocks-react/bedrock/components/Button';
import { CatPopover } from 'blocks-react/bedrock/components/Popover';
import { CatIconMessageMultiple } from '@blocks-react/components/Icons/MessageMultiple';
import { useEspot } from '@app/hooks';
import useHelpCenterLink from '../csr-landing/hooks/useHelpCenterLink';
import { isRTLLang } from '@app/utils';
import styles from './HelpcenterChatWidget.module.scss';

const HelpcenterChatWidget = () => {
  const [t] = useTranslation();
  const { getHelpCenterURL } = useHelpCenterLink();
  const [isCursorOverWidget, setCursorOverWidget] = useState(false);
  const helpCenterEnabled = useSelector(
    s => s.featureFlag?.PCCCustomerSupportHelpCenter
  );
  const isBuyOnBehalf = useSelector(s => s.common?.isBuyOnBehalf);
  const isCatCSR = useSelector(s => s.common?.isCatCSR);
  const PCCSalesForceChat = useSelector(s => s.featureFlag?.PCCSalesForceChat);
  const isCatCorp = useSelector(s => s.common?.isCatCorp);
  const langId = useSelector(s => s.common.langId);
  const popOverRef = useRef(null);
  const timerTimeoutID = useRef(null);
  const selfDisplayRef = useRef(true);
  const isCSRAgent = isBuyOnBehalf || isCatCSR;
  const showWidget =
    (isCatCorp || helpCenterEnabled) && !PCCSalesForceChat && !isCSRAgent;
  const isRtl = isRTLLang(langId);
  const PROACTIVE_CHAT_TIME = 'PROACTIVE_CHAT_TIME_TRIGGER';
  const { getEspotContent } = useEspot(PROACTIVE_CHAT_TIME);
  const espotContent = getEspotContent(PROACTIVE_CHAT_TIME);
  const minutesSettedByUser =
    espotContent?.contents?.length > 0 && espotContent?.contents[0]?.html;
  const linkToHelpChat = `${getHelpCenterURL()}&chatopen=true`;

  const timeoutValue = useMemo(
    () => +minutesSettedByUser * 1000 * 60,
    [minutesSettedByUser]
  );

  const handleDisplayContent = useCallback(() => {
    if (timerTimeoutID.current) {
      clearTimeout(timerTimeoutID.current);
      timerTimeoutID.current = null;
    }

    if (timeoutValue && selfDisplayRef.current === true) {
      timerTimeoutID.current = setTimeout(() => {
        popOverRef.current.open();
        selfDisplayRef.current = false;
      }, timeoutValue);
    }
  }, [timeoutValue]);

  const togglePopover = useCallback(isMouseOverWidget => {
    const isSelfDisplayed =
      isMouseOverWidget &&
      popOverRef.current.isActive &&
      !selfDisplayRef.current;
    const breakToggle = !popOverRef.current || isSelfDisplayed;

    if (breakToggle) {
      return;
    }

    if (isMouseOverWidget && !popOverRef.current.isActive) {
      popOverRef.current.open();
    } else {
      popOverRef.current.close();
      selfDisplayRef.current = false;
    }
  }, []);

  useEffect(() => {
    handleDisplayContent();
    document.addEventListener('click', handleDisplayContent);

    return () => {
      document.removeEventListener('click', handleDisplayContent);
    };
  }, [handleDisplayContent]);

  useEffect(() => {
    togglePopover(isCursorOverWidget);
  }, [togglePopover, isCursorOverWidget]);

  if (!showWidget) {
    return null;
  }

  const handleMouseEnter = () => {
    if (isCursorOverWidget && !popOverRef.current.isActive) {
      togglePopover(true);
      return;
    }

    setCursorOverWidget(true);
  };

  const rightToleftLangChatbox = `position-fixed top-0 start-0 ${styles['helpcenter-chat-widget--container']}`;
  const leftTorightLangChatbox = `position-fixed top-0 end-0 ${styles['helpcenter-chat-widget--container']}`;

  return (
    <section
      onMouseEnter={handleMouseEnter}
      onMouseLeave={() => setCursorOverWidget(false)}
    >
      <CatPopover
        ref={popOverRef}
        className={isRtl ? rightToleftLangChatbox : leftTorightLangChatbox}
        position="bottom-end"
        heading={t('INTERCEPTIVE_CHAT_HELP_MESSAGE')}
        headingVariant="title"
        headingTagName="h3"
        hoist
      >
        <>
          <CatButton
            slot="trigger"
            data-testid="chatWidgetButton"
            href={linkToHelpChat}
            target="_blank"
          >
            <div className="py-1">
              <CatIconMessageMultiple size="lg" />
            </div>
          </CatButton>
        </>
        <div>
          <CatButton
            variant="primary"
            href={linkToHelpChat}
            target="_blank"
            fullWidth
          >
            {t('INTERCEPTIVE_CHAT_EXPERT_CTA')}
          </CatButton>
        </div>
      </CatPopover>
    </section>
  );
};

export default HelpcenterChatWidget;
