import Conditional from '@app/components/common/Conditional';
import { USER_TYPE_GUEST } from '@app/constants/commonConstants';
import { isEmpty, isHomepage } from '@app/utils';
import { useSelector } from 'react-redux';
import {
  OUTAGE_MESSAGE,
  OUTAGE_MESSAGE_TYPE,
  OUTAGE_MESSAGE_WARNING_IMAGE_PROPS
} from '../constants';
import { replaceNamedTokenInString } from '../utils';
import { lazy } from 'react';
import { useECT } from '@app/hooks';

const CommonBannerNotification = lazy(
  () =>
    import(
      /* webpackChunkName: 'commonBannerNotification' */ '../CommonBannerNotification'
    )
);

const ErpOutageNotification = () => {
  const userType = useSelector(s => s?.common?.userType);
  const erpOutageInformationDetails = useSelector(
    state => state.dealer?.outage
  );

  const ERP_OUTAGE_MESSAGE_FEATURE_FLAG = 'PCC_ECTErpOutageMessage';
  const erpFeatureFlag = useSelector(
    state => state.featureFlag?.[ERP_OUTAGE_MESSAGE_FEATURE_FLAG]
  );
  const { data: ectData } = useECT(OUTAGE_MESSAGE_TYPE, OUTAGE_MESSAGE);

  const erpOutageEspotNotification = ectData?.text?.length > 0 && ectData?.text;
  const isLoggedIn = userType !== USER_TYPE_GUEST;
  const showErpOutageNotification = erpFeatureFlag
    ? isLoggedIn && isHomepage() && !isEmpty(erpOutageEspotNotification)
    : isLoggedIn &&
      isHomepage() &&
      !isEmpty(erpOutageInformationDetails) &&
      !isEmpty(erpOutageEspotNotification);

  const erpOutageNotification = replaceNamedTokenInString(
    erpOutageEspotNotification,
    erpOutageInformationDetails
  );
  return (
    <Conditional test={showErpOutageNotification}>
      <CommonBannerNotification
        message={erpOutageNotification}
        imageProps={OUTAGE_MESSAGE_WARNING_IMAGE_PROPS}
        isTextMessage={true}
      />
    </Conditional>
  );
};
export default ErpOutageNotification;
