import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useHeaderAnalytics from '../hooks/useHeaderAnalytics';
import CartDrawer from './CartDrawer/CartDrawer';
import { HeaderCart, Portal, useDrawer } from '@cat-ecom/pcc-components';
import { DRAWERS_ROOT } from '@app/constants/targets';
import links from '@app/constants/links';
import { getCurrentOrderItemCount } from './utils';
import { returnFocusToNavItem } from '@app/utils/DOMUtils';
import { replaceTokensInString } from '@app/utils';
import Conditional from '@app/components/common/Conditional';

const HeaderCartWrapper = () => {
  const { t } = useTranslation();
  const { productImpressionEvent } = useHeaderAnalytics();

  const currentOrderId = useSelector(s => s?.orders?.current);
  const storeId = useSelector(s => s.common?.storeId);
  const langId = useSelector(s => s.common?.langId);
  const catalogId = useSelector(s => s.common?.catalogId);
  const { SHOPPING_CART, SHOPPING_CART_URL_PARAMETER } = links;
  const shoppingCartUrlParameter = encodeURI(
    replaceTokensInString(SHOPPING_CART_URL_PARAMETER, storeId)
  );
  const shoppingCartUrl = replaceTokensInString(
    SHOPPING_CART,
    catalogId,
    langId,
    shoppingCartUrlParameter
  );

  const currentOrderItems =
    useSelector(s => s?.orders?.byId[currentOrderId]?.ascendingOrderItems) ||
    [];
  const isCartEmpty = currentOrderItems.length === 0;

  const numItems = getCurrentOrderItemCount(currentOrderItems);
  const { closeDrawer, initiatorProps, drawerProps, isDrawerOpen } = useDrawer({
    onClose: () => returnFocusToNavItem('#headerCart'),
    onOpen: () => {
      if (!isCartEmpty) {
        productImpressionEvent(currentOrderItems);
      }
    }
  });
  const cartDrawerProps = {
    closeDrawer,
    ...drawerProps
  };
  return (
    <>
      <HeaderCart
        title={t('ENCLOSED_SHOPPING_INFO')}
        cartItems={numItems}
        handleDrawerProps={initiatorProps}
        id="headerCart"
        isCurrent={isDrawerOpen}
        redirectionLink={shoppingCartUrl}
      />
      <Conditional test={false}>
        <Portal id={DRAWERS_ROOT}>
          <CartDrawer {...cartDrawerProps} />
        </Portal>
      </Conditional>
    </>
  );
};

export default HeaderCartWrapper;
