import { removeCookie, removeSessionStorage } from '@app/utils';
import {
  DROPBOX,
  SHOP_ONLINE,
  PICKUP,
  DELIVERY,
  MICO,
  LOGGED,
  GUEST,
  SUMMARY_AND_PAYMENT
} from '../../components/pages/checkout/PickUpAndDelivery/constants';
import { isEmpty } from '../../utils';
import { v4 } from 'uuid';
import { format } from 'date-fns';
import { DATE_FORMAT_YYYY_MM_DD } from '@app/constants/commonConstants';
import { updateDateAndEquipment } from './actions';
import { DATE_UPDATED } from './constants';
export const sortAlphabeticallyByName = list =>
  list.sort((a, b) => {
    if (a.address1 < b.address1) {
      return -1;
    }
    if (a.address1 > b.address1) {
      return 1;
    }
    return 0;
  });

export const separateWithAndWithoutDistance = list => {
  let hasDefault = false;
  const separated = list.reduce(
    (acc, a) => {
      if (a.default) {
        hasDefault = true;
      }
      if (a.distance) {
        return {
          ...acc,
          withDistance: [...acc.withDistance, a]
        };
      }
      return {
        ...acc,
        withoutDistance: [...acc.withoutDistance, a]
      };
    },
    { withDistance: [], withoutDistance: [] }
  );
  return { hasDefault, separated };
};

export const prepareAddressesWithDistance = list =>
  list
    .sort((a, b) => a.distance - b.distance)
    .map((a, i) => {
      // since we already sorted by distance we will set isClosest to true on first one
      return {
        ...a,
        isClosest: i === 0
      };
    });

export const processDropBoxData = (addresses, fulfillmentOptions) => {
  // sort all alphabetically
  const sorted = sortAlphabeticallyByName(addresses);
  // separate with and without distance
  const { hasDefault, separated } = separateWithAndWithoutDistance(sorted);
  //merge separated listsafter preparing those withDistance
  let newAddresses = [
    // sort withDistance list by distance and add isClosest flag
    ...prepareAddressesWithDistance(separated.withDistance),
    ...separated.withoutDistance
  ];
  // assign a default if default type is dropBox and no default is selected from preferences or move the default to first in the array

  newAddresses = newAddresses.reduce((acc, a, i) => {
    if (!hasDefault) {
      acc.push({ ...a, default: i === 0 });
    } else {
      if (a.default) {
        acc.unshift(a);
      } else {
        acc.push(a);
      }
    }
    return acc;
  }, []);

  return {
    ...fulfillmentOptions,
    address: newAddresses
  };
};

/**
 * Util from legacy add to cart to reset cookie and storage data
 */
export const removeFreightDataFromSession = () => {
  removeSessionStorage('orderTotalsSummary');
  removeSessionStorage('pricedFreightCharges');
  removeSessionStorage('ec');
  removeCookie('freq');
};
/**
 * util to use legacy SOS flow
 * @param {Object} param
 * @param {String} param.data html string passed from SOS service
 * @param {String} param.storeId storeId from redux
 * @param {String} param.catalogId catalogId from redux
 * @param {String} param.langId langId from redux
 * @param {Boolean} param.skipSBPage user preference to skip sb page
 * @param {Boolean} param.oneClickBuy whether user is adding to cart anbd proceeding
 * @param {Boolean} param.isSOSFlowQuickOrder whether is SOS flow
 * @param {Boolean} param.fromShoppingCart whether is from shopping cart
 */
export const handleSOSFlow = ({ data, ...rest }) => {
  const mainShoppingCart = document.getElementsByClassName(
    'shopping-cart__wrapper'
  )[0];
  mainShoppingCart.classList.add('d-none');
  const sosShoppingCartWrapper = document.getElementsByClassName(
    'shopping-cart__wrapper--sos'
  )[0];
  sosShoppingCartWrapper.classList.remove('d-none');
  const sosShoppingCartContainer = document.getElementsByClassName(
    'shopping-cart__container--sos'
  )[0];
  const legacyForm = document.createElement('form');
  legacyForm.id = 'MQuickOrderForm';
  legacyForm.name = 'MQuickOrderForm';
  legacyForm.method = 'post';
  legacyForm.innerHTML = data.replace('myaccount_header box_look', 'd-none');
  const hiddenInputs = {
    ...rest,
    quickOrderParts: undefined,
    quickOrderPartsQty: undefined
  };
  for (const key in hiddenInputs) {
    const input = document.createElement('input');
    input.type = 'hidden';
    input.id = key;
    input.value = hiddenInputs[key];
    input.name = key;
    legacyForm.append(input);
  }
  sosShoppingCartContainer.append(legacyForm);
};

/**
 * util to create payload for legacy sos service
 * @param {object} defaultPayload
 * @param {String} defaultPayload.storeId storeId from redux
 * @param {String} defaultPayload.catalogId catalogId from redux
 * @param {String} defaultPayload.langId langId from redux
 * @param {String} defaultPayload.cartProdList starting product list
 * @param {String} defaultPayload.requestType requestype
 * @param {Number} defaultPayload.noOfItems number of items in cart
 * @param {Number} defaultPayload.calculationUsage calculation usage
 * @param {Boolean} param.oneClickBuy whether user is adding to cart anbd proceeding
 * @param {Boolean} param.fromPage page
 * @param {Boolean} param.skipSBPage user preference to skip sb page
 * @param {String} param.orderId id of current order
 * @param {ItemsObject[]} items
 * @returns {URLSearchParams} payload
 */
export const getSOSServicePayload = (defaultPayload, items) => {
  let payloadParams = new URLSearchParams(defaultPayload);
  return items.reduce((acc, item, index) => {
    const itemNumber = item.itemNumber.trim();
    const quantity = item.quantity.trim();
    if (quantity && itemNumber) {
      const num = index + 1;
      acc.set(`partNumber_${num}`, itemNumber);
      acc.set(`serialNumber_${num}`, num);
      acc.set(`quantity_${num}`, quantity);
      acc.set(
        `cartProdList`,
        `${
          acc.get('cartProdList').length ? acc.get('cartProdList') + ',' : ''
        }${itemNumber}:${quantity}`
      );
      return acc;
    }
    return acc;
  }, payloadParams);
};

/**
 * util to re-arrange the data for SOS service
 * @param {ItemsObject[]} items items from quick order
 * @returns {Object} payload
 */
export const createSOSServicePayload = items => {
  const partsList = items.reduce((acc, item) => {
    const itemNumber = item.itemNumber.trim();
    const quantity = item.quantity.trim();

    const quantityfloatValue = parseFloat(quantity);

    const quantityintValue = parseInt(quantityfloatValue, 10);

    const id = item.id;
    if (quantity && itemNumber) {
      return [
        ...acc,
        { partNumber: itemNumber, quantity: quantityintValue, referenceId: id }
      ];
    }
    return acc;
  }, []);
  return {
    partsList
  };
};

/**
 * Formats part number to remove - and upper case all letters
 * to maintain uniformity for currentAddToCartModifications.items array keys
 * @param {String} partNumber
 * @returns formatted part number
 */
export const formatPartNumber = partNumber =>
  partNumber.replace('-', '').toUpperCase();

/**
 * util to re-arrange the data for dealer price and availability service
 * @param {ItemsObject[]} items items from checkout reducer (currentAddToCartModifications.items)
 * @return {Object} payload
 */
export const buildDealerPriceAndAvailabilityBody = items => ({
  partsList: Object.keys(items).reduce((newItems, key) => {
    const item = items[key];
    return [
      ...newItems,
      {
        referenceId: key,
        partNumber: item.sosList[item.selectedSOS].sosPartNumber,
        quantity: item.requestedQuantity,
        sosCode: item.sosList[item.selectedSOS].sosCode
      }
    ];
  }, [])
});

/**
 * util to transform orderItems into an object with the form of currentAddToCartModifications.items
 * @param {[]} orderItems items from current order
 * @returns {Object}
 */
export const orderItemsToObject = (orderItems = []) =>
  orderItems.reduce((orderItemsObject, item) => {
    const referenceId = v4();
    const requestedQuantity = item.quantity;
    return {
      ...orderItemsObject,
      [referenceId]: { ...item, referenceId, requestedQuantity }
    };
  }, {});

/**
 * @param {[]} extraReplacementParts
 * @param {Object} currentItems
 * @returns
 */
export const buildDealerPriceAndAvailabilityWithExtraReplacementBody = (
  extraReplacementParts,
  currentItems
) => ({
  partsList: Object.values(currentItems)
    .filter(item =>
      extraReplacementParts.some(
        p =>
          formatPartNumber(item.partNumber) === formatPartNumber(p.partNumber)
      )
    )
    .map(item => {
      const referenceId = item.referenceId;
      const partNumber = item.partNumber;
      const quantity = (
        item.requestedQuantity ||
        item.quantity ||
        0
      ).toString();
      const sosCode = item.sosList?.[item.selectedSOS]?.sosCode || item.sosCode;
      return { referenceId, partNumber, quantity, sosCode };
    })
});

/**
 * util to re-arrange the data in order to store it in the reducer with price and availability info
 * @param {ItemsObject[]} items items from price and availability service response
 * @param {Object} currentItems parts stored within checkout reducer
 * @return {Object} payload
 */
export const arrangeAlternateReplacedOptions = (items, currentItems = {}) =>
  items?.reduce((newItems, part) => {
    // Add a referenceId to alternate and replacement parts to keep track of individual part's quantities
    const remanParts = part.remanParts.map(item => ({
      ...item,
      referenceId: v4()
    }));
    const classicParts = part.classicParts.map(item => ({
      ...item,
      referenceId: v4()
    }));
    const alternateParts = part.alternateParts.map(item => ({
      ...item,
      referenceId: v4()
    }));
    const replacementParts = part.replacementParts.map(item => ({
      ...item,
      referenceId: v4()
    }));
    return {
      ...newItems,
      [part.referenceId]: {
        ...(currentItems[part.referenceId] || {}),
        ...part,
        alternateParts,
        classicParts,
        remanParts,
        replacementParts
      }
    };
  }, {});

export const filterUnmatchedElements = (
  originalArray,
  comparedArray,
  filterKey
) => {
  // Filter array based on newly added array of objects and old array of objects
  return originalArray.filter(
    item => !comparedArray.some(it => it[filterKey] === item[filterKey])
  );
};

const getDropBoxAddressLine1 = (address1, address2) => {
  let addressLine1 = '';
  if (address1 && address2) {
    addressLine1 = `${address1}_${address2}`;
  } else if (address1) {
    addressLine1 = address1;
  } else {
    addressLine1 = address2;
  }
  return addressLine1;
};
const normalize = value => value ?? '';
const getUserType = deliveryAddress => {
  const isLoggedUser = deliveryAddress?.isLoggedUser;
  const isMicoUser = deliveryAddress?.isMicoUser;
  if (isMicoUser) {
    return MICO;
  } else if (isLoggedUser) {
    return LOGGED;
  } else {
    return GUEST;
  }
};
const getPayloadType = (fulfillment, deliveryAddress) => {
  const userType = getUserType(deliveryAddress);
  return `${fulfillment}${userType}`;
};

export const PUDPayloadBuilder = ({ deliveryAddress, formData }) => {
  const fulfillment = deliveryAddress?.selectedFulfillmentMethod;
  const dropBoxInfo = formData[DROPBOX]?.selectedDropboxLocation;

  // If empty, default should be 'yes', so we need to know if value is explicit 'no'
  const endUseNotAccepted = formData?.endUseCertification === 'no';
  const getShipModeId =
    formData?.[fulfillment]?.shipModeId ||
    formData?.shipModeId ||
    deliveryAddress?.shipModeId ||
    '';
  // Declaring common blocks of information to pass whatever is needed on each scenario
  const generalInfo = {
    orderId: formData?.orderId || deliveryAddress?.orderId,
    isTermsConditionsAccepted: formData?.caterpillarLocalPrivacyStatement,
    poNumber: '', // empty as per WCS team request, comes as formData?.poNumber
    requestByDate: deliveryAddress?.requestByDateFormatted,
    isSkipTaxAndFreight: formData?.isSkipTaxAndFreight,
    isEndUseCertificationAccepted: !endUseNotAccepted
  };
  const marketingOptinInfo = {
    isCATEmailMarketingOptin: formData?.dealerLocalReceiveEmail, //receiveEmail
    isDealerEmailMarketingOptin: formData?.dealerReceiveEmail //dealerReceiveEmail
  };
  const taxInfo = {
    stateTaxId: normalize(formData?.stateTaxId),
    neighborhood: normalize(formData?.neighborhood),
    taxIdOption: normalize(formData?.taxIdOption),
    individualTaxId: normalize(formData?.Individual),
    companyTaxId: normalize(formData?.Company),
    taxCredit: normalize(formData?.taxCredit),
    individualtaxIdOption: normalize(formData?.IndividualtaxIdOption)
  };
  const orderedByInfo = {
    name: normalize(
      `${formData?.orderedByFirstName} ${formData?.orderedByLastName}`
    ),
    email: normalize(formData?.orderByEmail),
    phone: normalize(formData?.orderByPhone)
  };
  const fulfillmentInfo = {
    shipModeId: getShipModeId,
    type: fulfillment === DROPBOX ? fulfillment : fulfillment.toLowerCase(),
    method: {
      code: normalize(formData?.[fulfillment]?.method?.code),
      label: normalize(formData?.[fulfillment]?.method?.displayName)
    },
    instructions: {
      code: normalize(formData?.[fulfillment]?.instructions?.code),
      label: normalize(formData?.[fulfillment]?.instructions?.displayName)
    },
    specialInstructions: normalize(formData?.[fulfillment]?.specialInstructions)
  };
  // We pass this object so we can fill empty spaces and
  // pass information as needed on each scenario
  const shippingAddress = {
    addressId: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    line1: '',
    line2: '',
    line3: '',
    country: '',
    city: '',
    state: '',
    zipCode: ''
  };
  const userAddressInfo = {
    firstName: normalize(formData?.orderedByFirstName),
    lastName: normalize(formData?.orderedByLastName),
    email: normalize(formData?.orderByEmail),
    phone: normalize(formData?.orderByPhone)
  };
  const dropboxShippingAddress = {
    ...shippingAddress,
    line1: getDropBoxAddressLine1(dropBoxInfo?.address1, dropBoxInfo?.address2),
    line3: normalize(dropBoxInfo?.address3),
    country: 'DB',
    city: normalize(dropBoxInfo?.city),
    state: normalize(dropBoxInfo?.state),
    zipCode: 'DB'
  };
  const billingAddress = {
    ...shippingAddress,
    companyName: normalize(formData?.companyName)
  };
  const scenario = getPayloadType(fulfillment, deliveryAddress);
  switch (scenario) {
    case `${SHOP_ONLINE}${GUEST}`:
      return {
        ...generalInfo,
        shippingAddress: {
          ...shippingAddress,
          ...userAddressInfo,
          line1: normalize(deliveryAddress?.address1),
          line2: normalize(deliveryAddress?.address2),
          line3: normalize(deliveryAddress?.address3),
          country: normalize(deliveryAddress?.country),
          city: normalize(deliveryAddress?.city),
          state: normalize(deliveryAddress?.state),
          zipCode: normalize(deliveryAddress?.zipCode)
        },
        billingAddress: {
          ...billingAddress,
          ...userAddressInfo,
          line1: normalize(deliveryAddress?.address1),
          country: normalize(deliveryAddress?.country),
          city: normalize(deliveryAddress?.city),
          state: normalize(deliveryAddress?.state),
          zipCode: normalize(deliveryAddress?.zipCode)
        },
        marketingOptinInfo,
        orderedByInfo,
        taxInfo,
        fulfillmentInfo: {
          ...fulfillmentInfo,
          shipModeId: getShipModeId,
          type: DELIVERY,
          method: {
            code: normalize(
              formData?.deliveryMethod?.code ||
                formData?.deliveryMethod?.shipViaCode
            ),
            label: normalize(formData?.deliveryMethod?.displayName)
          }
        }
      };
    case `${SHOP_ONLINE}${LOGGED}`:
      return {
        ...generalInfo,
        marketingOptinInfo,
        fulfillmentInfo: {
          ...fulfillmentInfo,
          type: DELIVERY,
          method: {
            code: normalize(formData?.deliveryMethod?.code),
            label: normalize(formData?.deliveryMethod?.displayName)
          }
        },
        shippingAddress: {
          ...shippingAddress,
          addressId: normalize(formData[SHOP_ONLINE]?.address?.addressId)
        }
      };
    case `${SHOP_ONLINE}${MICO}`:
      return {
        ...generalInfo,
        shippingAddress: {
          ...shippingAddress,
          ...userAddressInfo,
          addressId: '',
          line1: normalize(formData[SHOP_ONLINE]?.newAddress?.address1),
          line2: normalize(formData[SHOP_ONLINE]?.newAddress?.address2),
          line3: normalize(formData[SHOP_ONLINE]?.newAddress?.address3),
          country: normalize(formData[SHOP_ONLINE]?.address?.country),
          city: normalize(formData[SHOP_ONLINE]?.newAddress?.city),
          state: normalize(formData[SHOP_ONLINE]?.newAddress?.state),
          zipCode: normalize(formData[SHOP_ONLINE]?.newAddress?.zipCode)
        },
        billingAddress: {
          ...billingAddress,
          ...userAddressInfo,
          addressId: normalize(deliveryAddress?.userInfoAddressId),
          line1: normalize(formData[SHOP_ONLINE]?.newAddress?.address1),
          country: normalize(formData[SHOP_ONLINE]?.address?.country),
          city: normalize(formData[SHOP_ONLINE]?.newAddress?.city),
          state: normalize(formData[SHOP_ONLINE]?.newAddress?.state),
          zipCode: normalize(formData[SHOP_ONLINE]?.newAddress?.zipCode)
        },
        marketingOptinInfo,
        taxInfo,
        fulfillmentInfo: {
          ...fulfillmentInfo,
          type: DELIVERY,
          method: {
            code: normalize(formData?.deliveryMethod?.code),
            label: normalize(formData?.deliveryMethod?.displayName)
          }
        }
      };
    case `${PICKUP}${GUEST}`:
      return {
        ...generalInfo,
        shippingAddress: {
          ...shippingAddress,
          ...userAddressInfo
        },
        billingAddress: {
          ...billingAddress,
          ...userAddressInfo
        },
        marketingOptinInfo,
        orderedByInfo,
        taxInfo,
        fulfillmentInfo: {
          ...fulfillmentInfo,
          shipModeId: formData?.shipModeId || deliveryAddress?.shipModeId
        }
      };
    case `${PICKUP}${LOGGED}`:
      return {
        ...generalInfo,
        marketingOptinInfo,
        fulfillmentInfo,
        taxInfo
      };
    case `${PICKUP}${MICO}`:
      return {
        ...generalInfo,
        billingAddress: {
          ...billingAddress,
          ...userAddressInfo,
          addressId: normalize(deliveryAddress?.userInfoAddressId)
        },
        marketingOptinInfo,
        taxInfo,
        fulfillmentInfo
      };
    case `${DROPBOX}${GUEST}`:
      return {
        ...generalInfo,
        shippingAddress: dropboxShippingAddress,
        billingAddress: {
          ...billingAddress,
          ...userAddressInfo
        },
        marketingOptinInfo,
        taxInfo,
        orderedByInfo,
        fulfillmentInfo: {
          ...fulfillmentInfo,
          shipModeId:
            formData?.[SHOP_ONLINE]?.shipModeId ||
            formData?.shipModeId ||
            deliveryAddress?.shipModeId ||
            ''
        }
      };
    case `${DROPBOX}${LOGGED}`:
      return {
        ...generalInfo,
        shippingAddress: dropboxShippingAddress,
        marketingOptinInfo,
        fulfillmentInfo
      };
    case `${DROPBOX}${MICO}`:
      return {
        ...generalInfo,
        shippingAddress: dropboxShippingAddress,
        billingAddress: {
          ...billingAddress,
          ...userAddressInfo,
          addressId: normalize(deliveryAddress?.userInfoAddressId)
        },
        marketingOptinInfo,
        fulfillmentInfo,
        taxInfo
      };
    default:
      return {
        ...generalInfo,
        marketingOptinInfo,
        ...fulfillmentInfo
      };
  }
};

export const buildAddQOToCartBody = (items = {}, replacedParts = []) => ({
  parts: Object.keys(items).flatMap((key, index) => {
    const part = items[key];
    return [
      {
        partNumber: part.partNumber,
        partName: part.partName,
        quantity: part.requestedQuantity,
        sosCode: part.sosCode ?? part.sosList?.[part.selectedSOS]?.sosCode,
        isExistingInCatalog: part.partLevelFlags?.isPartExistInCatalog,
        isDealerPartInCatalog: part.partLevelFlags?.isDealerPartInCatalog,
        isCatPart: part.partLevelFlags?.isCatPart,
        customerSerialNumber: part.assetId ?? '',
        customerPartNumber: part.customerPartNumber ?? '',
        lineItemNote: part?.originalReplacedPartNum
          ? `${part.lineItemNote ?? ''}${
              part.lineItemNote ? '/' : ''
            }Replaces Part # (${part.originalReplacedPartNum})`
          : part.lineItemNote ?? '',
        customerItemNumber: part.customerItemNumber ?? '',
        ...(replacedParts[index]?.orderItemId && {
          orderItemId: replacedParts[index].orderItemId
        })
      }
    ];
  })
});

const capitalizeAndTrim = str => str.toUpperCase().trim();

/**
 * util to re-arrange the data in order to store it in the reducer with quick order line item values
 * @param {Object} currentAddToCartModifications object from reducer with currentAddToCartModifications info
 * @param {Object} currentAddToCartModifications.items parts stored within checkout reducer
 * @return {Object} payload
 */
export const arrangeSOSData = (data, quickOrderValues) => {
  const items = {
    ...data,
    items: Object.fromEntries(
      quickOrderValues
        ?.filter(node => node.quantity && node.itemNumber)
        .map(node => {
          const key = node.id;
          const itemByRefId = data?.items.find(
            item => item.referenceId === key
          );
          if (isEmpty(itemByRefId)) {
            const itemByPartNumber = data?.items.find(
              item =>
                capitalizeAndTrim(item.partNumber) ===
                capitalizeAndTrim(node.itemNumber)
            );
            return [
              key,
              {
                ...itemByPartNumber,
                ...node,
                requestedQuantity: `${node.quantity}`,
                referenceId: key,
                selectedSOS: 0
              }
            ];
          } else {
            return [key, { ...itemByRefId, ...node, selectedSOS: 0 }];
          }
        })
    )
  };
  return items;
};

export const ALTERNATIVE_TYPES = {
  ALTERNATE: 'alternateParts',
  CLASSIC: 'classicParts',
  REMANUFACTURED: 'remanParts'
};
/**
 * Returns the usable list of alternative parts
 * @param {cartItemPropType} item
 * @returns {[cartItemPropType]} returns array containing all alternate, classic, and reman parts
 */
export const getAltParts = (item, originalPartReferenceId = null) => {
  return [
    ...(item?.alternateParts ?? []).map(i => ({
      ...i,
      originalPartNumber: formatPartNumber(item?.partNumber),
      alternativeType: ALTERNATIVE_TYPES.ALTERNATE,
      originalPartReferenceId
    })),
    ...(item?.classicParts ?? []).map(i => ({
      ...i,
      originalPartNumber: formatPartNumber(item?.partNumber),
      alternativeType: ALTERNATIVE_TYPES.CLASSIC,
      originalPartReferenceId
    })),
    ...(item?.remanParts ?? []).map(i => ({
      ...i,
      originalPartNumber: formatPartNumber(item?.partNumber),
      alternativeType: ALTERNATIVE_TYPES.REMANUFACTURED,
      originalPartReferenceId
    }))
  ];
};

export const invalidErrorPartsHandler = data => {
  return data?.items?.reduce((acc, item) => {
    if (item?.sosList?.[0].errors) {
      return {
        ...acc,
        [item.partNumber]: {
          itemNumber: item?.sosList?.[0].errors.errorMessage || '',
          quantity: item?.requestedQuantity || ''
        }
      };
    } else {
      return acc;
    }
  }, {});
};

export const itemsAddedToCartHelpers = (currentItems = {}) => {
  const numberOfItemsAddedToCart = Object.keys(currentItems).reduce(
    (subTotal, key) =>
      subTotal + parseInt(currentItems[key]?.requestedQuantity) || 0,
    0
  );
  const itemsAndQuantityAddedIntoCartObject = Object.keys(currentItems).map(
    element => {
      let { partNumber, requestedQuantity, page } = currentItems[element];
      return {
        partNumber,
        quantity: requestedQuantity,
        page
      };
    }
  );
  return {
    itemsAndQuantityAddedIntoCartObject,
    numberOfItemsAddedToCart
  };
};

export const removeParentheses = input =>
  input.startsWith('(') && input.endsWith(')') ? input.slice(1, -1) : input;
export const normalizeEquipmentObject = equipmentInfo => {
  const { serialNumber, model, assetId } = equipmentInfo ?? {};
  return typeof equipmentInfo === 'object'
    ? {
        serialNumber,
        model,
        assetId
      }
    : {
        serialNumber: removeParentheses(equipmentInfo),
        model: '',
        assetId: ''
      };
};
export const getRBDDateUpdate = (
  estimatedDate,
  fieldValueObj,
  showToasterFulFillment,
  preAvailabilityMessage,
  dispatch,
  pageType
) => {
  const { availableByDate, availabilityMessage } = estimatedDate;
  if (
    format(fieldValueObj.fieldValue, DATE_FORMAT_YYYY_MM_DD) <
    format(new Date(availableByDate), DATE_FORMAT_YYYY_MM_DD)
  ) {
    if (!!availableByDate && pageType === SUMMARY_AND_PAYMENT) {
      dispatch(
        updateDateAndEquipment({
          equipment: {},
          requestByDate: new Date(availableByDate),
          updateRequestByDateOnly: true,
          calledFrom: DATE_UPDATED
        })
      );
    }
    return showToasterFulFillment(
      true,
      availabilityMessage,
      preAvailabilityMessage
    );
  }
  return showToasterFulFillment(
    false,
    availabilityMessage,
    preAvailabilityMessage
  );
};
