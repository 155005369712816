import LoginAndRegisterButtons from '@app/components/common/LoginAndRegisterButtons/LoginAndRegisterButtons';
import { PLACEMENT } from '@app/components/common/LoginAndRegisterButtons/constants';
import {
  Drawer,
  DrawerHeader,
  HeaderAccountButton,
  HeaderAccountFeatures,
  useModal
} from '@cat-ecom/pcc-components';
import { CatHeading } from 'blocks-react/bedrock/components/Heading';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import useHeaderAccountFeatures from '../../hooks/useHeaderAccountFeatures/useHeaderAccountFeatures';
import useLogout from '@app/components/login/hooks/useLogout/useLogout';
import PropTypes from 'prop-types';
import { DealerLocatorModalWrapper } from '@app/components/dealer-locator-react';
import { CreateAccountDescription } from '@app/components/dealer-locator-react/CreateAccountDescription/CreateAccountDescription';
import Conditional from '@app/components/common/Conditional';

const AccountDrawer = ({ closeDrawer, ...drawerProps }) => {
  const { t } = useTranslation();

  const modal = useModal({
    isRequired: true,
    heading: `${t('STEP_1')}: ${t('REGISTER_MODAL_SELECT_DEALER_STORE')}`,
    backButton: false,
    size: 'md'
  });

  const userType = useSelector(s => s.common?.userType);
  const isBuyOnBehalf = useSelector(s => s.common.isBuyOnBehalf);
  const isLoggedIn = userType && userType !== 'G';
  const features = useHeaderAccountFeatures(isLoggedIn, closeDrawer);
  const { logout } = useLogout();

  return (
    <>
      <Drawer {...drawerProps}>
        <DrawerHeader>
          <CatHeading variant="title" data-testid="cat-header-account-title">
            {t('FOOTER_ACCOUNT')}
          </CatHeading>
        </DrawerHeader>
        <Conditional test={!isLoggedIn}>
          <LoginAndRegisterButtons
            disclaimerPlacement={PLACEMENT.BOTTOM}
            closeDrawer={closeDrawer}
            openDealerLocator={modal.initiatorProps.onClick}
          />
        </Conditional>
        <HeaderAccountFeatures features={features} />
        <Conditional test={isLoggedIn && !isBuyOnBehalf}>
          <HeaderAccountButton label={t('HEADER_SIGN_OUT')} onClick={logout} />
        </Conditional>
      </Drawer>
      <DealerLocatorModalWrapper isRegistration modal={modal}>
        <CreateAccountDescription />
      </DealerLocatorModalWrapper>
    </>
  );
};

export default AccountDrawer;

AccountDrawer.propTypes = {
  closeDrawer: PropTypes.func
};
