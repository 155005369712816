import * as types from './constants';
import { storeInitialStore } from './initialStore';

const storeReducer = (state = {}, action) => {
  switch (action.type) {
    case types.STORE_GET_INFO_BEGIN: {
      return {
        ...state,
        getStoreLocationInfoLoading: true,
        getStoreLocationInfoSuccess: false
      };
    }
    case types.STORE_GET_INFO_SUCCESS: {
      return {
        ...state,
        getStoreLocationInfoLoading: false,
        getStoreLocationInfoSuccess: true,
        ...action.payload
      };
    }
    case types.STORE_GET_INFO_FAIL: {
      return {
        ...state,
        getStoreLocationInfoLoading: false,
        getStoreLocationInfoSuccess: false
      };
    }
    default: {
      return state;
    }
  }
};

export { storeInitialStore };
export default storeReducer;
