import { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import qs from 'query-string';
import { getHeaderInfo, getWaffleInfo } from '@app/store/header/actions';
import {
  isPickupAndDeliveryPage,
  isSummaryAndPaymentPage,
  isHomepage
} from '@app/utils';
import {
  STATUS,
  USER_TYPE_GUEST,
  AFFILIATION_DEALER
} from '@app/constants/commonConstants';
import { getNotificationHeaderList } from '@app/store/notificationHeader/action';
import {
  getServiceRecommendations,
  loadEquipmentDataDetails
} from '@app/store/myequipment/actions';
import HeaderManualApprovalMessage from './HeaderManualApprovalMessage';
import { SHOW_MANUAL_APPROVAL } from './constants';
import Conditional from '@app/components/common/Conditional';
import { HeaderContainer } from '@cat-ecom/pcc-components';
import SimplifiedHeader from './SimplifiedHeader';
import useCheckoutRoutes from './hooks/useCheckoutRoutes/useCheckoutRoutes';
import FullHeader from './FullHeader';
import {
  HEADER as headerTarget,
  HEADER_WRAPPER,
  WRAPPER,
  HEADER_WIDGET
} from '../../constants/targets';
import {
  getSessionStorageCartSharingData,
  updateSessionStorageCartSharingData
} from '../pages/checkout/shopping-cart/ShoppingCartPage/utils';
import styles from './header.module.scss';

const Header = () => {
  const dispatch = useDispatch();
  const storeId = useSelector(s => s.common?.storeId);
  const userType = useSelector(s => s.common?.userType);
  const getCommonVarsStatus = useSelector(s => s.common.getCommonVarsStatus);

  const isCSPCustomer = useSelector(s => s.common?.isCSPCustomer);
  const isServiceRecommendationFeatureEnable = useSelector(
    s => s.featureFlag?.PCC_ServiceRecommandationFlag
  );

  const isServiceRecommendationEnable =
    isCSPCustomer && isServiceRecommendationFeatureEnable;

  const isLoggedIn = !!userType && userType !== USER_TYPE_GUEST;
  const isCommonApiLoaded =
    getCommonVarsStatus === STATUS.RESOLVED ||
    getCommonVarsStatus === STATUS.REJECTED;
  const { feedback } = qs.parse(window.location.search);
  const isFeedbackShowManualApproval = feedback === SHOW_MANUAL_APPROVAL;
  const pendingManualApprovalEnabled = useSelector(
    state => state.common?.registrationPendingApproval
  );
  const showBlocksManualApproval =
    isFeedbackShowManualApproval ||
    (!!pendingManualApprovalEnabled && isHomepage());

  const userAffiliation = useSelector(s => s.common?.userAffiliation);

  const isPCCStickyNavMenuIntegrationFlag = useSelector(
    s => s.featureFlag?.PCCStickyNavMenuIntegrationFlag
  );
  const isDealerUser = userAffiliation === AFFILIATION_DEALER;

  const registerPreviousStoreId = useCallback(() => {
    const { previousStoreId = null } = getSessionStorageCartSharingData() || {};

    if (isLoggedIn && storeId && !previousStoreId) {
      updateSessionStorageCartSharingData({ previousStoreId: storeId });
    }
  }, [isLoggedIn, storeId]);

  const headerActionsHasBeenDispatched = useRef(false);
  const disableStickyHeader =
    isPickupAndDeliveryPage() || isSummaryAndPaymentPage();

  if (!headerActionsHasBeenDispatched.current) {
    headerActionsHasBeenDispatched.current = true;
    dispatch(getHeaderInfo());
    dispatch(getWaffleInfo());
  }

  // Add sticky behavior header root
  useEffect(() => {
    if (isPCCStickyNavMenuIntegrationFlag && !disableStickyHeader) {
      const wrapper = document.getElementById(WRAPPER);
      wrapper?.classList.add(styles['header--sticky']);

      const headerWrapper = document.getElementById(HEADER_WRAPPER);
      headerWrapper?.classList.add(styles['header--sticky']);

      const headerWidget = document.getElementById(HEADER_WIDGET);
      headerWidget?.classList.add(styles['header--sticky']);

      const headerRoot = document.getElementById(headerTarget);
      headerRoot?.classList.add(styles['header--sticky']);

      return () => {
        headerWrapper?.classList.remove(styles['header--sticky']);
        headerRoot?.classList.remove(styles['header--sticky']);
        wrapper?.classList.remove(styles['header--sticky']);
        headerWidget?.classList.remove(styles['header--sticky']);
      };
    }
  }, [isPCCStickyNavMenuIntegrationFlag, disableStickyHeader]);

  useEffect(() => {
    registerPreviousStoreId();
  }, [registerPreviousStoreId]);

  useEffect(() => {
    if (isLoggedIn && !isDealerUser) {
      dispatch(getNotificationHeaderList());
    }
  }, [isLoggedIn, isDealerUser, dispatch]);

  useEffect(() => {
    if (isCommonApiLoaded) {
      dispatch(loadEquipmentDataDetails());
    }
  }, [dispatch, isCommonApiLoaded]);

  useEffect(() => {
    if (isServiceRecommendationEnable) {
      dispatch(getServiceRecommendations());
    }
  }, [dispatch, isServiceRecommendationEnable]);

  const showSimplifiedHeader = useCheckoutRoutes();
  return (
    <>
      {showBlocksManualApproval && <HeaderManualApprovalMessage />}
      <HeaderContainer>
        <Conditional test={showSimplifiedHeader} fallback={<FullHeader />}>
          <SimplifiedHeader />
        </Conditional>
      </HeaderContainer>
    </>
  );
};

export default Header;
