import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import Conditional from '@app/components/common/Conditional';
import HeaderLogo from '../HeaderLogoWrapper';
import { CatTooltip } from 'blocks-react/bedrock/components/Tooltip/Tooltip.js';
import { CatTextPassage } from 'blocks-react/bedrock/components/TextPassage/TextPassage.js';
import { CatHeading } from 'blocks-react/bedrock/components/Heading/Heading.js';
import { CatIconShieldCheck } from 'blocks-react/bedrock/components/Icons/ShieldCheck/ShieldCheck.js';
import { useBreakpoint } from '@app/hooks';
import { PAGE_BREAKPOINTS } from '@app/constants/commonConstants';
import styles from './SimplifiedHeader.module.scss';
import { HeaderRow } from '@cat-ecom/pcc-components';

const SimplifiedHeader = () => {
  const { t } = useTranslation();
  const isMobile = !useBreakpoint(PAGE_BREAKPOINTS.BLOCKS_LG);

  return (
    <HeaderRow>
      <HeaderLogo isMobile={isMobile} />
      <CatHeading color="knockout" variant="title-sm">
        {t('CHECKOUT_LOGON_TITLE')}
      </CatHeading>
      <div className="d-flex flex-row align-items-center text-white">
        <Conditional
          test={!isMobile}
          fallback={
            <CatIconShieldCheck
              color="knockout"
              data-testid="cat-icon-shield-check"
              size="xl"
              slot="trigger"
            />
          }
        >
          <CatTooltip
            align="bottom-center"
            className="d-flex align-items-center"
          >
            <CatIconShieldCheck
              color="knockout"
              data-testid="cat-icon-shield-check"
              size="xl"
              slot="trigger"
            />
            <CatTextPassage className="text-center" size="xs">
              {t('ENCLOSED_CHECKOUT')}
            </CatTextPassage>
          </CatTooltip>
          <div
            className={cx(
              'cat-u-theme-typography-body-sm',
              styles['secure-checkout-text']
            )}
          >
            {t('SECURE_CHECKOUT')}
          </div>
        </Conditional>
      </div>
    </HeaderRow>
  );
};

export default SimplifiedHeader;
