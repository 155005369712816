import {
  getDefaultDisplayName,
  getDefaultValue,
  valueFromName,
  nameFromValue,
  defaultFromName,
  getValueByName,
  getNameByValue,
  getOnOffByBool,
  getBoolByBool,
  getValueByValue
} from './util';
import { isEmpty } from '../../utils';
import {
  IMAGE_PREFIX,
  FULFILLMENT_TYPE_PICKUP,
  FULFILLMENT_TYPE_SHIPTO,
  FULFILLMENT_TYPE_DROPBOX
} from '../../components/pages/account/shopping-preferences/constants';
import { UPDATE_BASIC, UPDATE_ADVANCED } from './constants';

export const getInitialStoreName = shoppingPreferencesData =>
  getDefaultDisplayName(shoppingPreferencesData.dealerStore);

export const getInitialAccountName = shoppingPreferencesData =>
  getDefaultDisplayName(shoppingPreferencesData.customerNumber);

export const getHasAccountChanged = prefs =>
  getInitialAccountName(prefs.shoppingPreferencesData) !==
  prefs.selectedAccount;

export const getHasDealerStoreChanged = prefs => {
  const prevStore = getInitialStoreName(prefs.shoppingPreferencesData);
  return isEmpty(prevStore) ? false : prevStore !== prefs.selectedDealerStore;
};

export const getInitialAccountValue = prefs =>
  getDefaultValue(prefs.shoppingPreferencesData.customerNumber) ||
  ((prefs.shoppingPreferencesData.customerNumber || []).length &&
    prefs.shoppingPreferencesData.customerNumber[0].value);

export const getSelectedAccountValue = prefs =>
  valueFromName(
    prefs.selectedAccount,
    prefs.shoppingPreferencesData.customerNumber
  );

export const getPoNumberUpdate = (form, spd) => {
  if (
    form.paymentPoNumber === spd.poNumber ||
    (isEmpty(form.paymentPoNumber) && isEmpty(spd.poNumber)) ||
    form.paymentPoNumber === undefined
  ) {
    return undefined;
  }

  return isEmpty(form.paymentPoNumber) ? '' : form.paymentPoNumber;
};

export const mapImageSetting = imageSettings => {
  let diplaySettings = {};

  imageSettings.forEach(setting => {
    diplaySettings[setting.value] = setting.default ? 'on' : 'off';
  });

  return diplaySettings;
};

//  request made when user changes the dealer store or account
export const buildStoreChangeRequest = prefs => {
  const spd = prefs.shoppingPreferencesData;
  const customerNumber =
    valueFromName(prefs.selectedAccount, spd.customerNumber) ||
    getDefaultValue(spd.customerNumber) ||
    ((spd.customerNumber || []).length && spd.customerNumber[0].value);

  return {
    preferenceType: UPDATE_BASIC,
    storeId: prefs.storeId,
    langId: prefs.langId,
    customerNumber,
    selectedDealerStore: valueFromName(
      prefs.selectedDealerStore,
      spd.dealerStore
    ),
    customerNumberName: nameFromValue(customerNumber, spd.customerNumber)
  };
};

export const buildSimpleUpdateRequest = (updateObj, prefs) => {
  const spd = prefs.shoppingPreferencesData;
  return {
    preferenceType: UPDATE_ADVANCED,
    storeId: prefs.storeId,
    langId: prefs.langId,
    selectedDealerStore: valueFromName(
      prefs.selectedDealerStore,
      spd.dealerStore
    ),
    customerNumber: getInitialAccountValue(prefs),
    customerNumberName: getInitialAccountName(spd),
    ...updateObj
  };
};

// build PUT request for form changes
export const buildUpdateRequest = (form, prefs, languageOptions) => {
  const cartData = {
    partOrItem: {},
    showFulfillment: 'P'
  };
  const spd = prefs.shoppingPreferencesData;
  const customerNumberName = getInitialAccountName(spd);
  const selectedDealerStore = valueFromName(
    prefs.selectedDealerStore,
    spd.dealerStore
  );
  return {
    preferenceType: UPDATE_ADVANCED,
    storeId: prefs.storeId,
    langId: prefs.langId,
    customerNumber: getInitialAccountValue(prefs),
    customerNumberName: customerNumberName
      ? customerNumberName
      : prefs.selectedAccount,
    selectedDealerStore: selectedDealerStore
      ? selectedDealerStore
      : prefs.selectedStore,
    uom: form.uom,
    // login section, 2 items
    startPage: getValueByName(form.loginStartPageSettings, spd.startPage),
    defaultLanguage:
      getValueByName(
        form.loginDefaultLangSettings ??
          getNameByValue(
            spd.defaultLanguage,
            languageOptions ?? spd.languageOptions
          ),
        languageOptions ?? spd.languageOptions
      ) || '',
    homePageSettings: getValueByValue(form.homePageSettings, spd.adpSettings),
    // order details section, 4 items
    orderType: getValueByValue(form.orderDetailsDealerSettings, spd.orderTypes),
    // note: must set orderType and orderTypeLabel to set the orderType
    orderTypeLabel: getNameByValue(
      form.orderDetailsDealerSettings,
      spd.orderTypes
    ),
    enduseCode: getValueByName(form.orderDetailsEndUseCode, spd.endUseCodes),
    quoteMailPreference: getOnOffByBool(
      form.orderDetailsAttachQuote,
      spd.attachQuoteToPDF
    ),
    editOrderedByFlag: getBoolByBool(
      form.editOrderByInformation,
      spd.editOrderedByFlag
    ),
    // shopping cart
    displayOrderInfo: form.hideOrderInformationToggle
      ? undefined
      : getBoolByBool(
          form.shoppingCartDisplayOrderInfo,
          spd.displayOrderInformation
        ),
    addLineItemNotes: getBoolByBool(
      form.shoppingCartAddNotes,
      spd.displayLineItemNotes
    ),
    displayQuickOrder: getBoolByBool(
      form.shoppingCartQuickOrder,
      spd.displayQuickOrder
    ),
    showSourcingDetailToggle: getBoolByBool(
      form.shoppingSourcingDetail && JSON.parse(form.shoppingSourcingDetail),
      spd.showSourcingDetailToggle
    ),
    // pickup and delivery, 2 items
    fullfillment: setFulfillment(form, spd),
    skipPickUpAndDelivery: getOnOffByBool(
      form.fulfillmentSkipPickupDeliveryPage,
      spd.skipSB
    ),
    // payment  section
    paymentMethod: getValueByName(form.paymentMethod, spd.paymentMethods),
    displayPoNumber: getOnOffByBool(
      form.paymentPoNumberToggle,
      spd.displayPoNumber
    ),
    purchaseOrderNumber: getPoNumberUpdate(form, spd),
    poSuffix: getOnOffByBool(form.paymentRequirePoSuffix, spd.requirePOSuffix),
    // image
    imageSetting: form.imageSettings
      ? mapImageSetting(form.imageSettings)
      : getImageSetting(form, spd.imageSettings),
    // TODO: We modified the params because the service is throwing an error. Please refer to this bug: #bug1064898
    shoppingCartPrefs: cartData
  };
};

// locals --------------------------------------------------

const createInitialFulfillment = spd => {
  const { pickup, dropBox, shipTo } = spd;
  const {
    pickupMethods,
    pickupInst,
    specialInst: pickupSpecialInstructions
  } = pickup || {};

  const {
    dropBoxMethods,
    dropBoxInst,
    specialInst: dropBoxSpecialInstructions
  } = dropBox || {};

  const {
    shippingMethods,
    shipInst,
    specialInst: shipSpecialInstructions
  } = shipTo || {};

  const fulfillment = getDefaultValue(spd.deliveryPreference);
  switch (fulfillment) {
    case FULFILLMENT_TYPE_PICKUP:
      return {
        type: fulfillment,
        method: getDefaultValue(pickupMethods),
        instructions: getDefaultValue(pickupInst),
        specialInst: pickupSpecialInstructions
      };
    case FULFILLMENT_TYPE_DROPBOX:
      return {
        type: fulfillment,
        method: getDefaultValue(dropBoxMethods),
        instructions: getDefaultValue(dropBoxInst),
        specialInst: dropBoxSpecialInstructions,
        dropboxAddress: getInitialAddress(fulfillment, spd)
      };
    case FULFILLMENT_TYPE_SHIPTO:
      return {
        type: fulfillment,
        method: getDefaultValue(shippingMethods),
        instructions: getDefaultValue(shipInst),
        specialInst: shipSpecialInstructions,
        shippingAddressId: getInitialAddress(fulfillment, spd)
      };
    default:
      return undefined;
  }
};

const createFormFulfillment = (form, spd) => {
  switch (form.fulfillment) {
    case FULFILLMENT_TYPE_PICKUP:
      return {
        type: form.fulfillment,
        method: valueFromName(
          form.fulfillmentPickupMethod,
          spd.pickup.pickupMethods
        ),
        instructions: valueFromName(
          form.fulfillmentPickupInstructions,
          spd.pickup.pickupInst
        ),
        specialInst: form.fulfillmentSpecialPickupInstructions
      };
    case FULFILLMENT_TYPE_DROPBOX:
      return {
        type: form.fulfillment,
        method: valueFromName(
          form.fulfillmentdropBoxMethod,
          spd.dropBox.dropBoxMethods
        ),
        instructions: valueFromName(
          form.fulfillmentDropBoxInstructions,
          spd.dropBox.dropBoxInst
        ),
        dropboxAddress: buildDropBoxAddress(
          form.fulfillmentDropBoxAddress,
          spd.dropBox.Address
        ),
        specialInst: form.fulfillmentSpecialDropBoxInstructions
      };
    case FULFILLMENT_TYPE_SHIPTO:
      return {
        type: form.fulfillment,
        method: valueFromName(
          form.fulfillmentShippingMethod,
          spd.shipTo.shippingMethods
        ),
        instructions: valueFromName(
          form.fulfillmentShippingInstructions,
          spd.shipTo.shipInst
        ),
        specialInst: form.fulfillmentSpecialShippingInstructions,
        shippingAddressId: valueFromName(
          form.fulfillmentShippingAddress,
          (spd.shipTo || {}).Address
        )
      };
    default:
      return undefined;
  }
};

const setFulfillment = (form, spd) => {
  const f = createFormFulfillment(form, spd);
  const i = createInitialFulfillment(spd);

  if (!i) {
    return f;
  }

  if (!f) {
    return undefined;
  }

  return i.type === f.type &&
    i.method === f.method &&
    i.instructions === f.instructions &&
    (i.specialInst === f.specialInst ||
      (isEmpty(i.specialInst) && isEmpty(f.specialInst))) &&
    hasSameAddress(i, f)
    ? undefined
    : f;
};

const buildDropBoxAddress = (selectedDisplayName, arr) => {
  const i = arr.find(a => a.displayName === selectedDisplayName);
  return (
    i && {
      dropBoxAddressLine1: i.dropBoxAddressLine1,
      dropBoxAddressLine2: i.dropBoxAddressLine2,
      dropBoxAddressLine3: i.dropBoxAddressLine3,
      dropBoxAddressLine4: i.dropBoxAddressLine4,
      dropBoxAddressLine5: i.dropBoxAddressLine5
    }
  );
};

const getInitialAddress = (fulfillment, spd) => {
  switch (fulfillment) {
    case FULFILLMENT_TYPE_DROPBOX: {
      const { Address: address } = spd.dropBox || {};
      return buildDropBoxAddress(getDefaultDisplayName(address), address);
    }
    case FULFILLMENT_TYPE_SHIPTO: {
      const { Address: address } = spd.shipTo || {};
      return getDefaultValue(address);
    }
    default:
      return undefined;
  }
};

const hasSameAddress = (initial, form, spd) => {
  if (initial.type !== form.type) {
    return false;
  }

  switch (form.type) {
    case FULFILLMENT_TYPE_DROPBOX: {
      const fa = form.dropboxAddress || {};
      const ia = initial.dropboxAddress || {};
      for (let [key, value] of Object.entries(fa)) {
        if (value !== ia[key]) {
          return false;
        }
      }
      return true;
    }
    case FULFILLMENT_TYPE_SHIPTO:
      return initial.shippingAddressId === form.shippingAddressId;
    default:
      return true;
  }
};

const getImageSetting = (form, imageSettings) => {
  let imagesChanged = false;
  const update = (imageSettings || []).reduce((acc, p, i) => {
    const v = form[`${IMAGE_PREFIX}${i}`];
    if (
      v !== defaultFromName(p.displayName, imageSettings) &&
      v !== undefined
    ) {
      imagesChanged = true;
    }
    acc[p.value] = v ? 'on' : 'off';
    return acc;
  }, {});

  return imagesChanged ? update : undefined;
};
