import { STATUS } from '../constants/commonConstants';
import { accountInitialStore } from './account';
import { headerInitialStore } from './header';
import { shoppingPreferencesInitialStore } from './shoppingPreferences';
import { ordersToApproveInitialStore } from './ordersToApprove';
import { organizationsAndUsersInitialStore } from './organizationsAndUsers';
import { mlpInitialStore } from './mlp';
import { checkoutInitialStore } from './checkout';
import { myequipmentInitialStore } from './myequipment';
import { ordersInitialStore } from './orders';
import { partsFilterInitialStore } from './partsFilter';
import { productsInitialStore } from './products';
import { orderHistoryInitialStore } from './orderHistory';
import { repairOptionsInitialStore } from './repairOptions';
import { registrationInitialStore } from './registration';
import { schematicsInitialStore } from './schematics';
import { commonInitialStore } from './common';
import { categoriesInitialStore } from './categories';
import { catVantageInitialStore } from './catvantage';
import { dealerInitialStore } from './dealer';
import { dealerAssocoationInitialStore } from './dealer-association';
import { dealercustomerInitialStore } from './dealer-customer';
import { storeInitialStore } from './store';
import { pageLoadInitialStore } from './pageLoad';
import { footerInitialStore } from './footer';
import { plpInitialStore } from './plp';

import { featureFlagInitialStore } from './featureFlag';
import { marketingInitialStore } from './marketing';
import { personalInformationInitialStore } from './personalInformation';
import { searchUIInitialStore } from './searchUI';
import { notificationInitialStore } from './notifications';
import { invoicesInitialStore } from './invoices';
import { notificationHeaderInitialStore } from './notificationHeader';
import { storeConfigurationInitialStore } from './storeConfiguration';
import { ectInitialStore } from './ect';
import { visionLinkInitialStore } from './visionLink';

const initialStore = {
  account: accountInitialStore,
  common: commonInitialStore,
  shoppingPreferences: shoppingPreferencesInitialStore,
  ordersToApprove: ordersToApproveInitialStore,
  organizationsAndUsers: organizationsAndUsersInitialStore,
  mlp: mlpInitialStore,
  checkout: checkoutInitialStore,
  categories: categoriesInitialStore,
  catVantage: catVantageInitialStore,
  customers: {
    byId: {},
    customersDataStatus: STATUS.IDLE,
    redirectionPoint: null
  },
  csrOrders: {
    byId: {},
    ordersDataStatus: STATUS.IDLE,
    onBehalfUserInfo: {},
    onBehalfUserInfoStatus: STATUS.IDLE
  },
  dealer: dealerInitialStore,
  dealerAssociation: dealerAssocoationInitialStore,
  dealerCustomer: dealercustomerInitialStore,
  myEquipment: myequipmentInitialStore,
  notifications: notificationInitialStore,
  header: headerInitialStore,
  registrationUI: registrationInitialStore,
  orders: ordersInitialStore,
  partsFilter: partsFilterInitialStore,
  plpUI: plpInitialStore,
  searchUI: searchUIInitialStore,
  products: productsInitialStore,
  errors: {},
  returnRequest: {},
  requestService: {},
  personalInfoUI: personalInformationInitialStore,
  savedListDetailUI: {
    currentId: '',
    status: ''
  },
  orderHistory: orderHistoryInitialStore,
  repairOptions: repairOptionsInitialStore,
  schematics: schematicsInitialStore,
  addresses: {},
  user: {},
  pmKit: {
    serviceOptions: {
      data: {},
      isServiceOptionsLoading: false
    },
    serviceOptionsDetails: {}
  },
  store: storeInitialStore,
  pageLoad: pageLoadInitialStore,
  marketingLandingPageUI: marketingInitialStore,
  footer: footerInitialStore,
  featureFlag: featureFlagInitialStore,
  homepageUI: {
    recentlyViewed: {
      products: []
    },
    bestSellers: {
      products: []
    },
    featured: {
      products: []
    }
  },
  invoices: invoicesInitialStore,
  notificationHeader: notificationHeaderInitialStore,
  storeConfiguration: storeConfigurationInitialStore,
  ect: ectInitialStore,
  visionLink: visionLinkInitialStore
};

export default initialStore;
