import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Carousel } from 'cat-ecommerce-alloy';
import EspotSkeletonLoaderBlocks from './EspotSkeletonLoaderBlocks';
import EspotContent from './EspotContent';
import PropTypes from 'prop-types';
import { ESPOT_VARIANTS } from '@app/constants/commonConstants';
import { ESPOT_TYPES } from '@app/components/common/Espot/constants';
import { isEmpty } from '@app/utils';

const Espot = ({
  isLoading = false,
  html,
  transitionTime,
  variant,
  className = '',
  scrollId
}) => {
  const [carouselHeight, setCarouselHeight] = useState('auto');
  const { contents, eSpotFlags } = !isEmpty(html) && html;
  const isCarousel = eSpotFlags?.IsCarousel;
  const content =
    !isCarousel && Array.isArray(contents) && contents?.length > 0
      ? contents[0]
      : contents;
  const { hash } = useLocation();

  //eg: legalNotices#privacyPolicy
  useEffect(() => {
    if ((hash || scrollId) && html && !isLoading) {
      const specialChars = /[`!@$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
      if (!specialChars.test(hash || scrollId)) {
        if (scrollId) {
          const contentId = document?.getElementById(scrollId);
          if (contentId) {
            // Give some time to Blocks components to load before scrolling
            setTimeout(() => {
              contentId.scrollIntoView({ behavior: 'smooth' });
            }, 300);
          }
        } else if (hash) {
          const contentHash = document?.querySelector(hash);
          if (contentHash) {
            contentHash.scrollIntoView({ behavior: 'smooth' });
          }
        }
      }
    }
  }, [hash, scrollId, html, isLoading]);

  const getEspotPaddingClass = () => {
    const { CAROUSEL, PINSTRIPE, LARGE } = ESPOT_VARIANTS;
    let paddingClasses = '';
    if (variant === PINSTRIPE || variant === CAROUSEL) {
      paddingClasses = 'pb-5 pt-4 espot__mobile-padding-bottom';
    } else if (variant === LARGE) {
      paddingClasses =
        'py-5 espot__mobile-padding-bottom espot__mobile-padding-top';
    }
    return paddingClasses;
  };

  if (isLoading) {
    return (
      <div className={getEspotPaddingClass()}>
        <EspotSkeletonLoaderBlocks variant={variant} />
      </div>
    );
  }

  if (isEmpty(content)) {
    return <div data-testid="espotDataFail" />;
  }

  return isCarousel ? (
    <Carousel
      isAutoPlay={true}
      height={carouselHeight}
      minHeight="60px"
      transitionTime={transitionTime}
      className={getEspotPaddingClass()}
    >
      {content?.length > 0 &&
        content?.map((htmlContent, index) => (
          <EspotContent
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            htmlContent={htmlContent}
            setCarouselHeight={setCarouselHeight}
            espotType={ESPOT_TYPES.CAROUSEL}
            position={index + 1}
            className={`${className} pb-2`}
          />
        ))}
    </Carousel>
  ) : (
    <EspotContent
      htmlContent={content}
      espotType={ESPOT_TYPES.STATIC}
      position={1}
      className={`${className} ${getEspotPaddingClass()}`}
    />
  );
};
export default Espot;

Espot.propTypes = {
  html: PropTypes.shape({
    contents: PropTypes.shape({
      html: PropTypes.string,
      creative: PropTypes.string,
      range: PropTypes.string,
      source: PropTypes.string
    }),
    eSpotFlags: PropTypes.shape({
      IsCarousel: PropTypes.bool
    })
  }),
  isLoading: PropTypes.bool,
  variant: PropTypes.string,
  transitionTime: PropTypes.number,
  className: PropTypes.string,
  scrollId: PropTypes.string
};
