import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { CatButton } from 'blocks-react/bedrock/components/Button';
import { NotificationChips } from '@cat-ecom/pcc-components';
import { notificationFilterChanged } from '@app/store/notificationHeader/action';
import styles from '../NotificationItem.module.scss';

const NotificationStatusChips = ({
  notificationFilter,
  setListOfOrderStatus
}) => {
  const { t } = useTranslation();
  const [isShowMore, setIsShowMore] = useState(false);
  const [isDisplayShowMore, setDisplayShowMore] = useState(false);
  const containerRef = useRef(null);
  const dispatch = useDispatch();
  const { orderStatus = [], account = {} } = notificationFilter;

  const handleRemovingChip = value => {
    const orderStatusArray = orderStatus?.filter(
      notificationChip => notificationChip.label !== value
    );
    setListOfOrderStatus(orderStatusArray);
    dispatch(
      notificationFilterChanged({
        ...notificationFilter,
        orderStatus: orderStatusArray
      })
    );
  };

  const handleRemoveAccountChip = value => {
    const { account, ...other } = notificationFilter;
    dispatch(notificationFilterChanged({ ...other }));
  };

  const handleShowMore = () => {
    setIsShowMore(true);
  };

  const maxHeight = 70;

  useEffect(() => {
    if (containerRef?.current?.scrollHeight > maxHeight) {
      setDisplayShowMore(true);
    } else if (containerRef?.current?.scrollHeight <= maxHeight) {
      setDisplayShowMore(false);
    }
  }, [account, orderStatus]);

  return (
    <div className={`${styles['notification_chip_container']}`}>
      <div>
        <div
          className={
            !isShowMore
              ? styles['notification_chip_status']
              : styles['notification_chip_expand']
          }
          ref={containerRef}
        >
          {Object.keys(account)?.length > 0 && (
            <NotificationChips
              handleRemovingChip={handleRemoveAccountChip}
              removable
              notificationChip={account.label}
            />
          )}
          {orderStatus?.map(notificationChip => {
            return (
              <NotificationChips
                key={notificationChip.label}
                handleRemovingChip={handleRemovingChip}
                removable
                notificationChip={notificationChip.label}
              />
            );
          })}
        </div>
        <div className="d-flex justify-content-end">
          {!isShowMore && isDisplayShowMore && (
            <CatButton
              className={`${styles['show_more_btn']}`}
              variant="inline"
              onClick={handleShowMore}
              data-testid="SHOW_MORE"
              ariaLable={t('SHOW_MORE')}
            >
              {t('SHOW_MORE')}
            </CatButton>
          )}
        </div>
      </div>
    </div>
  );
};

NotificationStatusChips.propTypes = {
  notificationFilter: PropTypes.object,
  setListOfOrderStatus: PropTypes.func
};
export default NotificationStatusChips;
