import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useMyAccountLinks } from '@app/components/pages/account/hooks';
import { NOTIFICATION_LABEL_MAP } from '@app/constants/commonConstants';
import { getRedirectionLink } from './utils';
import Orderstatus from './NotificationStatus/Orderstatus';
import Expiredstatus from './NotificationStatus/Expiredstatus';
import { view_order_cta } from './utils';

const CardFooter = ({
  orderDisplayLinkText,
  orderStatus,
  statusKey,
  link,
  quoteExpireDate,
  orderId,
  orderShipmentDetailsLink,
  reviewQuoteLink,
  orderNumber,
  type
}) => {
  const { t } = useTranslation();
  const expiredQuote = t(NOTIFICATION_LABEL_MAP.NEW);

  const { dynamicLinks } = useMyAccountLinks();

  const redirectLink = getRedirectionLink(
    dynamicLinks,
    orderId,
    orderNumber,
    link
  );
  const orderShipmentLink = orderShipmentDetailsLink
    ? dynamicLinks.ORDER_STATUS.getUrl({
        orderId,
        dbsOrderId: orderNumber
          ? orderNumber
          : orderShipmentDetailsLink
              ?.split('dbsOrderId=')
              ?.pop()
              ?.split('&')?.[0]
      })
    : null;

  const reviewQuoteDetailLink =
    ['EXP'].includes(statusKey) &&
    !!orderId &&
    `${dynamicLinks.PENDING_QUOTE_DETAIL.getUrl({
      orderId: orderId
    })}&&orderStatus=Expired`;

  const reviewLink =
    reviewQuoteLink && !!orderId
      ? dynamicLinks.PENDING_QUOTE_DETAIL.getUrl({ orderId: orderId })
      : reviewQuoteDetailLink;

  return (
    <>
      {!view_order_cta.includes(statusKey) &&
        ((quoteExpireDate === null || statusKey === 'EXPS') &&
        orderStatus !== expiredQuote ? (
          <Orderstatus
            link={orderShipmentLink ? orderShipmentLink : redirectLink}
            orderDisplayLinkText={orderDisplayLinkText}
            reviewLink={reviewLink}
            orderStatus={orderStatus}
            type={type}
            statusKey={statusKey}
            orderId={orderId}
          />
        ) : (
          <Expiredstatus
            orderStatus={orderStatus}
            link={reviewLink}
            quoteExpireDate={quoteExpireDate}
          />
        ))}
    </>
  );
};

export default CardFooter;

CardFooter.propTypes = {
  type: PropTypes.string,
  orderStatus: PropTypes.string,
  statusKey: PropTypes.string,
  orderDisplayLinkText: PropTypes.string,
  link: PropTypes.string,
  quoteExpireDate: PropTypes.string,
  orderId: PropTypes.number,
  orderShipmentDetailsLink: PropTypes.string,
  reviewQuoteLink: PropTypes.string,
  orderNumber: PropTypes.number
};
