import { useEffect, useMemo, lazy } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Conditional from '@app/components/common/Conditional';
import {
  OUTAGE_MESSAGE_WARNING_IMAGE_PROPS,
  IS_MANUAL_APPROVAL_MESSAGE_VIEWED
} from '../constants';
import {
  getCookie,
  replaceTokensInString,
  isEmpty,
  isHomepage
} from '@app/utils';
import { getSessionStorage, setSessionStorage } from '@app/utils/sessionUtils';

const CommonBannerNotification = lazy(
  () =>
    import(
      /* webpackChunkName: 'commonBannerNotification' */ '../CommonBannerNotification'
    )
);

const ManualApprovalMessage = () => {
  const { t } = useTranslation();
  const pendingManualApprovalEnabled = useSelector(
    state => state.common?.registrationPendingApproval
  );

  const dealerName = useSelector(s => s.dealer.dealerName);
  const isNoucidCookieExist = !!isEmpty(getCookie('noucid'));

  const isManualApprovalMessageViewed = useMemo(
    () => getSessionStorage(IS_MANUAL_APPROVAL_MESSAGE_VIEWED),
    []
  );

  useEffect(() => {
    if (!!pendingManualApprovalEnabled && !isManualApprovalMessageViewed) {
      setSessionStorage(IS_MANUAL_APPROVAL_MESSAGE_VIEWED, true);
    }
  }, [pendingManualApprovalEnabled, isManualApprovalMessageViewed]);

  const approvalMsg = t('2175_MANUAL_APPROVAL_REG_ESPOT');
  const welcomeMsg11 = replaceTokensInString(
    t('WELCOME_MESSAGE11'),
    dealerName
  );
  const welcomeMsg10 = replaceTokensInString(
    t('WELCOME_MESSAGE10'),
    dealerName
  );

  const showManualApprovalMessage =
    !!pendingManualApprovalEnabled &&
    !isManualApprovalMessageViewed &&
    isNoucidCookieExist &&
    isHomepage();

  return (
    <Conditional test={showManualApprovalMessage}>
      <CommonBannerNotification
        message={`${approvalMsg} ${welcomeMsg11} ${welcomeMsg10}`}
        imageProps={OUTAGE_MESSAGE_WARNING_IMAGE_PROPS}
        isTextMessage
      />
    </Conditional>
  );
};

export default ManualApprovalMessage;
