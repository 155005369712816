import { useEspot } from '@app/hooks';
import { createPortal } from 'react-dom';
import {
  APP_BANNERS as bannersTarget,
  NOTIFICATION_ROOT
} from '../../constants/targets';
import BrowserWarningMessage from './BrowserWarningMessage';
import { GLOBAL_BANNER_ESPOTS } from './constants';
import CSROrderByGuestWarning from './CSROrderByGuestWarning';
import EmergencyMessage from './EmergencyMessage';
import ManualApprovalMessage from './ManualApprovalMessage';
import ErpOutageNotification from './ErpOutageNotification';
import CSRCheckoutFlow from './CSRCheckoutFlow';
import { CatToastGroup } from '@blocks-react/components/ToastGroup';
import { TOAST_POSITION } from '@app/constants/commonConstants';
import CSRVerifiedToast from './CSRVerifiedToast';
import CSRInstantAccessToast from './CSRInstantAccessToast';
import CSRInactiveLogOut from './CSRInactiveLogOut';

const Container = () => {
  const { getEspotContent } = useEspot(GLOBAL_BANNER_ESPOTS);
  const bannersRootElement = document.getElementById(bannersTarget);
  const notificationsRootElement = document.getElementById(NOTIFICATION_ROOT);

  return (
    <>
      {notificationsRootElement &&
        createPortal(
          <>
            <CatToastGroup
              verticalPosition={TOAST_POSITION.TOP}
              horizontalPosition={TOAST_POSITION.RIGHT}
              style={{
                '--cat-group-toast--inset-block-start': '1',
                '--cat-group-toast--inset-inline-start': '1'
              }}
            >
              <>
                {/* This empty div is neccessary because of gap in CatToastGroup: User Story 847955: PCC Customer Support */}
                <div></div>
                <CSRInstantAccessToast />
                <CSRVerifiedToast />
                <CSRCheckoutFlow />
                <CSROrderByGuestWarning />
                <CSRInactiveLogOut />
              </>
            </CatToastGroup>
          </>,
          notificationsRootElement
        )}
      {bannersRootElement &&
        createPortal(
          <>
            <ErpOutageNotification />
            <EmergencyMessage />
            <ManualApprovalMessage />
            <BrowserWarningMessage getEspotContent={getEspotContent} />
          </>,
          bannersRootElement
        )}
    </>
  );
};

export default Container;
