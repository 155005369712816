import * as types from './constants';
import endpoints from '../../constants/endpoints';
import { getHttpInstance } from '../../services/defaultHttpService';

import { replaceTokensInString } from '../../utils';
import { normalizeError } from '../exception/utils';
import { clearError, setError } from '../exception/actions';
import { ERROR_DOMAIN, ERROR_PATH } from '../../constants/errorConstants';

export const getStoreLocationInfo =
  (errorInfo = {}) =>
  (dispatch, getState) => {
    dispatch({ type: types.STORE_GET_INFO_BEGIN });
    const { langId, selectedStore, storeId } = getState().common;
    const http = getHttpInstance();
    const url = replaceTokensInString(
      endpoints.STORE_LOCATION_INFO,
      storeId,
      selectedStore,
      langId
    );
    return http
      .get(url)
      .then(({ data: payload } = {}) => {
        dispatch({
          type: types.STORE_GET_INFO_SUCCESS,
          payload
        });
        dispatch(
          clearError(ERROR_DOMAIN.HEADER, ERROR_PATH.CURRENT_LOCATION_POPUP)
        );
      })
      .catch(error => {
        dispatch({
          type: types.STORE_GET_INFO_FAIL
        });
        const { domain, path } = errorInfo;
        if (domain && path) {
          dispatch(setError(domain, path, normalizeError(error)));
        }
        dispatch(
          setError(ERROR_DOMAIN.HEADER, ERROR_PATH.CURRENT_LOCATION_POPUP, {
            ...normalizeError(
              error,
              {
                severity: 'warning',
                message: 'UNKNOWN_ERROR_MESSAGE',
                title: undefined
              },
              true
            )
          })
        );
      });
  };
