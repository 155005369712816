import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import {
  AlloyButton,
  ComponentLoader,
  StickySection,
  Toggle
} from 'cat-ecommerce-alloy';
import Conditional from '@app/components/common/Conditional';
import ConsentAcceptance from '@app/components/common/ConsentAcceptance';
import ExceptionFeedbackBySelector from '../../common/Exception/ExceptionFeedbackBySelector';
import LogoutButton from '../../NavigationBarUtilitySection/LogoutButton';
import CancelImportButton from '../CancelImportButton';
import DealerLocatorFilterButton from '../DealerLocatorFilterButton';
import useSelectStore from '../hooks/useSelectStore/useSelectStore';
import DealerLocationList from './DealerLocationList';
import DealerLocationListErrorEffect from './DealerLocationListErrorEffect';
import DealerLocationMap from './DealerLocationMap';

import { normalizeUrl } from '@app/utils';
import { ERROR_DOMAIN, ERROR_PATH } from '../../../constants/errorConstants';
import {
  dealerlocatorResultsPropTypes,
  errorPropTypes
} from '../declarations.proptypes';

const DealerLocationListForm = ({
  adpSubmitPromise,
  dealerStores,
  error,
  filters,
  isRegistration,
  fromEspot,
  loading,
  numDisplayed,
  filteredAndOrderedVisibleDealerStores,
  allFilteredDealerStoresByDistance,
  numInvocations,
  setFilters,
  selectedSuggestion,
  setNumDisplayed,
  setSelectedSuggestion,
  showShopAsGuestButton,
  showCancelImportButton,
  uom,
  signInRef,
  bobRedirect,
  bobUserId,
  reorderFunc,
  showDefaultLocation
}) => {
  const [t] = useTranslation();
  const {
    loading: selectStoreLoading,
    invoke,
    isConsentError
  } = useSelectStore();
  const isCSR = useSelector(s => s.common.isCatCSR) || false;
  const errorInfo = useSelector(
    s => s?.errors?.[ERROR_DOMAIN.DEALER_LOCATOR]?.[ERROR_PATH.LOCATIONS]
  );
  const isSingleButton = !showShopAsGuestButton && !showCancelImportButton;
  const resultsAvailable =
    Array.isArray(dealerStores) && dealerStores.length > 0;
  const filteredResultsAvailable =
    filteredAndOrderedVisibleDealerStores.length > 0;
  const viewMapText = t('FYD_MAP_VIEW');
  const isBlankResult =
    !!selectedSuggestion &&
    !error &&
    !loading &&
    numInvocations > 0 &&
    !resultsAvailable;

  const isBlankFilteredDealers = resultsAvailable && !filteredResultsAvailable;
  const closestStoreLocation = useMemo(() => {
    if (showDefaultLocation) {
      return filteredAndOrderedVisibleDealerStores.find(item => item.isClosest)
        ?.id;
    }
    return '';
  }, [showDefaultLocation, filteredAndOrderedVisibleDealerStores]);
  const initialValues = {
    storeLocation: closestStoreLocation,
    caterpillarPrivacyStatement: false
  };

  let isShopAsGuestVisible = showShopAsGuestButton;
  if (errorInfo) {
    isShopAsGuestVisible = !isCSR;
  }

  const onSubmit = (values, { setFieldValue }) => {
    const locationId = values.storeLocation;
    const { dealerName, name: storeName } =
      dealerStores.find(s => s.id === locationId) || {};
    invoke({
      adpSubmitPromise,
      dealerName,
      isRegistration,
      fromEspot,
      locationId,
      storeName,
      consent: values.caterpillarPrivacyStatement,
      isConsentSelected: true,
      callBackFailure: () => {
        setFieldValue('caterpillarPrivacyStatement', false);
      },
      bobRedirect,
      bobUserId,
      reorderFunc
    });
  };

  /**
   * Disables select store button when both or either one of the values are empty.
   * It works based on the show consent flag.
   *
   * @param {*} values
   * @returns
   */
  const disableStoreButton = values => {
    if (isConsentError) {
      return false;
    }

    if (isCSR && values.storeLocation) {
      return false;
    }

    return !(values.storeLocation && values.caterpillarPrivacyStatement);
  };

  /**
   * Returns dealer name for the given location.
   *
   * @param {*} location
   * @returns
   */
  const getDealer = location => {
    const store =
      dealerStores && dealerStores.find(store => store.id === location);
    return {
      dealerName: store ? store.dealerName : null,
      seoURL: store ? normalizeUrl(store.seoURL) : null,
      dealerURL: store ? store.seoURL : null,
      dealerStoreId: store ? store?.dealerStoreId : null,
      displayDealerTermsAndCond: store ? store.displayDealerTermsAndCond : null
    };
  };

  const [isMapView, setIsMapView] = useState(false);

  const dealerLocationInfo = () =>
    isMapView ? (
      <DealerLocationMap
        /**
         * We add the filters as a key as a workaround to re-render the map upon a filter change
         * This is due to when the filters trigger a change and only some markers change, the Markers
         * that did not re-render become zombie like from within the google maps api
         * and do not respond to icon changes properly
         *
         * Additionally, keys are not able to consume objects so we stringify here
         */
        key={JSON.stringify(filters)}
        results={allFilteredDealerStoresByDistance}
        setSelectedSuggestion={setSelectedSuggestion}
        uom={uom}
      />
    ) : (
      <DealerLocationList
        signInRef={signInRef}
        dealerStores={dealerStores}
        isMapView={isMapView}
        numDisplayed={numDisplayed}
        filteredAndOrderedVisibleDealerStores={
          filteredAndOrderedVisibleDealerStores
        }
        allFilteredDealerStoresByDistance={allFilteredDealerStoresByDistance}
        setNumDisplayed={setNumDisplayed}
        uom={uom}
      />
    );

  return (
    <>
      <DealerLocationListErrorEffect
        error={error}
        isBlankResult={isBlankResult}
        isBlankFilteredDealers={isBlankFilteredDealers}
        bobRedirect={bobRedirect}
      />
      <span className="d-flex flex-row justify-content-between align-items-center mb-1 m-0">
        <Toggle
          className="d-inline-block"
          id="mapView"
          aria-label={viewMapText}
          label={viewMapText}
          labelClassName="text-md text-capitalize text-sans-serif"
          name="dealer-locator__map-view-toggle-button"
          checked={isMapView}
          setChecked={setIsMapView}
        />
        <DealerLocatorFilterButton
          className="flex-shrink-1 d-lg-none"
          dealerStores={dealerStores}
          filters={filters}
          setFilters={setFilters}
          name="dealer-locator__filter-button--mobile"
        />
      </span>
      <ExceptionFeedbackBySelector
        selector={state =>
          state?.errors?.[ERROR_DOMAIN.DEALER_LOCATOR]?.[ERROR_PATH.LOCATIONS]
        }
        messageClassName={cx({
          'mb-3': isBlankFilteredDealers
        })}
        showContactUs={!isBlankFilteredDealers && !bobRedirect}
        skipTranslation={isCSR}
      />
      {(selectStoreLoading || loading) && <ComponentLoader />}
      {!loading && (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validateOnChange={true}
        >
          {({ values }) => (
            <Form className="text-start" name="dealer-locator__list-form">
              {dealerLocationInfo()}
              <StickySection
                className={cx('bg-white py-3 z-index-1', {
                  'text-center': isSingleButton,
                  'text-end': !isSingleButton
                })}
              >
                <Conditional test={!error && !isBlankResult && !isCSR}>
                  <ConsentAcceptance dealer={getDealer(values.storeLocation)} />
                </Conditional>
                {isShopAsGuestVisible && (
                  <LogoutButton
                    buttonType="ghost"
                    className="my-3 me-4"
                    size="large"
                    name="welcome-modal__shop-as-guest-button"
                    isCSR={isCSR}
                  >
                    {t('SHOP_AS_GUEST')}
                  </LogoutButton>
                )}
                {showCancelImportButton && <CancelImportButton />}
                <AlloyButton
                  buttonType={isCSR ? 'secondary' : 'primary'}
                  className="my-3"
                  size="large"
                  type="submit"
                  disabled={disableStoreButton(values)}
                  name={
                    showShopAsGuestButton || showCancelImportButton
                      ? 'welcome-modal__select-store-button'
                      : 'dealer-locator__select-store-button'
                  }
                >
                  {t('CAT_SELECT_STORE')}
                </AlloyButton>
              </StickySection>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default DealerLocationListForm;

DealerLocationListForm.propTypes = {
  adpSubmitPromise: PropTypes.func,
  error: errorPropTypes,
  dealerStores: PropTypes.arrayOf(dealerlocatorResultsPropTypes),
  filters: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
  ),
  isRegistration: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  filteredAndOrderedVisibleDealerStores: PropTypes.arrayOf(
    dealerlocatorResultsPropTypes
  ),
  allFilteredDealerStoresByDistance: PropTypes.arrayOf(
    dealerlocatorResultsPropTypes
  ),
  numDisplayed: PropTypes.number.isRequired,
  numInvocations: PropTypes.number,
  selectedSuggestion: PropTypes.string,
  setFilters: PropTypes.func.isRequired,
  setNumDisplayed: PropTypes.func.isRequired,
  setSelectedSuggestion: PropTypes.func.isRequired,
  showCancelImportButton: PropTypes.bool,
  showShopAsGuestButton: PropTypes.bool,
  uom: PropTypes.string,
  fromEspot: PropTypes.bool,
  signInRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  bobRedirect: PropTypes.string,
  bobUserId: PropTypes.string,
  showDefaultLocation: PropTypes.bool
};
