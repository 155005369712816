import {
  FORM_ADD_EQ_SUGGESTION_SUBMITTED_NAME,
  STATUS_FAIL,
  STATUS_SUCCESS
} from '@app/constants/analyticsConstants';
import endpoints from '@app/constants/endpoints';
import { useAnalytics, useHttp } from '@app/hooks';
import { replaceTokensInUrl } from '@app/utils';
import debounce from 'lodash/debounce';
import { useMemo, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MILLISECONDS_DEBOUNCE } from '@app/constants/commonConstants';
import {
  setError as setSystemError,
  clearErrors
} from '@app/store/myequipment/actions';

const useEquipmentSearchSuggestions = () => {
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState('');
  const { invoke, loading } = useHttp();
  const { fireFormSubmittedEvent } = useAnalytics();
  const dispatch = useDispatch();

  const langId = useSelector(state => state.common.langId);
  const catalogId = useSelector(state => state.common.catalogId);
  const storeId = useSelector(state => state.common.storeId);

  const sendFormSubmittedEvent = useCallback(
    ({
      formContent,
      formStatus,
      formFieldCausingError = '',
      formLocation = '',
      formName = FORM_ADD_EQ_SUGGESTION_SUBMITTED_NAME
    }) => {
      fireFormSubmittedEvent({
        pagePath: document.location.pathname,
        formName,
        formContent,
        formStatus,
        formLocation,
        formFieldCausingError
      });
    },
    [fireFormSubmittedEvent]
  );

  const onSuggestionsSearchSuccess = useCallback(
    (serialNumber, data, updateSearchState, gaForm) => {
      setError(false);
      setErrorText('');
      updateSearchState({
        query: serialNumber,
        machinesCount: data.machinesCount,
        otherResults: data.otherEquipments,
        results:
          serialNumber.length >= 2 && data.equipments ? data.equipments : [],
        success: !!(
          serialNumber.length >= 2 &&
          (data.equipments || data.otherEquipments)
        )
      });
      sendFormSubmittedEvent({
        formContent: serialNumber,
        formStatus: STATUS_SUCCESS,
        ...gaForm
      });
    },
    [sendFormSubmittedEvent]
  );

  const onSuggestionsSearchFail = useCallback(
    (serialNumber, data, updateSearchState, gaForm) => {
      const errorMessage = data?.errors[0]?.errorMessage;
      updateSearchState({
        query: serialNumber,
        results: [],
        otherResults: [],
        success: false
      });
      if (errorMessage) {
        setError(true);
        setErrorText(errorMessage);
      } else {
        dispatch(setSystemError({ response: { status: '' } }));
      }
      sendFormSubmittedEvent({
        formContent: serialNumber,
        formStatus: STATUS_FAIL,
        formFieldCausingError: errorMessage,
        ...gaForm
      });
    },
    [dispatch, sendFormSubmittedEvent]
  );

  const invokeDebounce = useMemo(
    () =>
      debounce(
        (serialNumber, updateSearchState, filter, gaForm) => {
          let url;
          if (!filter) {
            url = replaceTokensInUrl(
              endpoints.EQUIPMENT_SEARCH,
              serialNumber.toLowerCase(),
              catalogId,
              langId,
              storeId
            );
          } else {
            url = replaceTokensInUrl(
              endpoints.EQUIPMENT_SEARCH_REST,
              storeId,
              serialNumber.toLowerCase(),
              catalogId,
              langId,
              filter
            );
          }
          invoke({ method: 'get', rethrowError: true, url: url })
            .then(data => {
              dispatch(clearErrors());
              if (data.equipments || data.otherEquipments) {
                onSuggestionsSearchSuccess(
                  serialNumber,
                  data,
                  updateSearchState,
                  gaForm
                );
              }
              if (data.errors) {
                onSuggestionsSearchFail(
                  serialNumber,
                  data,
                  updateSearchState,
                  gaForm
                );
              }
            })
            .catch(({ response }) => {
              onSuggestionsSearchFail(
                serialNumber,
                response?.data,
                updateSearchState,
                gaForm
              );
            });
        },
        MILLISECONDS_DEBOUNCE,
        {
          leading: true,
          trailing: true
        }
      ),
    [
      invoke,
      catalogId,
      langId,
      storeId,
      dispatch,
      onSuggestionsSearchSuccess,
      onSuggestionsSearchFail
    ]
  );

  const callSuggestionsSearchService = (
    serialNumber,
    updateSearchState,
    filter = '',
    gaForm = {}
  ) => {
    updateSearchState({
      query: serialNumber,
      results: [],
      otherResults: [],
      success: false
    });
    invokeDebounce(serialNumber, updateSearchState, filter, gaForm);
  };

  return {
    loading,
    error,
    setError,
    errorText,
    setErrorText,
    callSuggestionsSearchService,
    sendFormSubmittedEvent
  };
};

export default useEquipmentSearchSuggestions;
