import { CSR_ACTIONS } from '@app/components/pages/csr-landing/constants';
import { CSR_TEXTS } from '@app/components/pages/csr-landing/texts';
import links from '@app/constants/links';
import { useSystemFeedback } from '@app/hooks';
import { removeLocalStorage } from '@app/utils/localStorageUtils';
import queryString from 'query-string';
import { useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTracking } from 'react-tracking';
import {
  CONSENT_SESSION_KEY,
  FULFILMENT_DATE_CHANGED,
  GENERIC_USER_ID,
  LAST_FULFILLMENT_DATE_CHANGED,
  TIMEOUT_DOUBLED,
  TIMEOUT_EXTENDED,
  USER_TYPE_GUEST
} from '../../../../constants/commonConstants';
import endpoints from '../../../../constants/endpoints';
import { ERROR_DOMAIN, ERROR_PATH } from '../../../../constants/errorConstants';
import useHttp from '../../../../hooks/useHttp';
import { normalizeError } from '../../../../store/exception/utils';
import { replaceTokensInUrl, setSessionStorage } from '../../../../utils';
import { getFormSubmitEvent } from '../../../common/analytics/analyticsUtils';
import { createGuestIdentity } from '@app/store/common/actions';

const useSelectStore = () => {
  const { trackEvent } = useTracking();
  const dispatch = useDispatch();
  const { invoke: getUserInfo, loading: userLoading } = useHttp();
  const { invoke: callSetGuestIdentity, loading: guestIdentityLoading } =
    useHttp();
  const { invoke: callSelectStore, loading } = useHttp();
  const { invoke: setGuestUser } = useHttp();
  const { invoke: resetGuestUser } = useHttp();
  const { invoke: getRegisterURL, loading: registerLoading } = useHttp();
  const { invoke: setGuestUserInSession } = useHttp();
  const { invoke: setUserInSession } = useHttp();
  const storeId = useSelector(s => s?.common?.storeId);
  const langId = useSelector(s => s?.common?.langId);
  const userType = useSelector(s => s?.common?.userType);
  const selectedStore = useSelector(s => s?.common?.selectedStore);
  const dealerAssociations = useSelector(
    s => s?.dealerAssociation?.dealerAssociations
  );
  const { setError } = useSystemFeedback();
  const [isConsentError, setisConsentError] = useState(false);
  const featureFlagStatus = useSelector(s => s?.featureFlag?.featureFlagStatus);
  const pCCUserRegistrationRedirectFlag = useSelector(
    state => state.featureFlag?.PCCUserRegistrationRedirect
  );
  const handleError = useCallback(
    async (error = {}, gaEvent, isTransactAsAGuest, nonIADealer) => {
      if (isTransactAsAGuest) {
        try {
          await resetGuestUser({
            url: endpoints.CSR_TABLE_RESET_CUSTOMER_SELECTION
          });
          sessionStorage.removeItem('isGuest');
        } catch (e) {
          console.error(e);
        }
      }
      trackEvent({ ...gaEvent, formStatus: 'fail' });
      let errorParams = {
        severity: 'warning',
        message: isTransactAsAGuest
          ? CSR_TEXTS.ADP_MODAL_ERROR_MESSAGE
          : 'UNKNOWN_ERROR_MESSAGE',
        title: undefined
      };

      if (isTransactAsAGuest && nonIADealer) {
        errorParams = {
          ...errorParams,
          severity: 'error',
          message: CSR_TEXTS.NON_IA_DEALER_ERROR_MESSAGE
        };
      }

      setError(ERROR_DOMAIN.DEALER_LOCATOR, ERROR_PATH.LOCATIONS, {
        ...normalizeError(error, errorParams, true)
      });
    },
    [resetGuestUser, setError, trackEvent]
  );

  const invokeSetGuestUser = useCallback(
    async gaEvent => {
      try {
        const response = await setGuestUser({
          url: `${links.TRANSACT_AS_GUEST}`
        });
        // Temporal solution for Buy on Behalf as a guest workaround
        const responseError = response.includes('errorCode');
        return !responseError;
      } catch (error) {
        handleError(error, gaEvent, true);
        return false;
      }
    },
    [handleError, setGuestUser]
  );
  const routeRegisterPage = useCallback(
    async (redirectURL, gaEvent, bobRedirect) => {
      try {
        const data = await getRegisterURL({ url: redirectURL });
        if (data && data.b2cUrl) {
          window.location = data.b2cUrl;
        } else {
          handleError({}, gaEvent, bobRedirect);
        }
      } catch (error) {
        handleError(error, gaEvent, bobRedirect);
      }
    },
    [getRegisterURL, handleError]
  );

  const getSelectStore = useCallback(
    async (body, storeSelectId) => {
      const timeout = TIMEOUT_DOUBLED;
      const selectStoreUrl = replaceTokensInUrl(
        endpoints.SELECT_STORE,
        storeSelectId,
        langId
      );
      return await callSelectStore({
        data: body,
        method: 'post',
        rethrowError: true,
        timeout,
        url: selectStoreUrl
      });
    },
    [callSelectStore, langId]
  );

  const handleBuyOnBehalf = async (
    redirectURL,
    dealerStoreId,
    adpSubmitPromise,
    reorderFunc
  ) => {
    await adpSubmitPromise(dealerStoreId, true);
    if (reorderFunc) {
      reorderFunc(dealerStoreId);
    } else {
      window.location = redirectURL;
    }
  };
  const handleTransactAsAGuest = async (
    redirectURL,
    dealerStoreId,
    gaEvent,
    bobUserId,
    isTransactAsAGuest
  ) => {
    if (redirectURL.includes('UserRegistrationForm')) {
      handleError({}, gaEvent, isTransactAsAGuest, true);
    } else {
      await setGuestUserInSession({
        url: replaceTokensInUrl(
          endpoints.CSR_SET_USER_IN_SESSION,
          dealerStoreId,
          bobUserId
        )
      });
      await setUserInSession({
        url: replaceTokensInUrl(
          endpoints.CSR_TABLE_SELECT_ENDPOINT,
          bobUserId,
          dealerStoreId
        )
      });
      sessionStorage.setItem('isGuest', 'true');
      window.location = redirectURL;
    }
  };
  const handleRedirectDefaultCase = (gaEvent, redirectURL, bobRedirect) => {
    trackEvent(gaEvent);
    const urlParams = queryString.parse(redirectURL);
    const isRegistration = !!urlParams.isRegistration;
    if (featureFlagStatus === 'resolved') {
      if (pCCUserRegistrationRedirectFlag && isRegistration) {
        routeRegisterPage(redirectURL, gaEvent, bobRedirect);
      } else {
        window.location = redirectURL;
      }
    }
  };
  const handleCallBackFailureError = (
    err,
    callBackFailure,
    gaEvent,
    isTransactAsAGuest
  ) => {
    if (err?.response?.data?.errors) {
      callBackFailure && callBackFailure();
      setisConsentError(true);
    } else {
      handleError(err, gaEvent, isTransactAsAGuest);
    }
  };
  const selectStore = useCallback(
    (
      body,
      gaEvent,
      isTransactAsAGuest,
      isBuyOnBehalf,
      adpSubmitPromise,
      bobUserId,
      reorderFunc,
      bobRedirect,
      callBackFailure
    ) => {
      const hasNoAssociations =
        !Array.isArray(dealerAssociations) || dealerAssociations.length === 0;
      const timeout =
        userType !== USER_TYPE_GUEST && hasNoAssociations
          ? TIMEOUT_EXTENDED
          : TIMEOUT_DOUBLED;
      const bobSelectStoreUrl = replaceTokensInUrl(
        `${endpoints.SELECT_STORE}&forUserId={2}`,
        storeId,
        langId,
        bobUserId
      );
      const selectStoreUrl = replaceTokensInUrl(
        endpoints.SELECT_STORE,
        storeId,
        langId
      );
      setisConsentError(false);
      callSelectStore({
        data: body,
        method: 'post',
        rethrowError: true,
        timeout,
        url:
          isBuyOnBehalf || isTransactAsAGuest
            ? bobSelectStoreUrl
            : selectStoreUrl
      })
        .then(async ({ redirectURL, dealerStoreId }) => {
          if (redirectURL) {
            if (isBuyOnBehalf) {
              handleBuyOnBehalf(
                redirectURL,
                dealerStoreId,
                adpSubmitPromise,
                reorderFunc
              );
            } else if (isTransactAsAGuest) {
              handleTransactAsAGuest(
                redirectURL,
                dealerStoreId,
                gaEvent,
                bobUserId,
                isTransactAsAGuest
              );
            } else {
              handleRedirectDefaultCase(gaEvent, redirectURL, bobRedirect);
            }
          } else {
            handleError({}, gaEvent, isTransactAsAGuest);
          }
        })
        .catch(err => {
          handleCallBackFailureError(
            err,
            callBackFailure,
            gaEvent,
            isTransactAsAGuest
          );
        });
    },
    [
      callSelectStore,
      dealerAssociations,
      handleError,
      langId,
      setGuestUserInSession,
      setUserInSession,
      storeId,
      trackEvent,
      userType,
      routeRegisterPage,
      featureFlagStatus
    ]
  );

  const invoke = useCallback(
    async ({
      adpSubmitPromise,
      dealerName,
      isRegistration,
      fromEspot,
      locationId,
      storeName,
      bobRedirect,
      bobUserId = false,
      reorderFunc,
      consent,
      isConsentSelected,
      callBackFailure
    } = {}) => {
      const { pathname, search } = window.location;
      const pageURL = `${pathname}${search}`;
      const gaEvent = {
        ...getFormSubmitEvent({
          formName: 'Select Store',
          formContent: `${dealerName}, ${storeName}`
        }),
        formFieldCausingError: ''
      };
      const isTransactAsAGuest = bobRedirect === CSR_ACTIONS.TRANSACT_AS_GUEST;
      const isBuyOnBehalf =
        bobRedirect === CSR_ACTIONS.BUY_ON_BEHALF ||
        bobRedirect === CSR_ACTIONS.MANAGE_ACCOUNT;
      const body = {
        isRegistration,
        fromEspot,
        locationId,
        storeId,
        pageURL,
        consent,
        bobRedirect: isTransactAsAGuest
          ? CSR_ACTIONS.BUY_ON_BEHALF
          : bobRedirect
      };
      if (isBuyOnBehalf) {
        await adpSubmitPromise();
      }

      // Temporal solution for Buy on Behalf as a guest workaround
      let guestSetted;

      if (isTransactAsAGuest) {
        guestSetted = await invokeSetGuestUser(gaEvent);
      }

      try {
        if (isTransactAsAGuest && !guestSetted) {
          throw new Error();
        }
      } catch (error) {
        return handleError(error, gaEvent, true);
      }
      getUserInfo({
        url: replaceTokensInUrl(endpoints.USER_INFO_URL, storeId, langId)
      })
        .then(async data => {
          if (data.userId === GENERIC_USER_ID) {
            await dispatch(createGuestIdentity())
              .then(() => {
                selectStore(
                  body,
                  gaEvent,
                  isTransactAsAGuest,
                  isBuyOnBehalf,
                  adpSubmitPromise,
                  isTransactAsAGuest ? data.userId : bobUserId,
                  reorderFunc,
                  bobRedirect,
                  callBackFailure
                );
                setSessionStorage(CONSENT_SESSION_KEY, isConsentSelected);
              })
              .catch(err => handleError(err, gaEvent));
          } else {
            selectStore(
              body,
              gaEvent,
              isTransactAsAGuest,
              isBuyOnBehalf,
              adpSubmitPromise,
              isTransactAsAGuest ? data.userId : bobUserId,
              reorderFunc,
              bobRedirect,
              callBackFailure
            );
            setSessionStorage(CONSENT_SESSION_KEY, isConsentSelected);
          }
          if (selectedStore !== data.selectStore) {
            removeLocalStorage(LAST_FULFILLMENT_DATE_CHANGED);
            removeLocalStorage(FULFILMENT_DATE_CHANGED);
          }
        })
        .catch(err => handleError(err, gaEvent));
    },
    [
      dispatch,
      getUserInfo,
      handleError,
      invokeSetGuestUser,
      langId,
      selectStore,
      selectedStore,
      storeId
    ]
  );

  return {
    invoke,
    loading: loading || guestIdentityLoading || userLoading || registerLoading,
    isConsentError,
    getSelectStore
  };
};

export default useSelectStore;
