import endpoints from '../../constants/endpoints';
import { getHttpInstance } from '../../services/defaultHttpService';
import {
  getDealerCustomerInfo,
  setDealerCustomerInfo
} from '../../services/cacheService';
import * as types from './constants';
import {
  replaceTokensInUrl,
  isDev,
  getObjectAsQueryParams,
  isEmpty,
  getCookie
} from '../../utils';
import { createGuestIdentity } from '@app/store/common/actions';
import {
  hasBothMetricAndUSSpecs,
  normalizeProducts,
  extractProductsIds,
  transformReplacementPartsItems,
  waitForStoreInfo
} from './utils';
import { normalizeError } from '../exception/utils';
import {
  clearError,
  setError,
  setError as setExceptionError
} from '../exception/actions';
import { setProductsInfo } from '../homepage/actions';
import {
  RECENTLY_VIEWED_PRODUCTS_DEFAULT_PAGE_SIZE,
  GENERIC_USER_ID,
  GET_PRODUCTS_NO_CACHE_HEADER,
  REQUEST_ID_HEADER,
  STATUS,
  TIMEOUT_EXTENDED
} from '@app/constants/commonConstants';
import { ERROR_DOMAIN, ERROR_PATH } from '@app/constants/errorConstants';
import { DEALER_CUSTOMER_STORES_SET_INFO } from '../dealer-customer/constants';
import { v4 } from 'uuid';
import {
  getInitialPriceAndAvailabilityById,
  getRejectedPriceAndAvailabilityById,
  getResponsePriceAndAvailabilityById
} from './priceAndAvailabilityUtils';
import { cloneDeep } from 'lodash';
import { PRICE_AVAILABILITY_ATTRIBUTE_ID } from '@app/hooks/usePriceAndAvailability/constants';
import {
  BY_COMMA,
  COOKIE_BROWSING_HISTORY
} from '@app/components/pages/pdp/ProductDisplayPage/constant';

export const getProductUrlToken = partNumber => async (dispatch, getState) => {
  const timeout = isDev() ? TIMEOUT_EXTENDED : undefined;
  const http = getHttpInstance(timeout);
  const { storeId } = getState().common;
  const { byId } = getState().products;
  const url = replaceTokensInUrl(
    endpoints.PRODUCT_URL_TOKEN,
    storeId,
    partNumber
  );
  dispatch({ type: types.PRODUCTS_GET_URL_TOKEN_BEGIN });
  try {
    const { data } = await http.get(url);
    const existingProductData = byId[data.uniqueId];
    const payload = existingProductData
      ? { ...existingProductData, ...data, partNumber }
      : { ...data, partNumber };
    dispatch({ type: types.PRODUCTS_GET_URL_TOKEN_SUCCESS, payload });
  } catch (error) {
    dispatch({ type: types.PRODUCTS_GET_URL_TOKEN_FAIL, payload: error });
  }
};

export const getSummaryItems = partNumbers => async (dispatch, getState) => {
  const http = getHttpInstance(TIMEOUT_EXTENDED);
  const { locale, storeIdentifier, storeId } = getState().common;
  const url = replaceTokensInUrl(
    endpoints.SUMMARY_API,
    locale,
    storeIdentifier,
    storeId
  );
  dispatch({ type: types.SUMMARY_ITEMS_BEGIN });

  try {
    const {
      data: { products = [] }
    } = await http.post(url, partNumbers, {
      headers: {
        [REQUEST_ID_HEADER]: v4()
      }
    });
    dispatch({ type: types.SUMMARY_ITEMS_SUCCESS });
    return products;
  } catch (error) {
    const normalizedError = normalizeError(error);
    dispatch({ type: types.SUMMARY_ITEMS_FAIL, payload: normalizedError });
  }
};

export const getPriceAndAvailability =
  ({
    partsList = [],
    namespace = 'priceAndAvailability',
    setError,
    setStatus,
    statusActionPrefix,
    errorInfo = {},
    widgetId,
    useCacheService = false,
    attributeId = types.PRICE_AVAILABILITY_DEFAULT_ATTRIBUTE_ID
  } = {}) =>
  async (dispatch, getState) => {
    const { userId, isCatCorp } = getState().common;
    if (userId === GENERIC_USER_ID && !isCatCorp) {
      await dispatch(createGuestIdentity());
    }

    // Waiting to has dealer store info in the redux store
    await waitForStoreInfo(getState, { maxAttempts: 3, timeout: 100 });

    // If true, we use uniqueId as key. If false, we use partNumber as key
    const checkPartListId =
      attributeId === types.PRICE_AVAILABILITY_DEFAULT_ATTRIBUTE_ID;

    const initialPriceAndAvailabilityById =
      await getInitialPriceAndAvailabilityById({
        state: getState(),
        namespace,
        partsList,
        checkPartListId,
        useCacheService
      });

    dispatch({
      type: types.PRODUCTS_SET_PRICE,
      payload: {
        priceAndAvailability: initialPriceAndAvailabilityById,
        namespace
      }
    });

    dispatch({ type: types.PRODUCTS_GET_PRICE_BEGIN });

    if (statusActionPrefix) {
      dispatch({
        type: types[`${statusActionPrefix}_PRODUCTS_PRICE_SET_STATUS`],
        payload: { status: STATUS.PENDING, widgetId }
      });
    }
    if (setStatus) {
      setStatus(STATUS.PENDING);
    }

    const partsToRequest = partsList.filter(
      part =>
        initialPriceAndAvailabilityById[part[attributeId]]?.loadingStatus ===
          STATUS.PENDING ||
        initialPriceAndAvailabilityById[part[attributeId]]?.loadingStatus ===
          STATUS.RESOLVED
    );

    try {
      const {
        langId,
        userId,
        storeId,
        selectedCustomerNumber,
        selectedEndUseCode,
        selectedOrderType,
        selectedStore = ''
      } = getState().common;
      const { current, byId } = getState().products;
      const currentPart = byId[current];
      let customerInfo = useCacheService
        ? await getDealerCustomerInfo({
            userId,
            langId,
            storeId,
            selectedStore,
            dcn: selectedCustomerNumber,
            endUseCode: selectedEndUseCode?.value,
            orderType: selectedOrderType?.value
          })
        : {};

      if (!isEmpty(customerInfo)) {
        dispatch({
          type: DEALER_CUSTOMER_STORES_SET_INFO,
          payload: customerInfo
        });
      }

      let priceAndAvailability = [];

      const getTransformedPartsAndAvailability =
        async priceAndAvailabilityData => {
          let replacementSummaryItems = [];
          let updatedPriceAndAvailability = cloneDeep(priceAndAvailabilityData);
          //  On parts list pages there is no current part
          // so we can skip the logic to update the replacements parts using the summary service
          // We still need to return cloned data to protect against mutation in other logic
          if (!currentPart) {
            return updatedPriceAndAvailability;
          }
          const replacementParts = priceAndAvailabilityData.reduce(
            (acc, part) => {
              return part[attributeId] === currentPart[attributeId] &&
                part?.replacementParts
                ? part.replacementParts
                : acc;
            },
            []
          );

          if (replacementParts.length) {
            const partNumbers = replacementParts.map(item => item.partNumber);
            replacementSummaryItems = await dispatch(
              getSummaryItems({ partNumbers })
            );

            updatedPriceAndAvailability = priceAndAvailabilityData.map(part => {
              part.replacementParts = transformReplacementPartsItems(
                replacementParts,
                replacementSummaryItems
              );

              return part;
            });
          }

          return updatedPriceAndAvailability;
        };

      if (partsToRequest.length > 0) {
        const http = getHttpInstance(TIMEOUT_EXTENDED);
        const url = replaceTokensInUrl(
          endpoints.PRICE_AVAILABILITY_URL,
          storeId,
          langId
        );
        const postParams = { partsList: partsToRequest };
        const { data, config } = await http.post(url, postParams);

        priceAndAvailability = await getTransformedPartsAndAvailability(
          data.priceAndAvailability
        );
        // save only dealer - related data
        if (isEmpty(customerInfo)) {
          customerInfo = {
            currency: data.currency,
            formattedZeroPrice: data.formattedZeroPrice,
            gstIncludesTax: data.gstIncludesTax,
            isGstAvailable: data.isGstAvailable,
            taxDisclaimerMessage: data.taxDisclaimerMessage,
            taxDisclaimerMessageFooter: data.taxDisclaimerMessageFooter
          };

          if (useCacheService && data.currency) {
            await setDealerCustomerInfo({
              langId,
              userId,
              storeId,
              selectedStore,
              dcn: selectedCustomerNumber,
              customerInfo,
              endUseCode: selectedEndUseCode?.value,
              orderType: selectedOrderType?.value
            });
          }
        }
      } else {
        const cachedPriceAndAvailability = Object.values(
          initialPriceAndAvailabilityById
        );
        priceAndAvailability = await getTransformedPartsAndAvailability(
          cachedPriceAndAvailability
        );
      }

      const responsePriceAndAvailabilityById =
        await getResponsePriceAndAvailabilityById({
          state: getState(),
          namespace,
          partsToRequest,
          priceAndAvailability,
          checkPartListId,
          customerInfo,
          useCacheService
        });
      dispatch({
        type: types.PRODUCTS_SET_PRICE,
        payload: {
          namespace,
          priceAndAvailability: responsePriceAndAvailabilityById
        }
      });
      dispatch({ type: types.PRODUCTS_GET_PRICE_SUCCESS });
      if (customerInfo.currency) {
        dispatch({
          type: DEALER_CUSTOMER_STORES_SET_INFO,
          payload: customerInfo
        });
      }

      if (statusActionPrefix) {
        dispatch({
          type: types[`${statusActionPrefix}_PRODUCTS_PRICE_SET_STATUS`],
          payload: { status: STATUS.RESOLVED, widgetId }
        });
      }
      if (setError) {
        setError(undefined);
      }
      if (setStatus) {
        setStatus(STATUS.RESOLVED);
      }
      return {
        priceAndAvailability: {
          ...initialPriceAndAvailabilityById,
          ...responsePriceAndAvailabilityById
        },
        dealerCustomer: customerInfo
      };
    } catch (error) {
      const rejectedPriceAndAvailabilityById =
        getRejectedPriceAndAvailabilityById({
          state: getState(),
          namespace,
          partsToRequest,
          checkPartListId
        });

      dispatch({
        type: types.PRODUCTS_SET_PRICE,
        payload: {
          namespace,
          priceAndAvailability: rejectedPriceAndAvailabilityById
        }
      });
      dispatch({ type: types.PRODUCTS_GET_PRICE_FAIL });
      if (statusActionPrefix) {
        dispatch({
          type: types[`${statusActionPrefix}_PRODUCTS_PRICE_SET_STATUS`],
          payload: { status: STATUS.REJECTED, widgetId }
        });
      }
      if (setError) {
        setError(error);
      }
      if (setStatus) {
        setStatus(STATUS.REJECTED);
      }
      const { domain, path } = errorInfo;
      if (domain && path) {
        const err = normalizeError(error);
        dispatch(setExceptionError(domain, path, err));
      }
    }
  };

export const getProductDetails = () => async (dispatch, getState) => {
  const timeout = isDev() ? TIMEOUT_EXTENDED : undefined;
  const http = getHttpInstance(timeout);
  const { storeIdentifier, locale, storeId, langId } = getState().common;
  const { current, byId } = getState().products;
  const { partNumber } = byId[current];
  const url = replaceTokensInUrl(
    endpoints.PRODUCT_DETAILS,
    current,
    storeIdentifier,
    locale,
    partNumber,
    storeId,
    langId
  );

  dispatch({ type: types.PRODUCTS_GET_DETAILS_BEGIN });

  try {
    const { data } = await http.get(url, {
      headers: {
        [REQUEST_ID_HEADER]: v4()
      }
    });
    const existingProductData = byId[current];
    let payload = existingProductData
      ? { ...existingProductData, ...data }
      : data;
    const attributes = payload.attributes || [];
    payload = {
      ...payload,
      showSpecificationsToggle: hasBothMetricAndUSSpecs(attributes)
    };
    dispatch({ type: types.PRODUCTS_GET_DETAILS_SUCCESS, payload });
  } catch (error) {
    dispatch({ type: types.PRODUCTS_GET_DETAILS_FAIL, payload: error });
  }
};

export const getKitPartsDetails = partNumber => async (dispatch, getState) => {
  const timeout = isDev() ? TIMEOUT_EXTENDED : undefined;
  const http = getHttpInstance(timeout);
  const { storeIdentifier, locale, storeId, langId } = getState().common;
  const url = replaceTokensInUrl(
    endpoints.KIT_PARTS_DETAILS,
    storeIdentifier,
    locale,
    partNumber,
    storeId,
    langId
  );

  dispatch({ type: types.KIT_PARTS_GET_DETAILS_BEGIN });
  try {
    const { data } = await http.get(url);
    let payload = data;
    dispatch({ type: types.KIT_PARTS_GET_DETAILS_SUCCESS, payload });
  } catch (error) {
    dispatch({ type: types.KIT_PARTS_GET_DETAILS_FAIL, payload: error });
  }
};

export const setPDPCurrent = productId => dispatch => {
  dispatch({
    type: types.PRODUCTS_SET_PDP_CURRENT,
    payload: { productId, isMLP: false }
  });
};

export const resetProducts = () => ({ type: types.PRODUCTS_RESET });

export const setProductList = products => dispatch => {
  return dispatch({
    type: types.PRODUCTS_SET_PRODUCT_LIST,
    payload: { products: normalizeProducts({ products }) }
  });
};

export const updateProductList = products => dispatch => {
  return dispatch({
    type: types.PRODUCTS_UPDATE_PRODUCT_LIST,
    payload: { products: normalizeProducts({ products }) }
  });
};
export const getProductsRecentlyViewed =
  (pageSize = RECENTLY_VIEWED_PRODUCTS_DEFAULT_PAGE_SIZE, isLucid = false) =>
  async (dispatch, getState) => {
    const http = getHttpInstance(TIMEOUT_EXTENDED);
    const { storeIdentifier, locale, storeId, langId } = getState().common;
    const partNumbers =
      getCookie(COOKIE_BROWSING_HISTORY)?.split(BY_COMMA) ?? [];
    if (partNumbers.length === 0 && isLucid) {
      return;
    }
    const url = replaceTokensInUrl(
      endpoints.PRODUCT_RECENTLY_VIEWED_DETAILS,
      storeId,
      langId,
      pageSize
    );
    const bffUrl = replaceTokensInUrl(
      endpoints.PRODUCT_RECENTLY_VIEWED_DETAILS_LUCID,
      locale,
      storeIdentifier,
      storeId
    );
    dispatch({ type: types.PRODUCTS_GET_RECENTLY_VIEWED_BEGIN });
    try {
      const { data } = isLucid
        ? await http.post(bffUrl, { partNumbers })
        : await http.get(url);
      const parts = data.parts ?? data.products ?? [];
      const product = parts.map(part => ({
        ...part,
        productDisplayUrl: part.productDisplayUrl ?? part.seoURL
      }));
      const productsList = normalizeProducts({ products: product });
      const productsRecentlyViewed = extractProductsIds(parts ?? []);
      const viewMoreURL = data.viewMoreUrl ?? '';
      const totalCount = data.totalProductsViewed ?? data.productCount;
      dispatch({
        type: types.PRODUCTS_GET_RECENTLY_VIEWED_SUCCESS,
        payload: {
          productsList,
          productsRecentlyViewed,
          viewMoreURL,
          totalCount
        }
      });
      return true;
    } catch (error) {
      dispatch({
        type: types.PRODUCTS_GET_RECENTLY_VIEWED_FAIL,
        payload: error
      });
      return false;
    }
  };

export const getHomepageProducts =
  ({ url, sourceKey, statusKey, statusErrorKey }) =>
  async dispatch => {
    const http = getHttpInstance();
    const searchConfig = {
      headers: GET_PRODUCTS_NO_CACHE_HEADER
    };

    const keys = {
      sourceKey,
      statusKey,
      statusErrorKey
    };

    dispatch({
      type: types.GET_HOMEPAGE_PRODUCTS_BEGIN,
      payload: { statusKey, statusErrorKey }
    });
    try {
      const { data } = await http.get(url, searchConfig);
      const productsList = !!data
        ? normalizeProducts({ products: data?.parts })
        : {};
      const products = !!data ? extractProductsIds(data?.parts) : [];
      const viewMoreUrl = !!data && data?.viewMoreUrl;
      const productTotalCount =
        !!data && (data.totalCount || data.totalProductsViewed);
      dispatch({
        type: types.GET_HOMEPAGE_PRODUCTS_SUCCESS,
        payload: {
          productsList,
          statusKey: keys.statusKey,
          statusErrorKey: keys.statusErrorKey
        }
      });
      dispatch(
        setProductsInfo({
          products,
          viewMoreUrl,
          productTotalCount,
          sourceKey: keys.sourceKey
        })
      );
    } catch (error) {
      dispatch({
        type: types.GET_HOMEPAGE_PRODUCTS_FAIL,
        payload: { error, statusKey, statusErrorKey }
      });
    }
  };

export const getBundlePrice =
  (priceAndAvailability, productId) => async (dispatch, getState) => {
    const http = getHttpInstance();
    const { storeId, langId } = getState().common;
    const { current } = getState().products;
    const url = replaceTokensInUrl(endpoints.TOTAL_PRICE__URL, storeId);

    const itemsKey = Object.keys(priceAndAvailability).filter(
      item => item !== current
    );
    const isObjectEmpty = itemsKey.some(key => {
      return isEmpty(priceAndAvailability[key]);
    });
    try {
      if (!isObjectEmpty) {
        const lineItemsPrice = itemsKey.reduce((acc, key) => {
          const price = priceAndAvailability[key].unformattedTotalPrice;

          if (price) {
            return [...acc, price];
          }

          return acc;
        }, []);
        const { data } = await http.post(url, { lineItemsPrice, langId });

        const bundlePrice = {
          [productId]: {
            quantity: 1,
            packageQty: 1,
            totalPrice: data.formattedTotal,
            unformattedTotalPrice: data.unformattedTotal,
            discountedPrice: data.formattedTotal,
            uniqueId: current,
            enableAddToCart: true,
            loadingStatus: STATUS.RESOLVED,
            priceIsLoaded: true
          }
        };
        dispatch({
          type: types.PRODUCTS_SET_PRICE,
          payload: {
            priceAndAvailability: { ...priceAndAvailability, ...bundlePrice }
          }
        });
        dispatch({ type: types.PRODUCTS_GET_PRICE_SUCCESS });
      }
    } catch (error) {
      //do some error
      dispatch({ type: types.PRODUCTS_GET_PRICE_FAIL });
    }
  };

export const getFbtTotalPrice =
  (selectedItems, isLG) => (dispatch, getState) => {
    const http = getHttpInstance(TIMEOUT_EXTENDED);
    const { priceAndAvailability, current, fbtPriceAndAvailability, byId } =
      getState().products;
    const { products } = getState().products.fbtItems;
    const productsThree = products?.slice(0, 3);
    const finalItems = isLG ? products : productsThree;
    const { storeId, langId } = getState().common;
    const partNumber = byId[current].partNumber;
    const currentPartPrice = priceAndAvailability?.[partNumber];
    const currencyNotation = getState().dealerCustomer?.currency;
    let finalItemArray = [];
    currentPartPrice && finalItemArray.push(currentPartPrice);
    finalItems?.map(item => {
      const data = fbtPriceAndAvailability[item.partNumber];
      finalItemArray.push(data);
    });

    const selectedItemArray = selectedItems
      ?.filter(item1 => {
        const item2 = finalItemArray?.find(
          item2 => item2.partNumber === item1.id
        );
        return !item2;
      })
      .concat(
        finalItemArray?.filter(item2 => {
          const item1 = selectedItems?.find(
            item1 => item1.id === item2.partNumber
          );
          return !item1;
        })
      );

    const lineItemsPrice = selectedItems?.length
      ? selectedItemArray?.map(part => {
          return !isEmpty(part.unformattedTotalPrice)
            ? part.unformattedTotalPrice
            : 0;
        })
      : finalItemArray?.map(part => {
          return !isEmpty(part.unformattedTotalPrice)
            ? part.unformattedTotalPrice
            : 0;
        });

    const url = replaceTokensInUrl(endpoints.TOTAL_PRICE__URL, storeId);

    if (lineItemsPrice?.length) {
      dispatch({ type: types.FBT_TOTAL_PRICE_BEGIN });
      return http
        .post(url, { lineItemsPrice, langId, currencyNotation })
        .then(({ data = {} }) => {
          dispatch(
            clearError(
              ERROR_DOMAIN.REPAIR_OPTIONS,
              ERROR_PATH.LINE_ITEMS_TOTAL_PRICE
            )
          );
          dispatch({
            type: types.FBT_TOTAL_PRICE_SUCCESS,
            payload: data
          });
        })
        .catch(payload => {
          dispatch(
            setError(
              ERROR_DOMAIN.REPAIR_OPTIONS,
              ERROR_PATH.LINE_ITEMS_TOTAL_PRICE,
              normalizeError(payload)
            )
          );
          dispatch({
            type: types.FBT_TOTAL_PRICE_FAIL
          });
        });
    } else {
      dispatch(
        clearError(
          ERROR_DOMAIN.REPAIR_OPTIONS,
          ERROR_PATH.LINE_ITEMS_TOTAL_PRICE
        )
      );
    }
  };

export const getFbtItem = (partNumber, isLG) => async (dispatch, getState) => {
  const http = getHttpInstance(40000);
  const { locale, storeIdentifier } = getState().common;
  const { equipments } = getState().myEquipment;
  const { model } = equipments?.selectedEquipment ?? {};
  const errorInfo = {
    domain: ERROR_DOMAIN.PDP,
    path: ERROR_PATH.FBT
  };
  const queryParams = getObjectAsQueryParams({
    partNumber,
    storeIdentifier,
    model,
    locale
  });
  const partsList = [];

  const url = replaceTokensInUrl(`${endpoints.GET_FBT_ITEMS}?${queryParams}`);
  dispatch({ type: types.GET_FBT_ITEMS_BEGIN });
  try {
    const { data = {} } = await http.get(url, {
      headers: {
        [REQUEST_ID_HEADER]: v4()
      }
    });
    dispatch({
      type: types.GET_FBT_ITEMS_SUCCESS,
      payload: data
    });
    if (data) {
      const fbtItems = isLG ? data.products : data.products?.slice(0, 3);
      fbtItems?.map(item =>
        partsList.push({
          partNumber: item.partNumber,
          quantity: 1
        })
      );
      dispatch(
        getPriceAndAvailability({
          partsList,
          namespace: 'fbtPriceAndAvailability',
          attributeId: PRICE_AVAILABILITY_ATTRIBUTE_ID
        })
      );
    }
  } catch (error) {
    dispatch({
      type: types.GET_FBT_ITEMS_FAIL,
      payload: error
    });
    const { domain, path } = errorInfo;
    if (domain && path) {
      const err = normalizeError(error);
      dispatch(setExceptionError(domain, path, err));
    }
  }
};

export const getJobRecommendations =
  partNumber => async (dispatch, getState) => {
    const http = getHttpInstance();
    const { equipments } = getState().myEquipment;
    const { serialNumber, model } = equipments?.selectedEquipment ?? {};
    const { storeId } = getState().common;
    const errorInfo = {
      domain: ERROR_DOMAIN.PDP,
      path: ERROR_PATH.JOBS_RECOMMENDATIONS
    };
    const serialPrefix = serialNumber?.slice(0, 3);
    const serialNum = serialNumber?.slice(3, 8);
    const queryParams = getObjectAsQueryParams({
      partNumber,
      serialNumber: serialNum,
      serialPrefix,
      requestedJobs: 5,
      salesModel: model,
      storeId
    });

    const url = replaceTokensInUrl(
      `${endpoints.GET_JOBS_RECOMMENDATIONS}?${queryParams}`
    );
    dispatch({ type: types.GET_JOBS_RECOMMENDATIONS_BEGIN });
    try {
      const { data = {} } = await http.get(url, {
        headers: {
          [REQUEST_ID_HEADER]: v4()
        }
      });
      dispatch({
        type: types.GET_JOBS_RECOMMENDATIONS_SUCCESS,
        payload: data
      });
    } catch (error) {
      dispatch({
        type: types.GET_JOBS_RECOMMENDATIONS_FAIL,
        payload: error
      });
      const { domain, path } = errorInfo;
      if (domain && path) {
        const err = normalizeError(error);
        dispatch(setExceptionError(domain, path, err));
      }
    }
  };
