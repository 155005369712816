import {
  CAT_HELP_CENTER_V2_CONTACT_SUPPPORT_ENDPOINT,
  CAT_HELP_CENTER_V2_ENDPOINT,
  CAT_HELP_CENTER_V2_FAQ_ENDPOINT,
  CAT_HELP_CENTER_V2_URL,
  CAT_HELP_CENTER_V2_URL_DEV,
  USER_TYPE_GUEST
} from '@app/constants/commonConstants';
import { SALESFORCE_LOCALES_MAP } from '@app/constants/languageConstants';
import { isProd } from '@app/utils';
import { useSelector } from 'react-redux';

const useHelpCenterLink = () => {
  const localeFromStore = useSelector(s => s.common?.locale);
  const countryCode = useSelector(s => s.common.userCountryCode);
  const storeUserType = useSelector(s => s.common.userType);
  const isCSPCustomer = useSelector(s => s.common.isCSPCustomer);
  const isInstantAccess = useSelector(s => s.common.isInstantAccess);
  const isCatCorp = useSelector(s => s.common.isCatCorp);
  const selectedDealer = useSelector(s => s.common.selectedDealer);
  const storeDcnName = useSelector(s => s.common.dcnName);
  const dealerCode = useSelector(s => s.dealer?.dealerCode);
  const pccCustomerSupportAugustEnhancementsFeatureFlag =
    useSelector(
      state => state.featureFlag.PCC_CustomerSupportAugustEnhancements
    ) || false;
  const language = SALESFORCE_LOCALES_MAP[localeFromStore || 'en_US'];
  const geoloc = countryCode || 'US';
  const pageGroup = document.querySelector('meta[name="pageGroup"]')?.content;
  const isLoggedIn = storeUserType !== USER_TYPE_GUEST;
  const userState = isLoggedIn ? 'LoggedIn' : 'Loggedout';
  const esite = isCatCorp ? 'no' : selectedDealer;
  const IAStatus = isInstantAccess ? 'IA' : 'NonIA';
  let userType = isCSPCustomer ? 'CSP' : IAStatus;
  let dcnName = null;

  if (!isLoggedIn) {
    userType = null;
  }

  if (userType === 'CSP') {
    dcnName = storeDcnName;
  }

  const HC2_QUERY_PARAMS = [
    { param: 'language', value: language },
    { param: 'geoloc', value: geoloc },
    { param: 'pccCurrentPage', value: pageGroup },
    { param: 'userstate', value: userState },
    { param: 'eSite', value: esite },
    { param: 'usertype', value: userType },
    { param: 'DCN', value: dcnName }
  ];

  if (
    pccCustomerSupportAugustEnhancementsFeatureFlag &&
    dealerCode &&
    !isCatCorp
  ) {
    HC2_QUERY_PARAMS.push({ param: 'dealerCode', value: dealerCode });
  }

  const getHelpCenterURL = (endpointPath = CAT_HELP_CENTER_V2_ENDPOINT) => {
    const HELP_CENTER_BASE_URL = isProd()
      ? CAT_HELP_CENTER_V2_URL
      : CAT_HELP_CENTER_V2_URL_DEV;

    const HCURL = new URL(endpointPath, HELP_CENTER_BASE_URL);
    HC2_QUERY_PARAMS.filter(item => item.value).forEach(item => {
      HCURL.searchParams.append(item.param, item.value);
    });

    return HCURL.toString();
  };

  const getFaqHelpCenterURL = (endpointPath = CAT_HELP_CENTER_V2_ENDPOINT) => {
    const HELP_CENTER_BASE_URL = isProd()
      ? CAT_HELP_CENTER_V2_URL
      : CAT_HELP_CENTER_V2_URL_DEV;

    const HCURL = new URL(endpointPath, HELP_CENTER_BASE_URL);
    HCURL.searchParams.append('language', language);

    return HCURL.toString();
  };

  const getHelpCenterFAQURL = () =>
    getFaqHelpCenterURL(CAT_HELP_CENTER_V2_FAQ_ENDPOINT);
  const getHelpCenterContactSupportURL = () =>
    getHelpCenterURL(CAT_HELP_CENTER_V2_CONTACT_SUPPPORT_ENDPOINT);

  return {
    getHelpCenterURL,
    getHelpCenterFAQURL,
    getHelpCenterContactSupportURL
  };
};

export default useHelpCenterLink;
