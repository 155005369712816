import format from 'date-fns/format';
import { getArrayProp } from './listUtils';
import {
  STATUS,
  SECONDS_PER_DAY,
  MILLISECONDS_IN_A_SECOND,
  PROD_ENVS,
  EQUIPMENT,
  DATE_FORMAT_M_D_YYYY,
  SEARCH_KEYS,
  HEADER_STICKY_ON_SCROLL,
  X_CAT_SESSION_ID_COOKIE_NAME,
  HTML_SPECIAL_CHAR_REGEX
} from '../constants/commonConstants';

import { v4 as uuid } from 'uuid';
import { getCookie, setCookie } from './cookieUtils';

import { REACT_ROOT_MAIN } from '../constants/targets';

/**
 * Create a debounced version of a given function
 * @param {Function} callback Function to be debounced
 * @param {number} wait Time to wait in miliseconds
 * @returns {Function}
 */
export const debounce = (callback, wait) => {
  let timeout;
  return function retFunction(...args) {
    const functionCall = () => callback.apply(this, args);
    clearTimeout(timeout);
    timeout = setTimeout(functionCall, wait);
  };
};

/**
 * Maps a list of objects on its displayName
 * @param {Array<any>} arr List of objects with displayName
 * @returns {Array<any>}
 */
export const getDisplayNames = arr => getArrayProp(arr, 'displayName');

/**
 * Returns comma separated string of an object's values
 * @param {Record<any, any>} obj
 * @returns {string}
 */
export const getCommaSeparatedObjectValues = obj =>
  Object.values(obj ?? {}).join(', ');

/**
 * Checks if the environment is in development mode
 * @returns {boolean}
 */
export const isDev = () => process.env.NODE_ENV === 'development';

/**
 * Checks if a string|array|object is empty
 * @param {string|Array<any>|Record<any, any>} item A value to check
 * @returns {boolean}
 */
export const isEmpty = item => {
  if (item === null || item === undefined) {
    return true;
  }

  if (Array.isArray(item) || typeof item === 'string') {
    return item.length === 0;
  }

  if (Object.prototype.toString.call(item) === '[object Date]') {
    return false;
  }

  if (typeof item === 'object') {
    return Object.getOwnPropertyNames(item).length === 0;
  }

  return false;
};

/**
 * returns a formatted date and time in UTC timezone
 * @param {string} locale a local with which to format the date
 * @returns {string}
 * @example 7/7/2021, 12:47:38 PM UTC
 */
export const getFormattedUtcDatestamp = (locale = 'en-US') => {
  const formattedLocale = locale.replace('_', '-');
  const date = new Date().toLocaleString(formattedLocale, {
    timeZone: 'UTC'
  });
  return `${date} UTC`;
};

/**
 * returns a templated string of a part name
 * @param {string} partNumber the number of the part
 * @param {string} partName the name of the part
 * @returns {string}
 * @example 26/8/2022, '144-0235: GAUGE-TENSION'
 */
export const getPartName = (partNumber, partName) => {
  if (partName?.startsWith(partNumber)) {
    return partName;
  }

  return `${partNumber ?? ''}${partNumber && partName ? ': ' : ''}${
    partName ?? ''
  }`;
};

/**
 * returns the order export file name with pattern 'pageName[_orderId]_yyyyMMdd'
 * @param {string} page Page Name
 * @param {string} orderId order id
 * @returns filename
 */
export const getOrderExportFileName = (page = '', orderId = '') => {
  const timestamp = format(new Date(), 'yyyyMMdd');
  return `${page}${orderId ? `_${orderId}` : ''}_${timestamp}`;
};

/**
 * returns a date with added days
 * @param {Date} date the number of the part
 * @param {number} daysToAdd the name of the part
 * @returns {Date}
 */
export const addDaysToDate = (date, daysToAdd) =>
  new Date(date.setDate(date.getDate() + daysToAdd));

/**
 * returns the status of a network call'
 * @param {string} status Current Status
 * @returns {bool} Is Resolved
 */
export const isResolved = status => status === STATUS.RESOLVED;

/**
 * returns the status of a network call'
 * @param {string} status Current Status
 * @returns {bool} Is Rejected
 */
export const isRejected = status => status === STATUS.REJECTED;

export const isResolvedOrRejected = status =>
  isResolved(status) || isRejected(status);

/**
 * returns a date with time of expiry
 * @param {number} daysForExpiry the name of the part
 * @returns {Date}
 */
export const getExpiryDateFromNow = daysForExpiry => {
  const date = new Date();
  const expiryTime =
    date.getTime() + daysForExpiry * SECONDS_PER_DAY * MILLISECONDS_IN_A_SECOND;
  date.setTime(expiryTime);
  return date.toUTCString();
};

export const getLatLng = (lat, lng) => ({
  lat: parseFloat(lat),
  lng: parseFloat(lng)
});

export const roundCompare = (x, y, precision) => {
  return x.toPrecision(precision) === y.toPrecision(precision);
};

export const joinByProperty = (data = [], key) =>
  data.map(obj => obj[key]).join(', ');

export const isProd = () => {
  const currentEnv = window.catReact?.envName;
  return PROD_ENVS.includes(currentEnv);
};

export const getEquipmentField = formikProps => {
  const {
    setFieldValue,
    setFieldTouched,
    validateField,
    touched,
    values,
    errors
  } = formikProps;

  return {
    setEquipment: (value, bool) => setFieldValue(EQUIPMENT, value, bool),
    setEquipmentTouched: () => setFieldTouched(EQUIPMENT, true),
    validateEquipment: () => validateField(EQUIPMENT),
    equipmentErrors: errors.equipment,
    isEquipmentTouched: touched.equipment,
    equipmentValue: values.equipment
  };
};

/**
 * Helper to check if something is valid after some timeout
 * @param {() => (boolean | Promise<boolean>)} validation Callback to validate what are you waiting for.
 * @param {Object} options Options to wait
 * @param {number} options.maxAttempts Max attempts to keep alive, default: 10
 * @param {number} options.timeout Time to wait, default: 20ms
 * @returns {Promise<boolean>} if the expected result was solved
 */
export const waitForValidation = (
  validation,
  { maxAttempts = 10, timeout = 20 } = {}
) => {
  let attempts = 0;

  return new Promise(resolve => {
    const checkForResults = async () => {
      attempts++;

      if (maxAttempts > 0 && attempts > maxAttempts) {
        resolve(false);
      } else {
        const result = await validation();
        result ? resolve(true) : setTimeout(checkForResults, timeout);
      }
    };

    checkForResults();
  });
};

export const getFilterList = (list, searchString) => {
  return list.filter(obj => {
    return Object.keys(obj).some(key => {
      if (SEARCH_KEYS.includes(key)) {
        return obj[key].toLowerCase().includes(searchString.toLowerCase());
      }
    });
  });
};

/**
 * Set of utilities to work with session ID cookie, this includes:
 *  - `get`: to obtain the current value of the session ID cookie
 *  - `createNewId`: to create and store a new session ID cookie
 */
export const pccSessionId = {
  /** Get the current value of the session ID cookie
   * @returns {void} returns the ID store in the session ID cookie
   */
  get: () => getCookie(X_CAT_SESSION_ID_COOKIE_NAME),
  /** Create a new ID that will be stored on the session id cookie
   * @returns {string} The new ID stored in the cookie session ID
   */
  createNewId: () => {
    const newUUID = uuid();
    setCookie(X_CAT_SESSION_ID_COOKIE_NAME, newUUID);
    return newUUID;
  }
};

export const seletedDataUtil = (selectedEquipment, filteredList) => {
  return [selectedEquipment]?.concat(
    filteredList?.filter(
      filterData => filterData?.uniqueIndex !== selectedEquipment?.uniqueIndex
    )
  );
};

export const transformDateToString = date =>
  date instanceof Date
    ? `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
    : date;

export const getFormattedDate = date => {
  const dateObj = new Date(date);
  const month =
    dateObj.getMonth() > 8
      ? dateObj.getMonth() + 1
      : '0' + (dateObj.getMonth() + 1);
  const day =
    dateObj.getDate() > 9 ? dateObj.getDate() : '0' + dateObj.getDate();
  const year = String(dateObj.getFullYear()).substring(2);
  return `${month}-${day}-${year}`;
};

export const getTimestamp = dateFormat => format(new Date(), dateFormat);
export const getExportFileName = (
  dealerDateFormat,
  dealerDateFormatLoaded,
  page = ''
) => {
  if (!dealerDateFormat) {
    return `${page}`;
  }
  if (dealerDateFormatLoaded) {
    return `${page}_${getTimestamp(dealerDateFormat)}`;
  } else {
    return `${page}_${getTimestamp(DATE_FORMAT_M_D_YYYY)}`;
  }
};

export const getLocationAriaLabel = (
  storeLocation,
  storeName,
  label,
  storeLabel,
  locationLabel
) => {
  if (storeLocation && storeName) {
    return `${storeLabel} ${storeName} ${locationLabel} ${storeLocation}`;
  } else if (storeName && !storeLocation) {
    return `${storeLabel} ${storeName}`;
  } else if (!storeName && !storeLocation) {
    return label;
  }
};

/**
 * Function that format a number to a currency style
 * @param {*} number number to format
 * @param {*} locales local of the user. Default: 'en-US'
 * @param {*} currency currency of that country. Default: 'USD'
 * @returns a string with formatted value as currency style
 */
export const formatNumberToCurrency = (
  number,
  locales = 'en-US',
  currency = 'USD'
) => {
  return new Intl.NumberFormat(locales, {
    currency,
    style: 'currency'
  }).format(number);
};

export const modalOnScroll = showModel => {
  const body = document.getElementById(REACT_ROOT_MAIN);
  if (showModel) {
    body.classList.remove(HEADER_STICKY_ON_SCROLL);
  }
};

export const hasHtmlInjectionTags = (
  currentValue,
  errorMsg,
  errorPattern = HTML_SPECIAL_CHAR_REGEX
) => {
  if (currentValue.match(errorPattern)) {
    return errorMsg;
  }
  return true;
};

export const getPartsList = (productList, quantity) =>
  productList.map(part => ({
    partNumber: part.partNumber,
    quantity: quantity
  }));

export const getProductDetails = (
  product,
  priceAndAvailability = {},
  index = 0,
  categoryName = '',
  model = '',
  listName = ''
) => ({
  availability: priceAndAvailability?.[product['partNumber']]?.availability,
  brand: product.manufacturer,
  businessUnit: 'PCC',
  category: categoryName,
  partNumber: product['partNumber'],
  list: listName,
  name: getPartName(product['partNumber'], product.name),
  price: priceAndAvailability?.[product['partNumber']]?.unformattedUnitPrice,
  productWeight: priceAndAvailability?.[product['partNumber']]?.weight,
  position: index + 1,
  quantity: 1,
  relatedModel: model,
  sourcingDetails:
    priceAndAvailability?.[product['partNumber']]?.sourcingDetails
});

export const getHeliosInfoFromMyEquipmentList = (
  myEquipmentList,
  equipmentDetails
) => {
  return myEquipmentList?.find(
    row => row?.serialNumber === equipmentDetails?.serialNumber
  );
};
