import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { APP_BANNERS } from '@app/constants/targets';
import CSRBannerBlocks from '../CSRBannerBlocks/CSRBannerBlocks';

let node;
const app = APP_BANNERS;

const Container = () => {
  const [newNode, setNewNode] = useState(null);

  useEffect(() => {
    if (!document.getElementById(app)) {
      node = document.createElement('header');
      node.setAttribute('id', app);
      node.setAttribute('role', 'banner');
      node.setAttribute('style', 'overflow-x: hidden;overflow-y: auto;');
      const pageNode = document.getElementById('page');
      pageNode && pageNode.prepend(node);
    } else {
      node = document.getElementById(app);
    }
    setNewNode(node);
  }, []);

  return newNode ? createPortal(<CSRBannerBlocks />, newNode) : null;
};

export default Container;
