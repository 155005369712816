/* global CheckoutHelperJS */
import queryString from 'query-string';
import { getPackageErrorItemsIds } from '../../components/pages/checkout/shopping-cart/OrderItems/OrderItemsTable/utils';
import { getHttpInstance } from '../../services/defaultHttpService';
import { navigateToUrl } from '../../services/windowService';
import {
  debounce,
  getCookie,
  isEmpty,
  removeCookie,
  replaceTokensInString,
  replaceTokensInUrl
} from '../../utils';
import {
  deleteAvailabilityMessage,
  enrichOrderItems,
  getItemErrors,
  getLineItemStatusData,
  updatePageValue,
  updateSavedListHiddenFields
} from './utils';
import { normalizeError } from '../exception/utils';
import { setError, clearError } from '../exception/actions';
import { getObjectAsQueryParams } from '@app/utils';
import { filterUnmatchedElements, getRBDDateUpdate } from '../checkout/utils';
import * as types from './constants';
import { DEALER_CUSTOMER_STORES_SET_INFO } from '../dealer-customer/constants';
import endpoints from '../../constants/endpoints';

import {
  CUT_OFF_TIME_CROSSED_ERROR,
  EMPTY_CART_ERROR_MESSAGE,
  ERROR_DOMAIN,
  ERROR_PATH,
  PRICING_PARTS_ERROR,
  INFO_SHOPPING_CART_CRITICAL_ERROR
} from '../../constants/errorConstants';
import { SERVER_ERRORS } from '../../components/pages/checkout/shopping-cart/OrderItems/constants';
import {
  ORDER_ITEMS_ERROR_DOMAIN_QUANTITY,
  ORDER_ITEMS_PACKAGE_ERROR_CODE
} from '../../components/pages/checkout/shopping-cart/OrderItems/OrderItemsTable/customCells/errorConstants';
import {
  TIMEOUT_DEFAULT,
  TIMEOUT_EXTENDED,
  TIMEOUT_FORTY_SECONDS,
  USER_TYPE_GUEST,
  NO_STORE_CACHE_HEADER,
  DISPLAY_DATE_US
} from '../../constants/commonConstants';
import { responseFormat } from '@app/constants/responseFormats';
import { MARKETING_PRODUCT_PAGE_SIZE } from '@app/components/pages/marketing/constants';
import { getDropboxData } from '../checkout/actions';
import { ORDERED_BY_MODAL_TYPE } from '@app/components/pages/summary-and-payment/constants';
import { format } from 'date-fns';
import { isSummaryAndPaymentPage } from '@app/utils/browserUtils';
import { SHOPPING_CART_ROUTE } from '@app/constants/routes';

const {
  CHECKOUT_SUMMARY_UPDATE_ORDER,
  XORDER_PRICE_AVAILABILITY_URL,
  UPDATE_ORDER_TOTAL,
  UPDATE_ORDER_LINE_ITEM,
  XORDER_DELETE_CART,
  PROCEED_TO_CHECKOUT,
  DELETE_LINE_ITEM,
  VALIDATE_CART_ITEMS,
  ORDERS_UPDATE_LINE_ITEM_NOTES_URL,
  EMAIL_CART,
  DELETE_LINE_ITEM_BUNDLES,
  XORDER_BY_ID,
  OFFLINE_ORDER_DETAIL,
  XORDER_ATTRIBUTES,
  XORDER_CAL_INFO,
  XORDER_ITEM_INFO,
  XORDER_PRICE_AVAILABILITY_TOTALS,
  SEND_EMAIL_NOTIFICATION_BAR_CODE_PAYMENT,
  GUEST_XORDER_BY_ID
} = endpoints;

export const validateCartItems =
  (
    priceSuccess,
    errorInfo,
    checkoutCallback,
    gaCallback,
    itemsAndQuantityAddedIntoCartObject
  ) =>
  (dispatch, getState) => {
    const http = getHttpInstance();
    const isShoppingCartPage =
      window.location.pathname.includes(SHOPPING_CART_ROUTE);
    const {
      common: { storeId, langId }
    } = getState();
    const url = replaceTokensInString(VALIDATE_CART_ITEMS, storeId, langId);

    return http
      .get(url)
      .then(({ data }) => {
        removeCookie('zeroPriceString');
        removeCookie('indirectReplacedString');
        removeCookie('indirectReplacedParts');
        removeCookie('invalidParts');

        const { message, title, hasError } = data;

        const callback =
          !hasError && priceSuccess ? checkoutCallback : undefined;
        dispatch(
          // eslint-disable-next-line no-use-before-define
          getCurrentOrderInfo({
            errorInfo,
            callback,
            gaCallback,
            itemsAndQuantityAddedIntoCartObject
          })
        );

        if (hasError) {
          dispatch(
            setError(ERROR_DOMAIN.CART, ERROR_PATH.SHOPPING_CART, {
              ...normalizeError({}, { title, message, severity: 'warning' }),
              skipTranslation: true
            })
          );
        }
      })
      .catch(error => {
        if (isShoppingCartPage) {
          dispatch(
            setError(
              ERROR_DOMAIN.CART,
              ERROR_PATH.SHOPPING_CART_CRITICAL_ERROR,
              INFO_SHOPPING_CART_CRITICAL_ERROR
            )
          );
        }
      });
  };

export const getCurrentOrderInfo =
  ({
    cartItems = false,
    errorInfo = {},
    isInitialCartCall = false,
    callback,
    gaCallback,
    itemsAndQuantityAddedIntoCartObject = [],
    fieldValueObj,
    showToasterFulFillment,
    isCallingFromCounter = false,
    availabilityMessage,
    pageType
  }) =>
  (dispatch, getState) => {
    dispatch({
      type: types.ORDERS_GET_CURRENT_BEGIN,
      payload: { isCallingFromCounter }
    });
    const { common = {}, orders = {} } = getState();
    const { byId = {} } = orders;
    const { emailCart, storeId, langId } = common;
    const http = getHttpInstance(TIMEOUT_EXTENDED);
    const config = { headers: NO_STORE_CACHE_HEADER };
    const url = replaceTokensInString(
      endpoints.CHECKOUT_ORDER_DETAILS,
      storeId,
      langId,
      cartItems
    );
    const isShoppingCartPage =
      window.location.pathname.includes(SHOPPING_CART_ROUTE);

    return http
      .get(url, config)
      .then(({ data } = {}) => {
        window.catReact.orderItem = data.orderInformation.orderItem;
        const { orders: { byId = {} } = {} } = getState(); // getting actual state of byId instead of the previous to the request
        const { orderId, orderInformation, currencyNotation, ...rest } = data;
        const orderItem = enrichOrderItems(orders, orderInformation.orderItem);
        (!orderItem || orderItem.length === 0) && deleteAvailabilityMessage();
        const parsedOrderInfo = {
          ...orderInformation,
          orderItem,
          ascendingOrderItems: Array.isArray(orderItem)
            ? [...orderItem].reverse()
            : []
        };

        if (isInitialCartCall) {
          if (orderItem?.length > 0 && !emailCart) {
            dispatch(
              // eslint-disable-next-line no-use-before-define
              fetchPriceAvailability({
                errorInfo,
                checkoutCallback: null,
                gaCallback: null,
                itemsAndQuantityAddedIntoCartObject: []
              })
            );
          } else {
            const zeroPriceCookie = getCookie('zeroPriceString');
            const indirectReplacedString = getCookie('indirectReplacedString');
            const indirectReplacedParts = getCookie('indirectReplacedParts');
            const invalidParts = getCookie('invalidParts');
            if (
              zeroPriceCookie ||
              indirectReplacedString ||
              indirectReplacedParts ||
              invalidParts
            ) {
              dispatch(validateCartItems());
            }
          }
        }
        if (currencyNotation) {
          dispatch({
            type: DEALER_CUSTOMER_STORES_SET_INFO,
            payload: { currency: currencyNotation }
          });
        }
        dispatch({
          type: types.ORDERS_GET_CURRENT_SUCCESS,
          payload: {
            ...rest,
            currencyNotation,
            byId: { ...byId, [orderId]: parsedOrderInfo },
            current: orderId,
            taxDisclaimerMessage: data?.taxDisclaimerMessage,
            taxDisclaimerMessageFooter: data?.taxDisclaimerMessageFooter,
            taxExcludedDisclaimerMessage: data?.taxExcludedDisclaimerMessage
          }
        });
        const hasItemWithPackageError =
          parsedOrderInfo.ascendingOrderItems.some(item => item.packageError);
        if (!hasItemWithPackageError && callback) {
          callback();
        }
        if (gaCallback) {
          const parsedOrderItems = parsedOrderInfo.ascendingOrderItems;
          const filteredNewItems = parsedOrderItems.filter(item => {
            return itemsAndQuantityAddedIntoCartObject.find(
              part => part.partNumber === item.partNumber
            );
          });
          const filterRepeatedItems = filteredNewItems.filter(
            (element, index) => {
              return (
                filteredNewItems.findIndex(
                  item => item.partNumber === element.partNumber
                ) === index
              );
            }
          );
          const quantityUpdatedFilteredItems = filterRepeatedItems.map(
            element => {
              const updatedArray = updatePageValue(
                itemsAndQuantityAddedIntoCartObject
              );
              const matchingElement = updatedArray.find(
                part => part.partNumber === element.partNumber
              );
              const newQuantity = matchingElement?.quantity;
              const pageName = matchingElement?.page;
              return {
                ...element,
                serverQuantity:
                  element.serverQuantity ?? newQuantity?.toString(),
                quantity: element.quantity ?? newQuantity?.toString(),
                page: pageName
              };
            }
          );
          gaCallback(quantityUpdatedFilteredItems);
        }
        if (!!showToasterFulFillment) {
          getRBDDateUpdate(
            data.requestByDate.estimatedPickupDate,
            fieldValueObj,
            showToasterFulFillment,
            availabilityMessage,
            dispatch,
            pageType
          );
        }
      })
      .catch(error => {
        if (isShoppingCartPage) {
          dispatch(
            setError(
              ERROR_DOMAIN.CART,
              ERROR_PATH.SHOPPING_CART_CRITICAL_ERROR,
              INFO_SHOPPING_CART_CRITICAL_ERROR
            )
          );
        }
        const cartItems = byId[orders.current]?.ascendingOrderItems;
        (!cartItems || cartItems.length === 0) && deleteAvailabilityMessage();
        dispatch({
          type: types.ORDERS_GET_CURRENT_FAIL,
          error
        });

        const { domain, path, message } = errorInfo;
        if (domain && path) {
          const errorMessage = message ? { message } : normalizeError(error);
          dispatch(setError(domain, path, errorMessage));
        } else {
          dispatch(
            setError(
              ERROR_DOMAIN.CART,
              ERROR_PATH.MAIN,
              normalizeError(
                error,
                {
                  severity: 'error',
                  message: 'REFRESH_ERROR_MESSAGE',
                  title: undefined
                },
                true
              )
            )
          );
        }
      });
  };

export const getShippingOrderInfo =
  ({ storeId, langId, orderId, onError, email, docNum }) =>
  async (dispatch, getState) => {
    dispatch({ type: types.SHIPMENTS_BY_ORDER_ID_BEGIN, payload: { orderId } });
    let url = replaceTokensInUrl(
      endpoints.ORDER_TRACKING_INFO,
      storeId,
      langId,
      orderId
    );
    const {
      common: { userType },
      orders: { currentGuestOrder }
    } = getState();
    if (userType === USER_TYPE_GUEST) {
      url = replaceTokensInUrl(
        endpoints.GUEST_ORDER_TRACKING_INFO,
        storeId,
        langId,
        docNum,
        email
      );
    }
    const http = getHttpInstance();
    try {
      const { data } = await http.get(url);
      if (data?.shipments) {
        dispatch({
          type: types.SHIPMENTS_BY_ORDER_ID_SUCCESS,
          payload: {
            orderId: userType === USER_TYPE_GUEST ? currentGuestOrder : orderId,
            ...data,
            shipments: getLineItemStatusData(data.shipments)
          }
        });
      }
    } catch (error) {
      dispatch({
        type: types.SHIPMENTS_BY_ORDER_ID_FAIL,
        payload: {
          orderId,
          shipments: []
        }
      });
      onError && onError();
    }
  };

export const getOrder =
  (orderId, onError = () => {}, email = '', docNum = '') =>
  async (dispatch, getState) => {
    const http = getHttpInstance(TIMEOUT_DEFAULT);
    dispatch({ type: types.ORDER_GET_BEGIN, payload: orderId });
    const {
      common: { storeId, langId, userType },
      dealer: { dealerCode, isDropboxEnabled }
    } = getState();

    const queryParams = getObjectAsQueryParams({
      cartItems: false,
      langId,
      orderId,
      responseFormat: responseFormat.JSON,
      sortBy: 'asc',
      dealerCode
    });

    let url = replaceTokensInString(`${XORDER_BY_ID}?${queryParams}`, storeId);
    if (userType === USER_TYPE_GUEST) {
      url = replaceTokensInUrl(GUEST_XORDER_BY_ID, storeId, docNum, email);
    }

    let dropboxLabel;
    if (isDropboxEnabled) {
      const { dropbox } = await getDropboxData({
        storeId,
        langId,
        dispatch
      });
      dropboxLabel = dropbox.label;
    }

    return http
      .get(url)
      .then(({ data }) => {
        /*
         *  In order to match the shape of the order object we receive on checkout
         *  flow we are spreading orderInformation
         */
        const {
          orderInformation: { orderItem, orderStatus, ...restOrderInfo } = {},
          ...restOfData
        } = data ?? {};
        const order = {
          ...restOfData,
          ...restOrderInfo,
          ascendingOrderItems: orderItem,
          orderStatus,
          orderId: data?.orderId || orderId
        };
        dispatch({
          type: types.ORDER_GET_SUCCESS,
          payload: { order, dropboxLabel, userType }
        });
      })
      .catch(() => {
        dispatch({
          type: types.ORDER_GET_FAIL,
          payload: orderId
        });
        onError();
      });
  };

export const getOfflineOrder = orderId => (dispatch, getState) => {
  const http = getHttpInstance(TIMEOUT_FORTY_SECONDS);
  dispatch({ type: types.ORDER_GET_BEGIN, payload: orderId });
  const {
    common: { storeId, langId },
    dealer: { dealerCode }
  } = getState();

  const queryParams = getObjectAsQueryParams({
    cartItems: false,
    langId,
    orderId,
    responseFormat: responseFormat.JSON,
    sortBy: 'asc',
    dealerCode
  });
  const url = replaceTokensInString(
    `${OFFLINE_ORDER_DETAIL}?${queryParams}`,
    storeId
  );
  return http
    .get(url)
    .then(({ data }) => {
      /*
       *  In order to match the shape of the order object we receive on checkout
       *  flow we are spreading orderInformation
       */
      const {
        orderInformation: { orderItem, ...restOrderInfo } = {},
        ...restOfData
      } = data ?? {};
      const order = {
        ...restOfData,
        ...restOrderInfo,
        ascendingOrderItems: orderItem,
        orderId: data?.orderId || orderId
      };
      dispatch({ type: types.ORDER_GET_SUCCESS, payload: { order } });
    })
    .catch(e => {
      if (e?.response?.data?.errors?.length > 0) {
        dispatch({
          type: types.ORDER_GET_ERP_DOWN,
          payload: {
            ...e.response.data.errors[0],
            orderId: orderId
          }
        });
      } else {
        dispatch({
          type: types.ORDER_GET_FAIL,
          payload: orderId
        });
      }
    });
};

export const updateOrderedBy = (
  orderId = '',
  newOrderedBy = {},
  closeModalCallback,
  onSuccess,
  onFail
) => {
  const http = getHttpInstance();
  return (dispatch, getState) => {
    dispatch({ type: types.ORDERS_UPDATE_ORDERED_BY_BEGIN });
    const {
      common: { storeId },
      orders: {
        requestByDate: { format: rbdFormat }
      }
    } = getState();
    const { name, email, phone, requestByDate, modalType } = newOrderedBy;
    const endpointURL = replaceTokensInUrl(
      CHECKOUT_SUMMARY_UPDATE_ORDER,
      storeId
    );
    let formattedDate = '';
    if (requestByDate) {
      const unformattedDate = new Date(requestByDate);
      formattedDate = format(unformattedDate, rbdFormat);
    }
    const orderedBy = [
      {
        attributeName: 'orderByName',
        attributeValue: name
      },
      {
        attributeName: 'orderByEmail',
        attributeValue: email
      },
      {
        attributeName: 'orderByPhone',
        attributeValue: phone
      }
    ];
    const requestedShipDate = [
      {
        attributeName: 'requestedShipDate',
        attributeValue: requestByDate
          ? format(requestByDate, DISPLAY_DATE_US)
          : ''
      }
    ];

    return http
      .request({
        url: endpointURL,
        method: 'put',
        params: {
          responseFormat: 'json'
        },
        data: {
          orderExtendAttribute:
            modalType === ORDERED_BY_MODAL_TYPE ? orderedBy : requestedShipDate,

          orderId
        }
      })
      .then(() => {
        if (onSuccess && modalType === ORDERED_BY_MODAL_TYPE) {
          onSuccess();
        }
        if (closeModalCallback) {
          closeModalCallback();
        }
        if (modalType === ORDERED_BY_MODAL_TYPE) {
          return dispatch({
            type: types.ORDERS_UPDATE_ORDERED_BY_SUCCESS,
            payload: {
              ...newOrderedBy
            }
          });
        } else {
          return dispatch({
            type: types.RBD_UPDATE_ORDERED_BY_SUCCESS,
            payload: {
              formattedDate
            }
          });
        }
      })
      .catch(payload => {
        if (onFail) {
          onFail(payload);
        }
        dispatch(
          setError(
            ERROR_DOMAIN.CHECKOUT,
            ERROR_PATH.MODAL,
            normalizeError(payload)
          )
        );
        return dispatch({
          type: types.ORDERS_UPDATE_ORDERED_BY_FAIL,
          payload
        });
      });
  };
};

export const fetchPriceAvailability =
  ({
    errorInfo,
    checkoutCallback,
    gaCallback,
    itemsAndQuantityAddedIntoCartObject,
    invokeXorder = true
  }) =>
  (dispatch, getState) => {
    const isShoppingCartPage =
      window.location.pathname.includes(SHOPPING_CART_ROUTE);
    const http = getHttpInstance(TIMEOUT_EXTENDED);
    dispatch({ type: types.PRICE_AND_AVAILABILITY_BEGIN });
    const {
      common: { storeId, langId }
    } = getState();
    const url = replaceTokensInString(
      XORDER_PRICE_AVAILABILITY_URL,
      storeId,
      langId
    );
    return http
      .request({
        url: invokeXorder
          ? url
          : url + `&skipAddressValidation=${!invokeXorder}`,
        method: 'put'
      })
      .then(({ data }) => {
        dispatch({ type: types.PRICE_AND_AVAILABILITY_SUCCESS, payload: data });
        invokeXorder &&
          dispatch(
            validateCartItems(
              data?.success,
              errorInfo,
              checkoutCallback,
              gaCallback,
              itemsAndQuantityAddedIntoCartObject
            )
          );
      })
      .catch(error => {
        if (isShoppingCartPage) {
          dispatch(
            setError(
              ERROR_DOMAIN.CART,
              ERROR_PATH.SHOPPING_CART_CRITICAL_ERROR,
              INFO_SHOPPING_CART_CRITICAL_ERROR
            )
          );
        }
        dispatch({
          type: types.PRICE_AND_AVAILABILITY_FAIL,
          error
        });
        const { domain, path, message } = errorInfo;
        if (domain && path && message) {
          dispatch(setError(domain, path, { message }));
        } else {
          dispatch(
            setError(
              ERROR_DOMAIN.CART,
              ERROR_PATH.MAIN,
              normalizeError(
                error,
                {
                  severity: 'error',
                  message: 'REFRESH_ERROR_MESSAGE',
                  title: undefined
                },
                true
              )
            )
          );
        }
      });
  };

export const updateOrderTotal = successCb => (dispatch, getState) => {
  const http = getHttpInstance();
  const {
    common: { storeId, langId }
  } = getState();

  const url = replaceTokensInString(UPDATE_ORDER_TOTAL, storeId, langId);
  dispatch({
    type: types.ORDERS_UPDATE_TOTAL_BEGIN
  });

  return http
    .request({
      url: url,
      method: 'put'
    })
    .then(({ data }) => {
      const { orderSubTotal, orderSubTotalWithGST } = data;
      if (successCb) {
        successCb();
      }
      dispatch({
        type: types.ORDERS_UPDATE_TOTAL_SUCCESS,
        payload: { orderSubTotal, orderSubTotalWithGST }
      });
    })
    .catch(() => {
      dispatch({
        type: types.ORDERS_UPDATE_TOTAL_FAIL
      });
    });
};

const debouncedOrderTotal = debounce(dispatch => {
  dispatch(updateOrderTotal());
}, 2000);

export const setItemError = (item, error) => dispatch => {
  dispatch({
    type: types.ORDERS_SET_LINE_ITEM_ERROR,
    payload: {
      item,
      error
    }
  });
};

export const clearItemError = (item, errorDomain) => dispatch =>
  dispatch({
    type: types.ORDERS_CLEAR_LINE_ITEM_ERROR,
    payload: { item, errorDomain }
  });

export const removeZeroPricePart = orderItemId => ({
  type: types.ORDERS_REMOVE_ZERO_PRICE_LINE_ITEM,
  payload: orderItemId
});
export const updateLineItems =
  (orderItemIds, t, trackGA, checkPackageError) => (dispatch, getState) => {
    const http = getHttpInstance(TIMEOUT_EXTENDED);
    const {
      common: { storeId, langId, emailCart },
      orders: { byId, current }
    } = getState();
    const currentItems = byId[current].ascendingOrderItems;
    // Currently updating items
    const updatedItems = currentItems?.filter(
      obj =>
        orderItemIds.indexOf(obj.orderItemId) !== -1 &&
        obj.quantity !== '0' &&
        !isEmpty(obj.quantity)
    );

    // All errors in order (including this one if applicable)
    const orderErrors = getItemErrors(currentItems);

    if (updatedItems.length !== 0) {
      const url = replaceTokensInString(
        UPDATE_ORDER_LINE_ITEM,
        storeId,
        langId
      );
      const requestBody = {
        orderId: current,
        lineItemList: updatedItems
      };
      dispatch({
        type: types.ORDERS_UPDATE_LINE_ITEM_BEGIN,
        payload: updatedItems.map(item => item.orderItemId)
      });
      dispatch(clearError(ERROR_DOMAIN.CART, ERROR_PATH.MAIN));

      return http
        .request({
          url: url,
          method: 'put',
          data: requestBody
        })
        .then(({ data }) => {
          const lineItemList = data?.response?.lineItemList;
          // Other items with errors (besides the ones updated right now)
          const itemsWithErrors = orderErrors.filter(
            errorItem => orderItemIds.indexOf(errorItem.orderItemId) === -1
          );
          const successItems = [];
          const packageErrorItem = [];
          // TODO: This is here for testing server errors in a hard to reproduce
          // scenario. It will produce an error toast because there is a server
          // error with a code other than UPC5079, the package quantity error code
          // This can be removed prior to release once all testing is completed.
          // It will produce the error 50% of the time a line is updated.
          //
          // if (Math.random() < 0.5) {
          //   lineItemList[0].errorCode = 'some code';
          //   lineItemList[0].errorMessage = 'some message';
          // }

          if (lineItemList) {
            // Check for errors in response
            lineItemList.forEach(item => {
              const { errorCode, packageQuantity, zeroPricePart, orderItemId } =
                item;
              if (errorCode) {
                if (errorCode === ORDER_ITEMS_PACKAGE_ERROR_CODE) {
                  packageErrorItem.push(item);
                  dispatch(
                    setItemError(item, {
                      colId: ORDER_ITEMS_ERROR_DOMAIN_QUANTITY,
                      message: replaceTokensInString(
                        t('SC_PKG_QTY_PART_ERROR4'),
                        packageQuantity
                      )
                    })
                  );
                } else {
                  itemsWithErrors.push(item);
                  dispatch(
                    setItemError(item, {
                      colId: 'server',
                      message: SERVER_ERRORS.QUANTITY
                    })
                  );
                }
              } else {
                successItems.push(item);
                //To Remove Zero Price Parts from Line Item Update.
                if (zeroPricePart) {
                  dispatch(removeZeroPricePart(orderItemId));
                }
              }
            });
            if (trackGA) {
              trackGA(updatedItems);
            }
            // This updates the hidden input for item quantity to
            // allow existing legacy saved list modals to work
            updateSavedListHiddenFields(successItems);

            dispatch({
              type: types.ORDERS_UPDATE_LINE_ITEM_SUCCESS,
              payload: successItems
            });
            const packageErrorItemIds = getPackageErrorItemsIds(currentItems);
            if (checkPackageError && packageErrorItemIds.length) {
              dispatch({
                type: types.ORDERS_REMOVE_PACKAGE_ERROR,
                payload: packageErrorItemIds
              });
            }
          } else {
            //throw generic error?
          }
          if (itemsWithErrors.length || packageErrorItem.length) {
            dispatch({
              type: types.ORDERS_UPDATE_TOTAL_FAIL,
              payload: packageErrorItem
            });
          } else if (successItems.length) {
            !emailCart && debouncedOrderTotal(dispatch);
          }
          if (data?.hasReplacement) {
            dispatch({
              type: types.ORDERS_UPDATE_PARTIAL_REPLACEMENT_PARTS,
              payload: {
                replacementPartsInfo: data?.replacementPartsInfo
              }
            });
          }

          if (data?.zeroPriceError) {
            const { title, message } = data?.zeroPriceError;
            dispatch(
              setError(ERROR_DOMAIN.CART, ERROR_PATH.SHOPPING_CART, {
                ...normalizeError(
                  {},
                  {
                    title,
                    message,
                    severity: 'warning'
                  }
                ),
                skipTranslation: true
              })
            );
          }

          dispatch({
            type: types.ORDERS_REMOVE_PACKAGE_ERROR_PACKAGE
          });
        })
        .catch(error => {
          dispatch({
            type: types.ORDERS_UPDATE_LINE_ITEM_FAIL,
            payload: orderItemIds
          });

          dispatch(
            setError(
              ERROR_DOMAIN.CART,
              ERROR_PATH.MAIN,
              normalizeError(
                error,
                {
                  severity: 'error',
                  message: 'UNKNOWN_ERROR_MESSAGE'
                },
                true
              )
            )
          );
        });
    }
  };

export const clearBundleError = orderItemId => ({
  type: types.ORDERS_CLEAR_BUNDLE_ERROR,
  payload: orderItemId
});

export const setQuantity = (item, value, t) => dispatch => {
  const parsedQuantity = parseInt(value);

  //Setting Bundle Errors.
  const associatedBundleCount = item.orderItemExtendAttribute.ssroBundle.length;
  const hasBundleError =
    parsedQuantity < associatedBundleCount && parsedQuantity !== 0;
  if (hasBundleError) {
    dispatch(
      setItemError(item, {
        colId: ORDER_ITEMS_ERROR_DOMAIN_QUANTITY,
        message: t('MATCH_BUNDLE_QUANTITY')
      })
    );
  }
  dispatch({
    type: types.SET_QUANTITY,
    payload: { ...item, quantity: value, hasBundleError }
  });
};

export const emptyCart = trackGA => (dispatch, getState) => {
  const http = getHttpInstance();
  const {
    common: { storeId, langId },
    orders: { byId, current }
  } = getState();
  const currentItems = byId[current].ascendingOrderItems;
  const url = replaceTokensInString(XORDER_DELETE_CART, storeId, langId);
  dispatch({
    type: types.DELETE_CART_BEGIN
  });

  dispatch(clearError(ERROR_DOMAIN.CART, ERROR_PATH.MAIN));
  dispatch(clearError(ERROR_DOMAIN.CART, ERROR_PATH.SHOPPING_CART));

  return http
    .request({
      url: url,
      method: 'delete'
    })
    .then(({ data }) => {
      if (data?.success) {
        if (trackGA) {
          trackGA(currentItems);
        }
        deleteAvailabilityMessage();
        return dispatch({
          type: types.DELETE_CART_SUCCESS
        });
      } else {
        return dispatch(
          setError(
            ERROR_DOMAIN.CART,
            ERROR_PATH.EMPTY_CART_EMPTY_MODAL,
            normalizeError(data?.error, EMPTY_CART_ERROR_MESSAGE)
          )
        );
      }
    })
    .catch(error => {
      dispatch({
        type: types.DELETE_CART_FAIL
      });
      dispatch(
        setError(
          ERROR_DOMAIN.CART,
          ERROR_PATH.EMPTY_CART_EMPTY_MODAL,
          normalizeError(error, EMPTY_CART_ERROR_MESSAGE)
        )
      );
      throw error;
    });
};

const setErrorProceedToCheckout = (userType, errorObj) => {
  CheckoutHelperJS.canCheckoutContinue(userType, errorObj);
};
export const proceedToCheckout = orderDetails => (dispatch, getState) => {
  const http = getHttpInstance(TIMEOUT_EXTENDED);
  const {
    common: { storeId, userType, langId }
  } = getState();

  const url = replaceTokensInString(PROCEED_TO_CHECKOUT, storeId, langId);

  dispatch({
    type: types.PROCEED_TO_CHECKOUT_BEGIN
  });
  return http
    .request({
      url: url,
      method: 'post',
      data: orderDetails
    })
    .then(({ data: { redirectUrl: url, checkoutType } }) => {
      dispatch({
        type: types.PROCEED_TO_CHECKOUT_SUCCESS,
        payload: checkoutType
      });

      url && navigateToUrl(url);
      return checkoutType;
    })
    .catch(error => {
      const errorObj = {
        errorKey: '',
        errorMessage: ''
      };
      if (error?.response?.data?.errors.length > 0) {
        const errorRes = error.response.data.errors[0];
        if (errorRes['errorKey']) {
          errorObj.errorKey = errorRes.errorKey;
        }
        if (errorRes['errorMessage']) {
          errorObj.errorMessage = errorRes.errorMessage;
        }
      }
      let showRefreshButton = false;
      if (errorObj.errorKey === CUT_OFF_TIME_CROSSED_ERROR) {
        setErrorProceedToCheckout(userType, errorObj);
      } else if (errorObj.errorKey === PRICING_PARTS_ERROR) {
        dispatch(
          setError(
            ERROR_DOMAIN.CART,
            ERROR_PATH.SHOPPING_CART,
            normalizeError(error)
          )
        );
      } else {
        showRefreshButton = true;
        dispatch(
          setError(ERROR_DOMAIN.CART, ERROR_PATH.MAIN, normalizeError(error))
        );
      }

      dispatch({
        type: types.PROCEED_TO_CHECKOUT_FAIL,
        payload: { showRefreshButton }
      });
    });
};

export const setQuantityValidationError =
  (item, error) => (dispatch, getState) => {
    if (error) {
      const errorObj = {
        colId: ORDER_ITEMS_ERROR_DOMAIN_QUANTITY,
        message: error
      };
      dispatch(setItemError(item, errorObj));
    } else {
      dispatch(clearItemError(item, ORDER_ITEMS_ERROR_DOMAIN_QUANTITY));
    }
  };

export const deleteLineItem =
  (orderItemId, trackGA) => async (dispatch, getState) => {
    const http = getHttpInstance();
    const {
      common: { storeId, langId },
      orders: { byId, current }
    } = getState();
    const currentItems = byId[current].ascendingOrderItems;
    const updatedItem = currentItems?.find(
      obj => obj.orderItemId === orderItemId
    );
    if (updatedItem) {
      const url = replaceTokensInString(DELETE_LINE_ITEM, storeId, langId);
      const updatedLineItem = {
        orderItemId: updatedItem.orderItemId,
        calculateOrder: '0'
      };
      dispatch({
        type: types.DELETE_LINE_ITEM_BEGIN,
        payload: updatedLineItem
      });

      dispatch(clearError(ERROR_DOMAIN.CART, ERROR_PATH.MAIN));
      return http
        .request({
          url: url,
          method: 'put',
          data: updatedLineItem
        })
        .then(() => {
          //Timeout allows animation to run
          setTimeout(() => {
            debouncedOrderTotal(dispatch);
            if (trackGA) {
              trackGA([updatedItem]);
            }
            dispatch({
              type: types.DELETE_LINE_ITEM_SUCCESS,
              payload: { orderItemId: updatedItem.orderItemId }
            });
            dispatch(fetchPriceAvailability());
            dispatch(clearBundleError(updatedItem?.orderItemId));
            const cartItems =
              getState().orders.byId[getState().orders.current]
                .ascendingOrderItems;
            cartItems.length === 0 && deleteAvailabilityMessage();
          }, 500);
        })
        .catch(() => {
          setTimeout(() => {
            dispatch({
              type: types.DELETE_LINE_ITEM_FAIL,
              payload: { orderItemId: updatedItem.orderItemId }
            });
          }, 500);
        });
    }
  };

export const updateErrorItems =
  (trackUpdateGA, trackDeleteGA) => (dispatch, getState) => {
    const {
      orders: { byId, current }
    } = getState();
    const currentItems = byId[current].ascendingOrderItems;
    const failedItems = getItemErrors(currentItems);
    let failedLineItemUpdates = [];
    let failedDeletes = [];
    failedItems.forEach(item => {
      if (item.errors?.server === SERVER_ERRORS.QUANTITY) {
        failedLineItemUpdates.push(item.orderItemId);
      } else if (item.errors?.server === SERVER_ERRORS.DELETE) {
        failedDeletes.push(item.orderItemId);
      }
    });

    if (failedLineItemUpdates.length) {
      dispatch(updateLineItems(failedLineItemUpdates, trackUpdateGA));
    }

    failedDeletes.forEach(itemId =>
      dispatch(deleteLineItem(itemId, trackDeleteGA))
    );
  };

export const updatePackageCartQuantities = t => (dispatch, getState) => {
  const {
    orders: { byId, current }
  } = getState();
  const packageErrorItemIds = getPackageErrorItemsIds(
    byId[current].ascendingOrderItems
  );
  dispatch(updateLineItems(packageErrorItemIds, t, null, true));
};
export const setOrdersToIdle = () => ({ type: types.ORDERS_SET_TO_IDLE });

export const updateLineItemNotes =
  ({ orderItemId, type, input, focusedInputNoteId }) =>
  (dispatch, getState) => {
    const http = getHttpInstance();
    const {
      common: { storeId, langId },
      orders: { current }
    } = getState();

    const requestBody = {
      orderId: current,
      orderItemId,
      type,
      input
    };

    const url = replaceTokensInString(
      ORDERS_UPDATE_LINE_ITEM_NOTES_URL,
      storeId,
      langId
    );

    return http
      .request({
        url: url,
        method: 'put',
        data: requestBody
      })
      .then(({ data }) => {
        if (data.result) {
          dispatch({
            type: types.ORDERS_UPDATE_LINE_ITEM_NOTES_SUCCESS,
            payload: {
              orderItemId,
              type,
              input,
              focusedInputNoteId
            }
          });
        }
      })
      .catch(() => {
        dispatch({
          type: types.ORDERS_UPDATE_LINE_ITEM_NOTES_FAIL
        });
      });
  };

export const emailCart = () => (dispatch, getState) => {
  const http = getHttpInstance(TIMEOUT_EXTENDED);
  const {
    common: { storeId, langId }
  } = getState();

  const url = replaceTokensInString(EMAIL_CART, storeId, langId);

  dispatch({
    type: types.ORDERS_EMAIL_CART_BEGIN
  });
  return http
    .get(url)
    .then(() => {
      dispatch(getCurrentOrderInfo({}));
      dispatch({
        type: types.ORDERS_EMAIL_CART_SUCCESS
      });
      return true;
    })
    .catch(error => {
      dispatch({ type: types.ORDERS_EMAIL_CART_FAIL });
      dispatch(
        setError(
          ERROR_DOMAIN.CART,
          ERROR_PATH.MAIN,
          normalizeError(
            error,
            {
              severity: 'error',
              message: 'UNKNOWN_ERROR_MESSAGE'
            },
            true
          )
        )
      );
    });
};

export const saveOrdersPoNumber = (poNumber, poAttachments, orderId) => ({
  type: types.ORDERS_SAVE_PO_SUCCESS,
  payload: { poNumber, poAttachments, orderId }
});

export const savePOAttachment =
  ({ poNumber, poAttachmentFiles, orderId }) =>
  async (dispatch, getState) => {
    const {
      common: { storeId, locale },
      orders
    } = getState();

    const { poAttachments = [] } = orders.byId[orderId]?.purchaseOrderInfo;
    const [finalPOList] = filterUnmatchedElements(
      poAttachmentFiles,
      poAttachments,
      'fileName'
    ).map(({ file, fileName, fileSize }) => ({
      file,
      fileName,
      fileSize
    }));

    if (isEmpty(finalPOList)) {
      return false;
    }
    // File name needs to be encoded to be able to use "&" in the filename.
    const encodedFileName = encodeURIComponent(finalPOList?.fileName);
    const http = getHttpInstance(TIMEOUT_EXTENDED);
    const config = { headers: { 'content-type': 'application/octet-stream' } };
    const queryParams = getObjectAsQueryParams({
      orderId,
      locale,
      storeId,
      poNumber,
      fileName: encodedFileName
    });
    const url = replaceTokensInString(
      `${endpoints.XORDER_PO_ATTACHMENT}?${queryParams}`
    );

    dispatch({
      type: types.UPDATE_ORDER_PO_ATTACHMENT_BEGIN,
      payload: { orderId }
    });

    try {
      await http.put(url, finalPOList?.file, config);
      dispatch({
        type: types.UPDATE_ORDER_PO_ATTACHMENT_SUCCESS,
        payload: {
          poAttachments: [...poAttachments, finalPOList],
          orderId
        }
      });
      return true;
    } catch (error) {
      dispatch({
        type: types.UPDATE_ORDER_PO_ATTACHMENT_FAIL,
        payload: { orderId }
      });
    }
  };

export const deletePOAttachment =
  ({ poAttachment, orderId }) =>
  async (dispatch, getState) => {
    const {
      common: { storeId, locale },
      orders
    } = getState();
    const [{ fileName }] = poAttachment;
    const { poAttachments = [] } = orders.byId[orderId]?.purchaseOrderInfo;

    const POList = poAttachments.filter(
      attachment => attachment.fileName !== fileName
    );

    const encodedFileName = encodeURIComponent(fileName);
    const http = getHttpInstance(TIMEOUT_EXTENDED);
    const queryParams = getObjectAsQueryParams({
      orderId,
      locale,
      storeId,
      fileName: encodedFileName
    });
    const url = replaceTokensInString(
      `${endpoints.XORDER_PO_ATTACHMENT}?${queryParams}`
    );

    dispatch({
      type: types.UPDATE_ORDER_PO_ATTACHMENT_BEGIN,
      payload: { orderId }
    });

    try {
      await http.delete(url);
      dispatch({
        type: types.UPDATE_ORDER_PO_ATTACHMENT_SUCCESS,
        payload: {
          poAttachments: POList,
          orderId
        }
      });
    } catch (error) {
      dispatch({
        type: types.UPDATE_ORDER_PO_ATTACHMENT_FAIL,
        payload: { orderId }
      });
      throw error;
    }
  };

export const resetPOAttachmentUpdateStatus = ({ orderId }) => ({
  type: types.UPDATE_ORDER_PO_ATTACHMENT_RESET,
  payload: { orderId }
});
export const disableProceedToCheckoutButton = () => ({
  type: types.ORDERS_DISABLE_PROCEED_TO_CHECKOUT_BUTTON
});
export const clearLineItemNotesFocus = () => ({
  type: types.ORDERS_CLEAR_LINE_ITEM_NOTES_FOCUS
});

export const deleteLineItemBundle =
  (orderItemId, ssroIdList, closeModal, t, trackGA) => (dispatch, getState) => {
    const http = getHttpInstance();
    const {
      common: { storeId },
      orders: { current }
    } = getState();

    const requestBody = {
      orderId: current,
      orderItemId,
      ssroIdList
    };

    const url = replaceTokensInString(DELETE_LINE_ITEM_BUNDLES, storeId);
    dispatch({
      type: types.ORDERS_DELETE_LINE_ITEM_BUNDLE_BEGIN
    });

    return http
      .request({
        url: url,
        method: 'delete',
        data: requestBody
      })
      .then(({ data }) => {
        if (data?.result) {
          dispatch({
            type: types.ORDERS_DELETE_LINE_ITEM_BUNDLE_SUCCESS,
            payload: requestBody
          });
          closeModal();
          dispatch(clearBundleError(orderItemId));
          dispatch(updateLineItems([orderItemId], t, trackGA, true));
        } else {
          dispatch({
            type: types.ORDERS_DELETE_LINE_ITEM_BUNDLE_FAIL
          });
        }
      })
      .catch(() => {
        dispatch({
          type: types.ORDERS_DELETE_LINE_ITEM_BUNDLE_FAIL
        });
      });
  };

export const removeBundleModalError = () => dispatch => {
  dispatch({
    type: types.ORDERS_REMOVE_BUNDLE_MODAL_ERROR
  });
};

export const getDownLoadOrderDetail =
  (url, isListExport, pageName) => (dispatch, getState) => {
    const http = getHttpInstance();
    const { orderId } = queryString.parse(window.location.search);
    const { format } = queryString.parse(url);
    http
      .get(url, { responseType: 'arraybuffer' })
      .then(({ data }) => {
        if (data) {
          const url = URL.createObjectURL(new Blob([data]));
          const link = document.createElement('a');
          link.href = url;
          link.target = '_blank';
          link.setAttribute('download', `${pageName}.${format}`);
          document.body.appendChild(link);
          link.click();
          dispatch({
            type: isListExport
              ? types.OFFLINE_ORDER_HISTORY_EXPORT_SUCCESS_FAIL
              : types.OFFLINE_ORDER_DETAIL_EXPORT_SUCCESS_FAIL,
            payload: { orderId: orderId, isError: false }
          });
        }
      })
      .catch(error => {
        dispatch({
          type: isListExport
            ? types.OFFLINE_ORDER_HISTORY_EXPORT_SUCCESS_FAIL
            : types.OFFLINE_ORDER_DETAIL_EXPORT_SUCCESS_FAIL,
          payload: { orderId: orderId, isError: true }
        });
      });
  };

export const getOrderHeaderInfo =
  ({ storeId, langId, orderId, onError }) =>
  async dispatch => {
    dispatch({
      type: types.ORDER_HEADER_INFO_BEGIN,
      payload: {
        orderId
      }
    });

    const url = replaceTokensInUrl(
      endpoints.XORDER_HEADER_INFO,
      storeId,
      langId,
      orderId
    );

    const http = getHttpInstance();

    try {
      const { data = {} } = await http.get(url);

      dispatch({
        type: types.ORDER_HEADER_INFO_SUCCESS,
        payload: { orderId, orderHeaderInfo: data }
      });
    } catch (error) {
      dispatch({
        type: types.ORDER_HEADER_INFO_FAIL,
        payload: {
          orderId
        }
      });
      !!onError && onError();
    }
  };

export const getOrderFulfillmentInfo =
  ({ storeId, langId, orderId, onError }) =>
  async dispatch => {
    dispatch({
      type: types.ORDER_FULFILLMENT_INFO_BEGIN,
      payload: {
        orderId
      }
    });

    const url = replaceTokensInUrl(
      endpoints.XORDER_FULFILLMENT,
      storeId,
      langId,
      orderId
    );
    const http = getHttpInstance();
    try {
      const { data = {} } = await http.get(url);

      dispatch({
        type: types.ORDER_FULFILLMENT_INFO_SUCCESS,
        payload: { orderId, fulfillment: data.fulfillment }
      });
    } catch (error) {
      dispatch({
        type: types.ORDER_FULFILLMENT_INFO_FAIL,
        payload: {
          orderId,
          fulfillment: {}
        }
      });
      !!onError && onError();
    }
  };

export const getOrderCalInfoService =
  ({ storeId, langId, orderId, onError }) =>
  async dispatch => {
    const http = getHttpInstance();
    dispatch({
      type: types.ORDER_CAL_INFO_GET_BEGIN,
      payload: {
        orderId
      }
    });
    const url = replaceTokensInString(
      XORDER_CAL_INFO,
      storeId,
      langId,
      orderId
    );

    try {
      const { data } = await http.get(url);
      const orderCalInfo = data ?? {};
      dispatch({
        type: types.ORDER_CAL_INFO_GET_SUCCESS,
        payload: {
          orderId,
          orderCalInfo
        }
      });
    } catch (error) {
      dispatch({
        type: types.ORDER_CAL_INFO_GET_FAIL,
        payload: {
          orderId
        }
      });
      !!onError && onError();
    }
  };

export const getOrderInformation =
  ({ storeId, langId, orderId, onError, isDropboxEnabled }) =>
  async dispatch => {
    const http = getHttpInstance();
    dispatch({
      type: types.ORDER_INFORMATION_GET_BEGIN,
      payload: {
        orderId
      }
    });

    const url = replaceTokensInString(
      XORDER_ATTRIBUTES,
      storeId,
      langId,
      orderId
    );

    try {
      const { data } = await http.get(url);
      const orderAttributes = data ?? {};
      let dropboxLabel;
      if (isDropboxEnabled) {
        const { dropbox } = await getDropboxData({
          storeId,
          langId,
          dispatch
        });
        dropboxLabel = dropbox.label;
      }
      dispatch({
        type: types.ORDER_INFORMATION_GET_SUCCESS,
        payload: {
          orderId,
          orderAttributes,
          dropboxLabel
        }
      });
    } catch (error) {
      dispatch({
        type: types.ORDER_INFORMATION_GET_FAIL,
        payload: {
          orderId
        }
      });
      !!onError && onError();
    }
  };

export const getOrderBillingInfo =
  ({ storeId, langId, orderId, onError }) =>
  async dispatch => {
    dispatch({
      type: types.ORDER_BILLING_INFO_BEGIN,
      payload: {
        orderId
      }
    });

    const url = replaceTokensInUrl(
      endpoints.XORDER_BILLING,
      storeId,
      langId,
      orderId
    );
    const http = getHttpInstance();
    try {
      const { data = {} } = await http.get(url);

      dispatch({
        type: types.ORDER_BILLING_INFO_SUCCESS,
        payload: { orderId, billingAndPayment: data }
      });
    } catch (error) {
      dispatch({
        type: types.ORDER_BILLING_INFO_FAIL,
        payload: {
          orderId,
          billingAndPayment: {}
        }
      });
      !!onError && onError();
    }
  };

export const getTotalOrder =
  ({ storeId, langId, orderId, onError }) =>
  async dispatch => {
    dispatch({
      type: types.ORDER_TOTAL_BEGIN,
      payload: {
        orderId
      }
    });

    const url = replaceTokensInUrl(
      endpoints.ORDER_TOTAL,
      storeId,
      langId,
      orderId
    );

    const http = getHttpInstance();

    try {
      const { data = {} } = await http.get(url);

      dispatch({
        type: types.ORDER_TOTAL_SUCCESS,
        payload: { orderId, orderTotals: data }
      });
    } catch (error) {
      dispatch({
        type: types.ORDER_TOTAL_FAIL,
        payload: {
          orderId
        }
      });
      !!onError && onError();
    }
  };

export const getOrderItemInfo =
  ({ storeId, langId, orderId, onError }) =>
  async dispatch => {
    const http = getHttpInstance();
    dispatch({
      type: types.GET_ORDER_ITEM_INFO_BEGIN,
      payload: {
        orderId
      }
    });
    const url = replaceTokensInString(
      XORDER_ITEM_INFO,
      storeId,
      langId,
      orderId
    );

    try {
      const { data } = await http.get(url);
      const orderItemInfo = data ?? {};
      dispatch({
        type: types.GET_ORDER_ITEM_INFO_SUCCESS,
        payload: {
          orderId,
          orderItemInfo
        }
      });
    } catch (error) {
      dispatch({
        type: types.GET_ORDER_ITEM_INFO_FAIL,
        payload: {
          orderId
        }
      });
      !!onError && onError();
    }
  };

export const getProductDetails =
  ({
    storeId,
    langId,
    orderId,
    orderBy = 0,
    partNumbers,
    pageNumber = 0,
    onError
  }) =>
  async dispatch => {
    const http = getHttpInstance();

    dispatch({
      type: types.PRODUCT_DETAILS_BEGIN,
      payload: {
        orderId
      }
    });

    const url = replaceTokensInUrl(
      endpoints.MARKETING_PRODUCT_DETAILS,
      storeId,
      partNumbers,
      langId,
      orderBy,
      MARKETING_PRODUCT_PAGE_SIZE,
      pageNumber
    );

    try {
      const { data } = await http.get(url);
      const productDetails = data ?? {};

      dispatch({
        type: types.PRODUCT_DETAILS_SUCCESS,
        payload: {
          orderId,
          productDetails
        }
      });
    } catch (error) {
      dispatch({
        type: types.PRODUCT_DETAILS_FAIL,
        payload: {
          orderId
        }
      });
      !!onError && onError();
    }
  };

export const getOrderPriceAvailabilityTotals =
  ({ storeId, langId, orderId, onError, refreshPrices }) =>
  async dispatch => {
    const http = getHttpInstance(TIMEOUT_EXTENDED);
    dispatch({
      type: types.ORDER_PRICE_AVAILABILITY_TOTALS_BEGIN,
      payload: {
        orderId
      }
    });
    const url = replaceTokensInString(
      XORDER_PRICE_AVAILABILITY_TOTALS,
      storeId,
      langId,
      orderId,
      refreshPrices
    );

    try {
      const { data = {} } = await http.get(url);
      dispatch({
        type: types.ORDER_PRICE_AVAILABILITY_TOTALS_SUCCESS,
        payload: {
          orderId,
          data
        }
      });
    } catch (error) {
      dispatch({
        type: types.ORDER_PRICE_AVAILABILITY_TOTALS_FAIL,
        payload: {
          orderId
        }
      });
      !!onError && onError();
    }
  };

export const sendEmailNotificationPayment =
  ({ storeId, orderId }) =>
  async () => {
    const http = getHttpInstance(TIMEOUT_EXTENDED);
    const url = replaceTokensInString(
      SEND_EMAIL_NOTIFICATION_BAR_CODE_PAYMENT,
      storeId,
      orderId
    );

    try {
      return await (
        await http.post(url)
      ).data;
    } catch (error) {}
  };

export default {
  getCurrentOrderInfo,
  getOrderBillingInfo,
  getOrderCalInfoService,
  getOrderFulfillmentInfo,
  getOrderHeaderInfo,
  getOrderInformation,
  getProductDetails,
  getOrderItemInfo,
  updateOrderedBy,
  fetchPriceAvailability,
  updateLineItems,
  setQuantity,
  setItemError,
  clearItemError,
  emptyCart,
  updateOrderTotal,
  proceedToCheckout,
  setQuantityValidationError,
  deleteLineItem,
  validateCartItems,
  updateLineItemNotes,
  emailCart,
  updatePackageCartQuantities,
  removeZeroPricePart,
  clearLineItemNotesFocus,
  saveOrdersPoNumber,
  deleteLineItemBundle,
  removeBundleModalError,
  sendEmailNotificationPayment
};
