import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { CatInputField } from 'blocks-react/bedrock/components/InputField';
import {
  Drawer,
  NotificationNoResultBanner,
  FilterFormWrapper,
  PreferenceLink,
  FilterTitle,
  FilterFooter,
  Conditional,
  SearchContainer,
  OrderStatusFilter,
  useDrawer,
  NotificationFilterOrderStatusDrawer,
  OrderStatusCheckboxList,
  AccountStatusComponent,
  AccountStatusDrawer,
  AccountRadioButtons
} from '@cat-ecom/pcc-components';
import links from '@app/constants/links';
import {
  getNotificationHeaderList,
  notificationFilterChanged
} from '@app/store/notificationHeader/action';
import { useAnalytics } from '@app/hooks';
import { STATUS_FAIL, STATUS_SUCCESS } from '@app/constants/analyticsConstants';
import {
  debounce,
  replaceTokensInString,
  replaceTokensInUrl
} from '@app/utils';
import {
  changeOrderStatus,
  requestCreation,
  fireFormSubmittedEventWithOrderStatus,
  fireFormSubmittedEventWithAccount,
  fireFormSubmittedEventWithOrderNumber
} from '../../utils';
import IncludeDismissNotification from '../../IncludeDismissNotification';
import useOrderStatusList from './useOrderStatusList';
import styles from '../../NotificationItem.module.scss';

const NotificationFiltersDrawerWrapper = ({
  filterResult,
  drawerProps,
  closeDrawer,
  listOfOrderStatus,
  setListOfOrderStatus
}) => {
  const [isFilterWithNoResult, setIsFilterWithNoResult] = useState(false);
  const [orderStatusFilterDrawerOpen, setOrderStatusFilterDrawerOpen] =
    useState(false);

  const [errors, setErrors] = useState({
    orderNumberOrReference: false,
    poNumber: false
  });
  const [openAccountStatusDrawer, setOpenAccountStatusDrawer] = useState(false);
  const [t] = useTranslation();
  const { fireFormSubmittedEvent } = useAnalytics();
  const dispatch = useDispatch();
  const { catalogId, langId, storeId } = useSelector(s => s?.common);
  const quoteEnabled = useSelector(s => s.common?.quoteEnabled);
  const isCSPCustomer = useSelector(state => state?.common?.isCSPCustomer);
  const isInstantAccess = useSelector(state => state?.common?.isInstantAccess);
  const viewResult = useSelector(s => s.notificationHeader?.data);
  const notificationFilter = useSelector(
    s => s.notificationHeader?.notificationFilter
  );
  const dealerAssociations = useSelector(
    s => s.dealerAssociation?.dealerAssociations
  );
  const defaultDealer = dealerAssociations?.find(da => da.default);
  const dcnList = defaultDealer?.customerNumber;

  const orderstatusfilterdrawer = useDrawer();
  const [orderStatusList] = useOrderStatusList();
  const accountStatusDrawer = useDrawer();

  const isIAUser = useMemo(() => {
    return !isCSPCustomer && isInstantAccess;
  }, [isCSPCustomer, isInstantAccess]);

  const inputLabel = quoteEnabled
    ? t('ORDER_NUMBER_REFERENCE_NUMBER_QUOTE')
    : t('ORDER_NUMBER_REFERENCE_NUMBER');

  const orderInputPlaceholder = quoteEnabled
    ? t('ENTER_ORDER_REFERENCE_QUOTE_NUMBER')
    : t('ENTER_ORDER_REFERENCE_NUMBER');

  const { register, control, handleSubmit, setValue, reset, watch, getValues } =
    useForm({
      mode: 'onBlur'
    });

  const [account, orderNumberOrReference, orderStatus, poNumber] = watch([
    'account',
    'orderNumberOrReference',
    'orderStatus',
    'poNumber'
  ]);

  const contactUrl = replaceTokensInUrl(
    links.NOTIFICATION_PREFERENCES_URL,
    catalogId,
    langId,
    storeId
  );

  const showFilterAlterBanner = useMemo(() => {
    return (
      Object.keys(notificationFilter)?.length !== 0 &&
      filterResult?.length === 0
    );
  }, [notificationFilter, filterResult]);

  useEffect(() => {
    if (notificationFilter && Object.keys(notificationFilter)?.length > 0) {
      reset(notificationFilter);
    } else if (Object.keys(notificationFilter)?.length === 0) {
      reset({
        orderNumberOrReference: '',
        account: '',
        orderStatus: '',
        poNumber: ''
      });
    }
  }, [notificationFilter, reset]);

  useEffect(() => {
    if (
      Object.keys(notificationFilter)?.length !== 0 &&
      filterResult?.length > 0 &&
      isFilterWithNoResult
    ) {
      setIsFilterWithNoResult(false);
    }
  }, [isFilterWithNoResult, notificationFilter, filterResult, closeDrawer]);

  const closeDrawers = () => {
    closeDrawer();
    accountStatusDrawer.closeDrawer();
    orderstatusfilterdrawer.closeDrawer();
  };

  const handleApplyFilters = (value, close = true) => {
    const req = requestCreation(value, t, errors);
    dispatch(notificationFilterChanged(req));
    if (value?.orderStatus?.length > 0) {
      fireFormSubmittedEventWithOrderStatus(
        value,
        filterResult?.length > 0 ? STATUS_SUCCESS : STATUS_FAIL,
        fireFormSubmittedEvent
      );
    }
    if (value?.account) {
      fireFormSubmittedEventWithAccount(
        value,
        filterResult?.length > 0 ? STATUS_SUCCESS : STATUS_FAIL,
        fireFormSubmittedEvent
      );
    }
    close &&
      setTimeout(() => {
        closeDrawers();
        setIsFilterWithNoResult(true);
      }, 500);
  };

  const isValidToHandleValidtion = _.includes(errors, true);

  const handleFilterValidation = useCallback(() => {
    if (drawerProps?.isActive === false) {
      handleApplyFilters(getValues(), false);
      Object.keys(errors).forEach(key => {
        if (
          errors[key] !== false &&
          _.includes(['orderNumberOrReference', 'poNumber'], key)
        ) {
          setErrors(prevErrors => ({
            ...prevErrors,
            [key]: false
          }));
          setValue(key, '', true);
        }
      });
    }
  }, [drawerProps.isActive, errors]);

  const handleCloseOrderStatusDrawer = useCallback(() => {
    orderstatusfilterdrawer.closeDrawer();
    if (getValues()?.['orderStatus']?.length > 0) {
      fireFormSubmittedEventWithOrderStatus(
        getValues(),
        filterResult?.length > 0 ? STATUS_SUCCESS : STATUS_FAIL,
        fireFormSubmittedEvent
      );
    }
  }, [orderstatusfilterdrawer.isActive, orderStatus]);

  const handleCloseAccountDrawer = useCallback(() => {
    accountStatusDrawer.closeDrawer();
    if (!!getValues()?.account) {
      fireFormSubmittedEventWithAccount(
        getValues(),
        filterResult?.length > 0 ? STATUS_SUCCESS : STATUS_FAIL,
        fireFormSubmittedEvent
      );
    }
  }, [accountStatusDrawer.isActive, account]);

  const handleViewResult = useCallback(() => {
    handleApplyFilters(getValues(), true);
    if (
      getValues()?.orderNumberOrReference?.length > 0 ||
      getValues()?.poNumber?.length > 0
    ) {
      fireFormSubmittedEventWithOrderNumber(
        getValues(),
        filterResult?.length > 0 ? STATUS_SUCCESS : STATUS_FAIL,
        fireFormSubmittedEvent
      );
    }
  }, [filterResult?.length, getValues, handleApplyFilters]);

  const applyFilter = useCallback(() => {
    handleApplyFilters(getValues(), false);
  }, []);

  const debouncedApplyFilter = useMemo(() => {
    return debounce(applyFilter, 1000);
  }, [applyFilter]);

  const handleFieldChange = e => {
    const value = e.target.value.trim();
    setValue(e.target.name, value, true);
    const tempErrors = {
      ...errors,
      poNumber:
        e.target.name === 'poNumber'
          ? value.includes('>') || value.includes('<')
          : errors.poNumber,
      orderNumberOrReference:
        e.target.name === 'orderNumberOrReference'
          ? value.length < 3
          : errors.orderNumberOrReference
    };
    setErrors(tempErrors);
    if (!_.includes(tempErrors, true)) {
      debouncedApplyFilter();
    }
  };

  const handleFilterClear = () => {
    dispatch(notificationFilterChanged({}));
    reset({
      orderNumberOrReference: '',
      poNumber: '',
      account: '',
      orderStatus: ''
    });
    setListOfOrderStatus([]);
    setErrors({
      orderNumberOrReference: false,
      poNumber: false
    });
    dispatch(getNotificationHeaderList(false));
  };
  const handleFilterClearOrderStatus = useCallback(() => {
    const req = requestCreation(
      { orderNumberOrReference, poNumber, account },
      t
    );
    if (Object.keys(req)?.length === 0) {
      dispatch(notificationFilterChanged({}));
    } else {
      dispatch(
        notificationFilterChanged({
          ...req,
          orderStatus: []
        })
      );
    }

    reset({ orderStatus: '', account: account });
    setListOfOrderStatus([]);
    dispatch(getNotificationHeaderList(false));
  }, [
    account,
    dispatch,
    orderNumberOrReference,
    poNumber,
    reset,
    setListOfOrderStatus,
    t
  ]);

  const handleClearAccountStatus = () => {
    const req = requestCreation(
      { orderNumberOrReference, poNumber, orderStatus },
      t
    );
    if (Object.keys(req)?.length === 0) {
      dispatch(notificationFilterChanged({}));
    } else {
      dispatch(
        notificationFilterChanged({
          ...req
        })
      );
    }

    reset({ account: '', orderStatus: orderStatus });
    dispatch(getNotificationHeaderList(false));
  };

  const handleOrderStatusFilterDrawer = () => {
    orderstatusfilterdrawer.openDrawer();
    setOrderStatusFilterDrawerOpen(true);
  };

  const isFilterApplied =
    notificationFilter?.orderStatus?.length > 0 ||
    notificationFilter?.account ||
    notificationFilter?.poNumber ||
    notificationFilter?.orderNumberOrReference;

  const getViewResult = replaceTokensInString(
    t('VIEW_NUMBER_RESULTS'),
    isFilterApplied ? filterResult?.length : viewResult.recordSetTotal
  );

  const getAccountResult = replaceTokensInString(
    t('VIEW_NUMBER_RESULTS'),
    isFilterApplied ? filterResult?.length : viewResult.recordSetTotal
  );

  const handleCheckbox = (e, value) => {
    e.preventDefault();
    const { checked } = e.target;
    const req = requestCreation(
      { orderNumberOrReference, poNumber, account },
      t,
      errors
    );
    const updatedStatus = checked
      ? [
          ...listOfOrderStatus,
          {
            orderStatus: value.value,
            ...value
          }
        ]
      : listOfOrderStatus?.filter(data => data.orderStatus !== value.value);

    changeOrderStatus(
      updatedStatus,
      setListOfOrderStatus,
      req,
      setValue,
      dispatch
    );
  };

  const handleAccountStatusDrawer = () => {
    accountStatusDrawer.openDrawer();
    setOpenAccountStatusDrawer(true);
  };

  const handleRadioButton = (e, value) => {
    e.preventDefault();
    const req = requestCreation(
      { orderNumberOrReference, poNumber, orderStatus },
      t,
      errors
    );
    const selectedValue = {
      value: value,
      label: e?.detail.value
    };
    setValue('account', selectedValue, true);
    setTimeout(() => {
      dispatch(notificationFilterChanged({ ...req, account: selectedValue }));
    }, 1000);
  };

  return (
    <Drawer
      {...drawerProps}
      className={`${styles['notification_filters_drawer']}`}
      data-testid="notification-filters-drawer"
      backButton
    >
      <FilterTitle
        closeDrawer={closeDrawer}
        backButtonAriaLable={t('BACK')}
        ariaLabel={t('FILTER_OR_SEARCH')}
      >
        {t('FILTER_OR_SEARCH')}
      </FilterTitle>
      {isValidToHandleValidtion && handleFilterValidation()}

      <Conditional test={showFilterAlterBanner}>
        <NotificationNoResultBanner
          noResultText={t('NO_FILTER_RESULTS_FOUND')}
          notificationVariant="warning"
        />
      </Conditional>
      <div>
        <FilterFormWrapper
          id="notificationFilterForm"
          data-testid="filterForm"
          onSubmit={handleSubmit(handleApplyFilters)}
        >
          <Conditional test={!isIAUser && dcnList?.length > 0}>
            <AccountStatusComponent
              accountStatusTitle={t('FOOTER_ACCOUNT')}
              selectedAccount={dcnList?.length === 1 ? dcnList[0] : account}
              handleClick={handleAccountStatusDrawer}
              isDisable={dcnList?.length === 1}
            />
          </Conditional>

          <Conditional test={openAccountStatusDrawer}>
            <AccountStatusDrawer
              drawerProps={accountStatusDrawer.drawerProps}
              closeDrawer={handleCloseAccountDrawer}
              accountStatusTitle={t('FILTER_BY_ACCOUNT')}
              backButtonAriaLable={t('BACK')}
            >
              <Controller
                name="account"
                control={control}
                render={() => (
                  <AccountRadioButtons
                    accountList={dcnList}
                    handleRadioButton={handleRadioButton}
                    selectedAccount={account}
                  />
                )}
              />
              <FilterFooter
                viewResultText={getAccountResult}
                isDisable={!!notificationFilter?.account}
                handleFilterClear={handleClearAccountStatus}
                closeDrawer={closeDrawer}
                clearFilterText={t('USERROLEMANAGEMENT_CLEARFILTER')}
              />
            </AccountStatusDrawer>
          </Conditional>

          <div>
            <Controller
              name="orderStatus"
              control={control}
              render={({ field }) => (
                <OrderStatusFilter
                  field={field}
                  orderStatusTitle={t('SI_ORDERSTATUS')}
                  handleClick={handleOrderStatusFilterDrawer}
                  orderSelectedList={orderStatus ? orderStatus : []}
                  showLineAbove={!isIAUser && dcnList?.length > 0}
                />
              )}
            />
            {orderStatusFilterDrawerOpen && (
              <NotificationFilterOrderStatusDrawer
                drawerProps={orderstatusfilterdrawer.drawerProps}
                closeDrawer={handleCloseOrderStatusDrawer}
                OrderStatusFilterTitle={t('FILTER_BY_ORDER_STATUS')}
                backButtonAriaLable={t('BACK')}
              >
                <PreferenceLink
                  text={t('NOTIF_PREF_FILTERING_MESSAGE')}
                  link={contactUrl}
                  linkText={t('NOTIF_PREF_HYPERLINK')}
                />
                <Controller
                  name="orderStatus"
                  control={control}
                  render={() => (
                    <OrderStatusCheckboxList
                      orderStatusList={orderStatusList}
                      handlecheckbox={handleCheckbox}
                      orderStatusValue={orderStatus}
                    />
                  )}
                />
                <FilterFooter
                  viewResultText={getViewResult}
                  isDisable={!!notificationFilter?.orderStatus?.length > 0}
                  handleFilterClear={handleFilterClearOrderStatus}
                  closeDrawer={closeDrawer}
                  clearFilterText={t('USERROLEMANAGEMENT_CLEARFILTER')}
                />
              </NotificationFilterOrderStatusDrawer>
            )}
          </div>
          <SearchContainer title={t('SEARCH_CATALOG')}>
            <div className="mb-3 mt-2">
              <CatInputField
                name="orderNumberOrReference"
                ariaDescribedBy="orderNumberOrReference"
                aria-label={t('ORDER_NUMBER_REFERENCE_NUMBER_QUOTE')}
                fieldId="orderNumberOrReference"
                data-testid="orderNumberOrReference"
                label={inputLabel}
                autoComplete="off"
                {...register('orderNumberOrReference')}
                status={errors?.orderNumberOrReference ? 'error' : undefined}
                onBlChange={handleFieldChange}
                placeholder={orderInputPlaceholder}
                type="text"
                isError={errors.orderNumberOrReference}
                errorNote={
                  errors.orderNumberOrReference && t('MEQ_ADD_EQ_ERROR1')
                }
              />
            </div>
            <div className="pb-4">
              <CatInputField
                type="text"
                name="poNumber"
                data-testid="poNumber"
                label={t('CAT_CORE_TRACK_PO_NUM')}
                maxlength={20}
                hideWordCount
                {...register('poNumber')}
                onBlChange={handleFieldChange}
                placeholder={t('ENTER_PO_NUMBER')}
                status={errors.poNumber === true ? 'error' : undefined}
                errorNote={errors.poNumber && t('PO_PROHIBITED_CHAR')}
              />
            </div>
          </SearchContainer>
        </FilterFormWrapper>
        <div className={`${styles['notification_filters_drawer_toggle']}`}>
          <IncludeDismissNotification />
        </div>
      </div>
      <FilterFooter
        viewResultText={getViewResult}
        isDisable={
          notificationFilter?.orderNumberOrReference ||
          notificationFilter?.poNumber ||
          notificationFilter?.account ||
          notificationFilter?.orderStatus?.length > 0
        }
        handleFilterClear={handleFilterClear}
        closeDrawer={closeDrawer}
        handleViewResult={handleViewResult}
        clearFilterText={t('MULTI_SELECT_CLEAR_ALL_FILTERS')}
      />
    </Drawer>
  );
};

NotificationFiltersDrawerWrapper.propTypes = {
  toggleActive: PropTypes.bool,
  closeDrawer: PropTypes.func,
  drawerProps: PropTypes.shape({
    isActive: PropTypes.bool
  }),
  filterResult: PropTypes.arrayOf(
    PropTypes.shape({
      isDismissed: PropTypes.bool,
      reviewQuoteLink: PropTypes.string,
      orderNumber: PropTypes.number,
      orderQuoteDetailLink: PropTypes.string,
      isPinned: PropTypes.bool,
      orderId: PropTypes.number,
      isRead: PropTypes.bool,
      orderStatus: PropTypes.string,
      formattedTime: PropTypes.string,
      dcnName: PropTypes.string,
      dcn: PropTypes.string,
      formattedTotalOrderAmount: PropTypes.string,
      orderAmount: PropTypes.number,
      orderShipmentDetailsLink: PropTypes.string,
      formattedDate: PropTypes.string,
      currency: PropTypes.string,
      notificationId: PropTypes.number,
      quoteExpireDays: PropTypes.string,
      reviewOrderLink: PropTypes.string
    })
  ),
  listOfOrderStatus: PropTypes.arrayOf(
    PropTypes.shape({
      orderStatus: PropTypes.string,
      value: PropTypes.string
    })
  ),
  setListOfOrderStatus: PropTypes.func
};

export default NotificationFiltersDrawerWrapper;
