import qs from 'query-string';
import ExceptionFeedbackBySelector from '@app/components/common/Exception/ExceptionFeedbackBySelector';
import { SHOW_MANUAL_APPROVAL } from './constants';
import ManualApprovalMessage from './ManualApprovalMessage';

const ManualApprovalToast = () => {
  const { feedback } = qs.parse(window.location.search);
  const isFeedbackShowManualApproval = feedback === SHOW_MANUAL_APPROVAL;
  const showManualApproval = isFeedbackShowManualApproval;
  return (
    <>
      <ExceptionFeedbackBySelector
        className="container mt-4"
        preventScroll
        skipTranslation
        selector={state => state?.errors?.global?.main}
      />
      {showManualApproval && <ManualApprovalMessage />}
    </>
  );
};

export default ManualApprovalToast;
