import format from 'date-fns/format';
import { DISPLAY_DATE } from '@app/constants/commonConstants';

export const getFilteredSchema = (schema, columnToHide) =>
  schema.filter(item => item.id !== columnToHide);

export const getPopperOtions = isLG => {
  return {
    placement: 'bottom-end',
    modifiers: [
      {
        name: 'flip',
        options: {
          behavior: ['bottom']
        }
      },
      { name: 'offset', options: { offset: [0, isLG ? 2 : 10] } },
      {
        name: 'arrow',
        options: {
          padding: isLG ? 0 : ({ popper }) => ({ left: popper.width - 31 })
        }
      }
    ]
  };
};

// This is to return a string that does not contain brackets or \ symbol.
export const getStringWithoutParenthesis = term =>
  term.replace(/[{}()[\]\\]/g, '');

export const getFormattedDate = (date, dealerDateFormat = DISPLAY_DATE) => {
  const d = new Date(date);
  return format(d, dealerDateFormat);
};

export const getEquipmentName = item => {
  if (!item) {
    return '';
  }
  if (typeof item === 'string') {
    return item;
  }
  const { assetId = '', model = '', serialNumber = '' } = item || {};
  const equipmentAssetId = assetId ? `${assetId} ` : '';
  const equipmentModel = model ?? '';
  const equipmentSerialNumber = serialNumber ?? '';
  const hyphenSeparator = model && serialNumber ? ' - ' : '';
  const startWithBracket =
    model || (serialNumber && !serialNumber.startsWith('(')) ? '(' : '';
  const endWithBracket =
    model || (serialNumber && !serialNumber.endsWith(')')) ? ')' : '';

  return `${equipmentAssetId}${startWithBracket}${equipmentModel}${hyphenSeparator}${equipmentSerialNumber}${endWithBracket}`;
};

export const getValueOnCondition = (condition, option1 = '', option2 = '') => {
  return condition ? option1 : option2;
};
