import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { NotificationSkeletonLoader } from '@cat-ecom/pcc-components';
import { v4 as uuid } from 'uuid';
import {
  NOTIFICATION_INDICATOR,
  NOTIFICATION_LABEL_MAP,
  NOTIFICATION_LABEL_TYPE_MAP,
  NOTIFICATION_ORDER_LINK_TEXT,
  ORDER_PROCESSED
} from '@app/constants/commonConstants';
import 'blocks-web-components/dist/bedrock/bedrock.css';
import Conditional from '../../Conditional';
import CardHeader from '../CardHeader';
import CardContent from '../CardContent';
import CardFooter from '../CardFooter';
import styles from './UserNotificationItem.module.scss';

const NotificationDetails = ({ notificationData }) => {
  const { t } = useTranslation();

  const dealerAssociations = useSelector(
    s => s.dealerAssociation?.dealerAssociations
  );
  const isCSPCustomer = useSelector(state => state?.common?.isCSPCustomer);
  const isInstantAccess = useSelector(state => state?.common?.isInstantAccess);

  const defaultDealer = dealerAssociations?.find(da => da.default);

  const isIAUser = useMemo(() => {
    return !isCSPCustomer && isInstantAccess;
  }, [isCSPCustomer, isInstantAccess]);

  const dcnList = useMemo(() => {
    return defaultDealer ? defaultDealer.customerNumber : [];
  }, [defaultDealer]);

  return (
    <Conditional
      test={!!notificationData?.length > 0}
      fallback={<NotificationSkeletonLoader />}
    >
      {notificationData &&
        notificationData?.slice(0).map(item => {
          return (
            <React.Fragment
              key={`${item?.notificationId}_${item.orderStatus}_${
                item?.orderNumber
              } ${uuid()}`}
            >
              <div
                className={`${
                  styles['notification-item']
                } border-bottom border-LightGray d-flex d-row ${
                  item?.isDismissed ? styles['notification-item-hide'] : ''
                } ${item?.isRead ? '' : styles['notification-item-active']}`}
                data-testid="notification-details"
              >
                <div
                  className={`d-flex d-column pe-2 w-100 ${styles['notification-item-padding']}`}
                >
                  <div className="w-100">
                    <div
                      className={`${styles['notification-item__title']} u-fs--14`}
                    >
                      <CardHeader
                        orderId={item?.orderId || item?.referenceId}
                        formattedTotalOrderAmount={
                          item?.formattedTotalOrderAmount
                        }
                        title={t(
                          NOTIFICATION_ORDER_LINK_TEXT[item?.orderStatus]
                        )}
                        orderQuoteDetailLink={item?.orderQuoteDetailLink}
                        currency={item?.currency}
                        isRead={item?.isRead}
                        statusKey={item?.orderStatus}
                        orderNumber={item?.orderNumber}
                        quoteExpireDate={item?.quoteExpireDays}
                        reviewQuoteLink={item?.reviewQuoteLink}
                        notificationId={item?.notificationId}
                        isDismissed={item?.isDismissed}
                        dynamicClassName={styles['dismiss-Notification-icon']}
                        hoverCardClass={styles['order-status']}
                      />
                      <CardContent
                        dcn={
                          dcnList.length === 1 || isIAUser ? null : item?.dcn
                        }
                        formattedDate={item?.formattedDate}
                        formattedTime={item?.formattedTime}
                        isOrderRow={false}
                        purchaseOrderNumber={item?.poNumber}
                        isRead={item?.isRead}
                        statusKey={item?.orderStatus}
                      />

                      <CardFooter
                        orderStatus={
                          item?.ordersQueueStatus === ORDER_PROCESSED
                            ? t(NOTIFICATION_LABEL_MAP[item?.ordersQueueStatus])
                            : t(NOTIFICATION_LABEL_MAP[item?.orderStatus])
                        }
                        orderDisplayLinkText={t(
                          NOTIFICATION_INDICATOR[item?.orderStatus]
                        )}
                        statusKey={item?.orderStatus}
                        link={item?.reviewOrderLink}
                        purchaseOrderNumber={item?.poNumber}
                        quoteExpireDate={item?.quoteExpireDays}
                        isRead={item?.isRead}
                        orderId={item?.orderId || item?.referenceId}
                        orderShipmentDetailsLink={
                          NOTIFICATION_INDICATOR[item?.orderStatus] ===
                          'VIEW_ORDER'
                            ? item?.orderShipmentDetailsLink
                            : null
                        }
                        reviewQuoteLink={item?.reviewQuoteLink}
                        orderNumber={item?.orderNumber}
                        type={NOTIFICATION_LABEL_TYPE_MAP[item?.orderStatus]}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </React.Fragment>
          );
        })}
    </Conditional>
  );
};

export default NotificationDetails;

NotificationDetails.propTypes = {
  notificationData: PropTypes.arrayOf(
    PropTypes.shape({
      isDismissed: PropTypes.bool,
      reviewQuoteLink: PropTypes.string,
      orderNumber: PropTypes.number,
      orderQuoteDetailLink: PropTypes.string,
      isPinned: PropTypes.bool,
      orderId: PropTypes.number,
      isRead: PropTypes.bool,
      orderStatus: PropTypes.string,
      formattedTime: PropTypes.string,
      dcn: PropTypes.string,
      dcnName: PropTypes.string,
      formattedTotalOrderAmount: PropTypes.string,
      orderAmount: PropTypes.number,
      orderShipmentDetailsLink: PropTypes.string,
      formattedDate: PropTypes.string,
      currency: PropTypes.string,
      notificationId: PropTypes.number,
      quoteExpireDays: PropTypes.string,
      reviewOrderLink: PropTypes.string
    })
  )
};
