import PropTypes from 'prop-types';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import {
  getAvailabilityPickupText,
  getAvailabilityTitle,
  getFormattedDate
} from '../Common/util';
import { availability as availabilityPropTypes } from './StoreAvailability.proptypes';
import { useSelector } from 'react-redux';
import { AlloyButton, InlineLoader, Popup } from 'cat-ecommerce-alloy';
import { CatIconShipping } from 'blocks-react/bedrock/components/Icons/Shipping';
import { CatIconCustomer } from 'blocks-react/bedrock/components/Icons/Customer';
import { CatGrid } from 'blocks-react/bedrock/components/Grid';
import { CatGridItem } from 'blocks-react/bedrock/components/GridItem';
import ContactDealerPopup from '../../ContactDealerPopup';
import { replaceTokensInString } from '@app/utils';
import { DATE_FNS_LOCALES } from '@app/constants/languageConstants';
import withCutoffTime from './withCutoffTime';
import { USER_TYPE_GUEST } from '@app/constants/commonConstants';
import TooltipDisclaimer from '../TooltipDisclaimer';
import styles from './StoreAvailability.module.scss';

const StoreAvailability = ({
  hideLabel = false,
  showAdditionalInfo = false,
  className,
  availabilityInformation,
  isLoading,
  catEntryId,
  hoseAssemblyDetails,
  pickupRemainingTime,
  deliveryRemainingTime
}) => {
  const [t] = useTranslation();
  const locale = useSelector(s => s.common.locale);
  const { inStock, contactDealer, byDate } = availabilityInformation;

  const cellStyles = 'text-start m-0';
  const cellStylesBold = 'fw-bold';

  const isDeliveryEnabled = useSelector(
    state => state.common.isDeliveryEnabled
  );
  const isPickUpEnabled = useSelector(state => state.dealer.isPickupEnabled);
  const showPickupCutoffFlag = useSelector(
    s => s.featureFlag?.PCC_showPickupCutoffFlag
  );
  const estimatedDeliveryDateFlag = useSelector(
    s => s.featureFlag?.PCC_pdpEstimatedDeliveryDateFlag
  );
  const userType = useSelector(s => s.common?.userType);
  const current = useSelector(s => s.products?.current);
  const partNumber = useSelector(s => s.products?.byId?.[current]?.partNumber);
  const deliveryAvailability = useSelector(
    s =>
      s.products.priceAndAvailability?.[partNumber]?.deliveryAvailability
        ?.byDate
  );
  const isLoggedIn = userType !== null && userType !== USER_TYPE_GUEST;
  const showDeliveryText = inStock === null && byDate === null;
  const popperOptions = {
    placement: 'bottom',
    modifiers: [
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['top', 'bottom'],
          rootBoundary: 'document'
        }
      }
    ]
  };

  const getCutoffTime = (enabled, cutoffTime) => {
    if (!enabled) {
      return null;
    }
    const { hours, minutes } = cutoffTime || {};
    let tokenString = '';
    if ((hours || 0) + (minutes || 0) > 0 && hours < 12) {
      tokenString = replaceTokensInString(
        t('PDP_PICK_UP_ORDER_BY'),
        hours < 0 ? 0 : hours,
        minutes < 0 ? 0 : minutes
      );
    }
    return tokenString;
  };

  const pickupCutoffTime = getCutoffTime(
    showPickupCutoffFlag,
    pickupRemainingTime
  );
  const deliveryCutoffTime = getCutoffTime(
    estimatedDeliveryDateFlag,
    deliveryRemainingTime
  );

  const getLabel = () =>
    !hideLabel && (
      <div className="d-flex align-items-center justify-content-start mb-2">
        <span className="text-sm text-uppercase text-univers--bold u-color--ExtraDarkGray m-0">
          {getAvailabilityTitle(isDeliveryEnabled, isPickUpEnabled, t)}
        </span>
        <TooltipDisclaimer />
      </div>
    );

  const getHoseAssemblyDetails = () => {
    if (hoseAssemblyDetails.hoseAssemblyIndicator) {
      const hoseLabel = hoseAssemblyDetails.hoseAvailabilityLabel?.trim();
      const hoseDetails = hoseLabel || t('HOSE_DEFAULT_MESSAGE');
      return (
        <>
          <span className={'cat-u-theme-typography-body-sm text-sans-serif'}>
            {hoseDetails}
          </span>
          <p className="fst-italic">
            {showAdditionalInfo &&
              getAvailabilityPickupText(
                isDeliveryEnabled,
                isPickUpEnabled,
                t,
                'ps-0'
              )}
          </p>
        </>
      );
    }
  };

  const normalizeString = str => (str ?? '')?.toString().trim();

  const msg = contactDealer && normalizeString(contactDealer.message);

  const getDealerInitiator = msg => (
    <AlloyButton buttonType="link" className="p-0 text-start">
      {msg || t('CONTACT_YOUR_DEALER')}
    </AlloyButton>
  );

  const getPopupMessage = (
    showContactDealerOnly,
    msg,
    catEntryId,
    popperOptions
  ) => {
    return showContactDealerOnly || !msg ? (
      <>
        <div className="d-flex h-100 d-print-none">
          <Popup
            contents={<ContactDealerPopup catEntryId={catEntryId} />}
            initiator={getDealerInitiator(msg)}
            className={`${styles['availability-contact-dealer__popup']}`}
            popperOptions={popperOptions}
          />
        </div>
        <div className={` d-none d-print-block`}>
          {msg || t('CONTACT_YOUR_DEALER')}
        </div>
      </>
    ) : (
      { msg }
    );
  };

  const getAvailabilityTable = () =>
    (byDate || contactDealer || inStock) && (
      <>
        {hoseAssemblyDetails.hoseAssemblyIndicator ? (
          <>
            {getLabel()}
            {getHoseAssemblyDetails()}
          </>
        ) : (
          <div
            className={`cat-u-padding-md border border-cat-secondary text-sans-serif cat-u-theme-typography-body-sm ${styles['storeAvailablity__section']}`}
          >
            <CatGrid variant="side-by-side">
              <CatGridItem className="border-end">
                <div className="d-flex align-items-lg-center align-items-start mb-1">
                  <CatIconCustomer />
                  <p
                    className={`text-uppercase text-univers--bold ps-2 mb-0 ${styles['storeAvailablity__text-color']}`}
                  >
                    {t('PDP_ESTIMATED_PICKUP')}
                  </p>
                </div>
                <table>
                  <tbody>
                    {inStock && (
                      <tr>
                        <td className={cx('d-flex h-100')}>
                          <div
                            className={cx(
                              cellStylesBold,
                              cellStyles,
                              `${styles['stock-quantity-green-color']}`
                            )}
                          >
                            {inStock.quantity}
                          </div>
                          <div
                            className={cx(
                              cellStyles,
                              styles['contact-button'],
                              'ps-1'
                            )}
                          >
                            <div>{inStock.message}</div>
                          </div>
                        </td>
                      </tr>
                    )}
                    {byDate &&
                      byDate.map((item, index) => (
                        <tr
                          key={`${item.quantity}-${
                            item.date || 'contact-dealer'
                          }`}
                        >
                          <td className={cx(cellStylesBold, 'd-flex h-100')}>
                            <div className={cx(cellStyles)}>
                              {item.date && (
                                <div>
                                  {contactDealer === null
                                    ? replaceTokensInString(
                                        t('AVAIL_STANDARD_ALL_PICKUP'),
                                        item.quantity,
                                        getFormattedDate(item.date, 'MMM', {
                                          locale: DATE_FNS_LOCALES[locale]
                                        }),
                                        getFormattedDate(item.date, 'dd')
                                      )
                                    : replaceTokensInString(
                                        t('AVAIL_STANDARD_SOME_PICKUP'),
                                        item.quantity,
                                        getFormattedDate(item.date, 'MMM', {
                                          locale: DATE_FNS_LOCALES[locale]
                                        }),
                                        getFormattedDate(item.date, 'dd')
                                      )}
                                </div>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))}
                    {showPickupCutoffFlag &&
                      !showDeliveryText &&
                      pickupCutoffTime && (
                        <tr>
                          <td className={cellStylesBold}>
                            <div
                              className={cx('m-0', styles['cutoff-time-text'])}
                            >
                              {pickupCutoffTime}
                            </div>
                          </td>
                        </tr>
                      )}
                  </tbody>
                </table>
              </CatGridItem>
              <CatGridItem>
                <div className="d-flex align-items-lg-center align-items-start mb-1">
                  <CatIconShipping />
                  <p
                    className={`text-uppercase text-univers--bold ps-2 mb-0 ${styles['storeAvailablity__text-color']}`}
                  >
                    {t('PDP_ESTIMATED_DELIVERY')}
                  </p>
                </div>
                {estimatedDeliveryDateFlag &&
                  isLoggedIn &&
                  deliveryAvailability && (
                    <div>
                      {deliveryAvailability.map(
                        item =>
                          item.date && (
                            <>
                              <p
                                className={cx(cellStylesBold)}
                                key={`${item.quantity}-${item.date}`}
                              >
                                {replaceTokensInString(
                                  t('AVAIL_STANDARD_DELIVERY'),
                                  item.quantity,
                                  getFormattedDate(item.date, 'MMM', {
                                    locale: DATE_FNS_LOCALES[locale]
                                  }),
                                  getFormattedDate(item.date, 'dd')
                                )}
                              </p>
                              {deliveryCutoffTime &&
                                estimatedDeliveryDateFlag && (
                                  <div
                                    className={cx(
                                      'm-0 fw-bold',
                                      styles['cutoff-time-text']
                                    )}
                                  >
                                    {deliveryCutoffTime}
                                  </div>
                                )}
                            </>
                          )
                      )}
                    </div>
                  )}

                {!showDeliveryText && !isLoggedIn && (
                  <p className="fst-italic">
                    {showAdditionalInfo &&
                      getAvailabilityPickupText(
                        isDeliveryEnabled,
                        isPickUpEnabled,
                        t,
                        'ps-0'
                      )}
                  </p>
                )}
              </CatGridItem>
            </CatGrid>

            {contactDealer && (
              <CatGrid>
                <CatGridItem className="mt-3">
                  <tr>
                    <td className="d-flex h-100">
                      <div className={cx(cellStylesBold, 'pr-1')}>
                        {contactDealer.quantity}
                      </div>
                      <div className={cx(cellStyles, styles['contact-button'])}>
                        {getPopupMessage(
                          contactDealer,
                          msg,
                          catEntryId,
                          popperOptions
                        )}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="fst-italic">
                      {t('PDP_AVAILABILITY_ADDITIONAL_DEALER_INFO')}
                    </td>
                  </tr>
                </CatGridItem>
              </CatGrid>
            )}
          </div>
        )}
      </>
    );

  return (
    <div className={className}>
      {isLoading ? <InlineLoader /> : getAvailabilityTable()}
    </div>
  );
};

StoreAvailability.propTypes = {
  hideLabel: PropTypes.bool,
  className: PropTypes.string,
  catEntryId: PropTypes.string,
  isLoading: PropTypes.bool,
  availabilityInformation: availabilityPropTypes,
  hoseAssemblyDetails: PropTypes.shape({
    hoseAssemblyIndicator: PropTypes.bool,
    hoseAvailabilityLabel: PropTypes.string
  }),
  showAdditionalInfo: PropTypes.bool,
  pickupRemainingTime: PropTypes.shape({
    hours: PropTypes.number,
    minutes: PropTypes.number
  }),
  deliveryRemainingTime: PropTypes.shape({
    hours: PropTypes.number,
    minutes: PropTypes.number
  })
};

export default withCutoffTime(StoreAvailability);
